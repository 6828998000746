.btn-primary {
    background: theme-color("primary");
    color: $white;
    border:0;
    text-decoration:none!important;
    font-weight: 400;
}

.btn-secondary {
    background: theme-color("secondary");
    border: none;
    color: $white;
	font-weight: 400;
    text-decoration:none!important;
}