@include media-breakpoint-down($nav-hamburger-breakpoint) {
    //Container styling. All sizes and flexbox props resides in _e-header.scss
    .e-nav-utilities-secondary {
        .e-nav & {
            font-size: $font-size-sm;
            margin-bottom: 3em;
            border-top: $border-width solid $header-border-color;
            border-bottom: $border-width solid $header-border-color;
            background: rgba($header-color, 0.05);
            opacity: 0;
            //Props for item animation entry in active modal.
            .show & {
                transition: all $transition-fade-speed $transition-fade-easing 0.125s;
                opacity: 1;
            }
            // Level #1
            > .nav {
                margin-top: $spacer * 1.5;
                border-top: $border-width solid $border-color;
                padding-top: $spacer * 1.5;
                > .nav-item {
                    > .nav-link {
                        display: block;
                        padding: ($spacer * 0.5) ($spacer * 0);
                        color: inherit;
                        background: none;
                        white-space: normal;
                    }
                }
            }
        }
        .e-footer & {
            .nav {

            }
        }
    }
}

@include media-breakpoint-up(breakpoint-next($nav-hamburger-breakpoint)) {
    //Container styling. All sizes and flexbox props resides in _e-header.scss and _e-footer.scss
    .e-nav-utilities-secondary {
        .nav {
            margin-left: $nav-link-padding-x * -1;
        }
        .nav-item {
        }
        .nav-link {
        }
    }
}
