.e-canvas {
    position: relative;
    max-width: $canvas-max-width;
    margin-right: auto;
    margin-left: auto;
    // z-index disabled to allow modal's inside canvas
    // z-index: 1;
    &.is-fullscreen {
        max-width: 100%
    }
}
