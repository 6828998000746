// Remove clear botton on input fields in IE10+
input[type=text]::-ms-clear {
    display: none;
}

// prevent iphone from zooming in when filling out a form, like login or application
// http://blog.osmosys.asia/2017/01/05/prevent-ios-from-zooming-in-on-input-fields/
@media only screen and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    button[type="submit"],
    select,
    textarea,
    input,
    input:focus,
    input:hover {
        //font-size: 16px !important;
    }
}

.form-group {}

.form-legend {
    font-size: 1em;
    .e-form-dw-labels-bold & {
        font-weight: $font-weight-bold;
    }
}

.form-control-label {
    margin-bottom: $spacer / 5;
    .e-form-dw-labels-bold & {
        font-weight: $font-weight-bold;
    }
    .e-form-labels-hide & {
        display: none;
    }
}

.form-control {
    &.is-invalid {
        border-color: $form-feedback-invalid-color;
        &:focus {}
    }
}

.form-check {}

.form-check-label {
    cursor: pointer;
    .is-invalid & {
        color: $form-feedback-invalid-color;
    }
}

.form-text {
    &.is-invalid {
        color: $form-feedback-invalid-color;
    }
}

.invalid-feedback {
    display: none;
    .is-invalid & {
        display: block;
    }
}
