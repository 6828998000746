@import "custom/frontpage";
@import "custom/buttons";
@import "custom/header";
@import "custom/banner";
@import "custom/list-style";
@import "custom/forms";

// custom css

.p-txt-container {
    a {
        text-decoration: underline;
    }

    h4 {
        margin-bottom: 0.4em;
    }
}

@include media-breakpoint-down($nav-hamburger-breakpoint) {
    .e-footer-copyright {
        display: none;
    }
}

/*
.p-img-container.p-banner-img-center-middle::after {
    content: "keyboard_arrow_down";
    color: white;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    font-family: "Material Icons";
    text-rendering: optimizeLegibility;
    font-feature-settings: "liga";
    font-style: normal;
    text-transform: none;
    line-height: 1;
    font-size: 4em;
    width: 2em;
    margin-left: -1em;
    height: 1em;
    opacity: 1;
    display: inline-block;
    overflow: overlay;
    -webkit-font-smoothing: antialiased;
}
*/

// Text styling
a {
    color: theme-color("primary");
}

a:hover {
    color: theme-color("tertiary");
}

.strong {
    color: $black;
}

// footer styling
.footerInformationaddress {
    max-width: 100%;
    flex: 0 0 100%;

    h3 {
        font-weight: 500;
        padding-bottom: 0px;
        margin: 0;
        font-size: 0.875em;
    }
}

.e-footer-content {
    padding: 1em;
}

.e-footer-copyright {
    background: #2d2d2d;
}

// Content styling
.e-content-main {
    padding-top: 0;
    padding-bottom: 0;
}

.p-txt-container .lead {font-size:1.3em;
}

// breadcrumb
.breadcrumb-back-link {
    display: none;
}

.breadcrumb-back-link + .breadcrumb-prefix-text::before {
    display: none;
}

.e-breadcrumb {
    border-top: 1px solid #ccc;

    .breadcrumb {
        font-size: 0.9em;
    }

    .breadcrumb li:nth-child(2)::before {
        display: none;
    }
}

// icon section
.p-icontext .p-txt-container.col-12 p {
    margin-bottom: 0.5em;
}