.material-icons {
    font-size: 1em;
    vertical-align: -10%;
}

.material-icons-large {
    padding-left: .25em;
    padding-right: $spacer * 0.5;
    transform: scale(1.5);
    //transform-origin: 25% 50%;
}

.material-icons-2x {
    padding-left: .325em;
    padding-right: $spacer * 0.5;
    transform: scale(2);
    //transform-origin: 25% 50%;
}

.material-icons-fixed {
    box-sizing: content-box;
    width: 1.28571429em;
    text-align: center;
}

.material-icons {
    &.ml-1 {
        margin-left: ($spacer / 4) !important;
    }
    &.mr-1 {
        margin-right: ($spacer / 4) !important;
    }
    // &.ml-2 {
    //     margin-left: ($spacer / 4) !important;
    // }
    // &.mr-2 {
    //     margin-right: ($spacer / 4) !important;
    // }
}

.material-icons-ul {
    padding-left: 0;
    margin-left: 0;
    list-style-type: none;
    li {
        position: relative;
        padding-left: $spacer * 1.5;
        .material-icons {
            position: absolute;
            left: 0;
            text-align: left;
            transform: scale(1.25) translateY(0.125em);
        }
    }
    &.list-condensed {
        li {
            padding-left: $spacer * 1.25;
        }
    }
}
