.e-checkout {
    min-height: 100vh;
    .e-checkout-step-loading-overlay {
        background: rgba($loading-bg, .85);
    }
}

.e-checkout-step {
    + .e-checkout-step {
        border-top: $border-width solid $border-color;
    }
    .card-header {
        .badge {
            min-width: 2em;
        }
    }
    .card-body {
    }

    &.e-checkout-step-active {
        .card-header,
        .card-body {
            background: theme-color("info");
        }
    }
    &:not(.e-checkout-step-completed):not(.e-checkout-step-active) {
        .card-header {
            opacity: $text-muted / 2;
            .badge {
                background: color("gray");
            }
        }
    }
}

.e-checkout-step-summary {
    transition: max-height $transition-collapse-speed $transition-collapse-easing;
    overflow: hidden;
    max-height: 0;
    .e-checkout-step-completed & {
        max-height: 100vh;
    }
    dl {
        opacity: $text-muted / 2;
        .e-checkout-page-approve & {
            opacity: 1;
            color: $headings-color;

        }
    }
}

.e-checkout-form {
    .form-check-label {
        margin-bottom: $spacer * 0.125;
    }
    .e-checkout-list-group-item-icon {
        height: $spacer * 1.25;
        width: auto;
    }
    .list-group{
        .list-group-item{
            cursor: pointer;
            .form-control-label{
                cursor: pointer;
            }
            
        }
    }

}

//Leison keepers

.e-checkout-toggle-target-hidden {
    display: none;
}

.e-checkout-summery-icon {
    img {
        height: $spacer * 1.25;
        width: auto;
    }
}

.e-checkout-page-approve {
    .e-checkout .e-checkout-step .e-checkout-step-summary {
        //color: $gray-900;
    }

}

@include media-breakpoint-down(md) {
    .e-checkout {
        min-height: 100vh;
        .collapsing {
            transition: none;
        }
    }
    .e-checkout-step,
    .e-checkout-step-summary {
        //display: none;
    }
    .e-checkout-step-active {
        display: block;
    }
}

//
//.e-checkout-progress {
//    ul {
//        display: flex;
//        justify-content: space-around;
//        list-style: none;
//        li {
//            display: flex;
//            flex-direction: column;
//            position: relative;
//            justify-content: center;
//            align-items: center;
//            font-size: $font-size-sm;
//            color: e-section-color("light","text");
//            &::before {
//                content: attr(data-index);
//                display: inline-block;
//                position: relative;
//                overflow: hidden;
//                z-index: 1;
//                width: 2em;
//                height: 2em;
//                line-height: 2em;
//                padding: 0;
//                border-radius: 50%;
//                vertical-align: middle;
//                background-color: e-section-color("light","bg");
//                border-color: e-section-color("light","bg");
//                color: e-section-color("light","text");
//
//                text-align: center;
//                font-size: 1.6rem;
//            }
//            &.e-checkout-progress-completed {
//                cursor: pointer;
//                &::before {
//                    @include material-icons("done");
//                    background: theme-color("success");
//                    border-color: theme-color("success");
//                    color: color-yiq(theme-color("success"), $yiq-headings-light, $yiq-headings-dark);
//
//                    text-align: center;
//                    font-size: 1.6rem;
//                    line-height: 2em;
//
//                }
//            }
//            &.e-checkout-progress-active {
//                &::before {
//                    font-weight: $font-weight-bold;
//                    border-color: theme-color("success");
//                }
//            }
//        }
//    }
//}
