.p-banner {
    @include e-canvas-padding;
    position: relative;
    margin-bottom: $paragraph-margin-bottom;
    display: flex;
    @for $size from 1 through 5 {
        &.pt-#{$size} {
            padding-top: ( $spacer * $size) !important;
        }
        &.pb-#{$size} {
            padding-bottom: ( $spacer * $size) !important;
        }
    }
    .p-txt-container {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            @include media-breakpoint-down(sm) {
                background: rgba(0, 0, 0, 0.45);
            }
        }
    }
    .p-txt-wrapper {
        padding-top: $spacer;
        padding-bottom: $spacer;
        @include media-breakpoint-up(md) {
            padding-top: $spacer;
            padding-bottom: $spacer;
        }
        @include media-breakpoint-up(lg) {
            padding-top: $spacer * 1.5;
            padding-bottom: $spacer * 1.5;
        }
    }
    h1,
    h2,
    h3,
    h4,
    p {
        color: e-section-color("dark", "headings");
        margin-bottom: 0.5rem;
        &:last-child {
            margin-bottom: 0;
        }
    }
    h4 {
        color: e-section-color("dark", "text");
    }
    p {
        color: e-section-color("dark", "text");
    }
    a:not(.btn) {
        text-decoration: underline;
        color: inherit;
    }
    .btn {
        margin-top: 1.5rem;
    }
    .p-img-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
    }
}

.p-banner-small {
    @extend .p-section-small;
}

.p-banner-medium {
    @extend .p-section-medium;
}

.p-banner-large {
    @extend .p-section-large;
}

.p-banner-fullscreen {
    @extend .p-section-fullscreen;
}

.p-banner-auto {
    .p-txt-wrapper {
        padding-top: $spacer * 2;
        padding-bottom: $spacer * 2;
    }
}

.p-banner-txt-left-top {
    padding-bottom: $spacer * 2;
    &::before {
        background: linear-gradient(157deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 80%);
    }
}

.p-banner-txt-left-middle {
    padding-top: $spacer * 2;
    padding-bottom: $spacer * 2;
    &::before {
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 80%);
    }
}

.p-banner-txt-left-bottom {
    padding-top: $spacer * 2;
    &::before {
        background: linear-gradient(22deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 80%);
    }
}

.p-banner-txt-center-top {
    padding-bottom: $spacer * 2;
    &::before {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 65%);
    }
}

.p-banner-txt-center-middle {
    padding-top: $spacer * 2;
    padding-bottom: $spacer * 2;
    &::before {
        background: rgba(0, 0, 0, 0.55);
    }
}

.p-banner-txt-center-bottom {
    padding-top: $spacer * 2;
    &::before {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 65%);
    }
}

.p-banner-txt-right-top {
    padding-bottom: $spacer * 2;
    &::before {
        background: linear-gradient(202deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 80%);
    }
}

.p-banner-txt-right-middle {
    padding-top: $spacer * 2;
    padding-bottom: $spacer * 2;
    &::before {
        background: linear-gradient(270deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 80%);
    }
}

.p-banner-txt-right-bottom {
    padding-top: $spacer * 2;
    &::before {
        background: linear-gradient(338deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 80%);
    }
}

.p-banner-gradient-disabled {
    .p-txt-container::before {
        background: none;
    }
}

.p-banner-animation-image-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    transform: translateZ(0);
    .p-banner-animation-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        background-position: 50% 50%;
        opacity: 0;
        will-change: transform;
        &.animate-in {
            opacity: 1;
            transition: opacity ($transition-fade-speed * 2.5) $transition-fade-easing;
            animation: zoom ($transition-fade-speed * 25) linear forwards;
            -ms-animation-name:none;
        }
        &.animate-out {
            opacity: 0;
            transition: opacity ($transition-fade-speed * 5) $transition-fade-easing;
            transform: $transform-scale-zoom;
        }
    }
}

.p-banner-next-section-link {
    color: white;
    font-size: 3em;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    .p-banner-next-section-link-icon {
        animation: bounce 5s infinite;
        animation-fill-mode: both;
        transform-origin: center bottom;
    }
    &:hover,
    &:active {
        color: white;
    }
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.p-banner-img-left-top {
    background-position: 0 0;
}

.p-banner-img-left-middle {
    background-position: 0 50%;
}

.p-banner-img-left-bottom {
    background-position: 0 100%;
}

.p-banner-img-center-top {
    background-position: 50% 0;
}

.p-banner-img-center-middle {
    background-position: 50% 50%;
}

.p-banner-img-center-bottom {
    background-position: 50% 100%;
}

.p-banner-img-right-top {
    background-position: 100% 0;
}

.p-banner-img-right-middle {
    background-position: 100% 50%;
}

.p-banner-img-right-bottom {
    background-position: 100% 100%;
}
