.p-section {
    position: relative;
    border: 0 solid $border-color;
    padding-top: $spacer * 2;
    padding-bottom: $spacer * 2;
    @for $size from 1 through 5 {
        &.pt-#{$size} {
            padding-top: ($spacer * $size) !important;
        }
        &.pb-#{$size} {
            padding-bottom: ($spacer * $size) !important;
        }
    }
    &.pl-0 {
        .container-fluid {
            padding-left: 0;
            > .row {
                margin-left: 0;
            }
        }
        .col-12.col-md-12.col-lg-12 {
            padding-left: 0;
            >.row {
                margin-left: 0;
                > .p:first-child {
                    padding-left: 0;
                }

                @include media-breakpoint-up(md) {
                    > .p-columns:first-child {
                        padding-left: 0;

                        >.row {
                            margin-left: 0;
                            >.col-12 {
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    &.pr-0 {
        .container-fluid {
            padding-right: 0;
            > .row {
                margin-right: 0;
            }
        }
        .col-12.col-md-12.col-lg-12 {
            padding-right: 0;
            >.row {
                margin-right: 0;
                > .p:last-child {
                    padding-right: 0;
                }
                @include media-breakpoint-up(md) {
                > .p-columns:last-child {
                    padding-right: 0;

                        >.row {
                            margin-right: 0;
                            >.col-12 {
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .row.no-gutters {
        .p-columns > .row {
            margin-right: 0;
            margin-left: 0;

            > .col,
            > [class*="col-"] {
              padding-right: 0;
              padding-left: 0;
            }
        }
    }
}

// Height
.p-section-small {
    height: $section-height-small;
    max-height: 90vh;
    @include media-breakpoint-down(xs) {
        height: auto;
        min-height: $section-height-small * 2;
    }
    @media (min-width: $canvas-max-width + 1px) {
        height: ( $canvas-max-width / 100) * e-strip-unit( $section-height-small);
    }
}

.p-section-medium {
    height: $section-height-medium;
    max-height: 90vh;
    @include media-breakpoint-down(xs) {
        height: auto;
        min-height: $section-height-medium * 2;
    }
    @media (min-width: $canvas-max-width + 1px) {
        height: ( $canvas-max-width / 100) * e-strip-unit( $section-height-medium);
    }
}

.p-section-large {
    height: $section-height-large;
    max-height: 90vh;
    @include media-breakpoint-down(xs) {
        height: auto;
        min-height: $section-height-large * 2;
    }
    @media (min-width: $canvas-max-width + 1px) {
        height: ( $canvas-max-width / 100) * e-strip-unit( $section-height-large);
    }
}

.p-section-fullscreen {
    height: 100vh;
}

// Colors
.p-section-bg-white {
    @extend .e-section-white;
}

.p-section-bg-light {
    @extend .e-section-light;
}

.p-section-bg-dark {
    @extend .e-section-dark;
}

.p-section-bg-black {
    @extend .e-section-black;
}

.p-section-bg-brand-primary {
    @extend .e-section-primary;
}

.p-section-bg-brand-secondary {
    @extend .e-section-secondary;
}

.p-section-bg-brand-tertiary {
    @extend .e-section-tertiary;
}

.p-section-bg-brand-quaternary {
    @extend .e-section-quaternary;
}

// Text
.p-section-txt-dark {
    @extend .e-text-dark;
}

.p-section-txt-light {
    @extend .e-text-light;
}

//Background size and position
.p-section-bg-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.p-section-bg-contain {
    background-size: contain;
}

.p-section-bg-left-top {
    background-position: 0 0;
}

.p-section-bg-left-middle {
    background-position: 0 50%;
}

.p-section-bg-left-bottom {
    background-position: 0 100%;
}

.p-section-bg-center-top {
    background-position: 50% 0;
}

// Default value
// .p-section-bg-center-middle {
//    background-position: 50% 50%;
// }
.p-section-bg-center-bottom {
    background-position: 50% 100%;
}

.p-section-bg-right-top {
    background-position: 100% 0;
}

.p-section-bg-right-middle {
    background-position: 100% 50%;
}

.p-section-bg-right-bottom {
    background-position: 100% 100%;
}

// Spacing
@for $size from 1 through 5 {
    .p-section-bg-mt-#{$size} {
        top: 2.5 * $size * 1%;
    }
    .p-section-bg-mr-#{$size} {
        right: 2.5 * $size * 1%;
    }
    .p-section-bg-mb-#{$size} {
        bottom: 2.5 * $size * 1%;
    }
    .p-section-bg-ml-#{$size} {
        left: 2.5 * $size * 1%;
    }
}

// Borders
.p-section-border-top {
    border-top-width: $border-width;
}

.p-section-border-bottom {
    border-bottom-width: $border-width;
}

// Border colors
.p-section-border-white {
    border-color: #fff;
}

.p-section-border-black {
    border-color: #000;
}

.p-section-border-light {
    border-color: e-section-color("dark", "border");
}

.p-section-border-dark {
    border-color: e-section-color("light", "border");
}

.p-section-border-brand-primary {
    border-color: theme-color("primary");
}

.p-section-border-brand-secondary {
    border-color: theme-color("secondary");
}

.p-section-border-brand-tertiary {
    border-color: theme-color("tertiary");
}

.p-section-border-brand-quaternary {
    border-color: theme-color("quaternary");
}

.p-section-collapse {
    >.container-fluid {
        >.row {
            &::after {
                content: "";
                display: block;
                flex: 0 0 100%;
                order: 2;
            }
        }
    }
}

.p-section-collapse-content {
    &:not(.show) {
        display: block;
        overflow: hidden;
        height: 25vh;
        &.is-sm {
            height: 12.5vh;
        }
        &.is-lg {
            height: 37.5vh;
        }
    }
    &::after {
        content: "";
        display: block;
        height: $spacer;
    }
}

.p-section-collapse-content.collapsing:not(.show) {
    //height: 25vh;
}

.p-section-collapse-toggle {
    z-index: 99;
    cursor: pointer;
    font-size: $font-size-sm;
    &::before {
        content: "";
        position: absolute;
        right: 0;
        left: 0;
        bottom: 100%;
        height: 0;
        transition: height $transition-collapse-speed * 2 $transition-collapse-easing;
    }
    &.collapsed {
        &::before {
            height: $spacer * 0.25;
            background: radial-gradient(50% 100% at 50% 100%, rgba(0, 0, 0, 0.15) 0%, transparent 100%), radial-gradient(50% 25% at 50% 100%, rgba(0, 0, 0, 0.35) 0%, transparent 100%);
        }
    }
}

.p-section-collapse-toggle-text {
    margin-top: $spacer * -0.25;
    display: block;
    font-size: $font-size-sm;
    &::after {
        content: attr(data-collapse-text);
    }
    .collapsed & {
        &::after {
            content: attr(data-expand-text);
        }
    }
}

.p-section-collapse-toggle-btn {
    display: block;
    padding: 0;
    height: $spacer;
    width: $spacer;
    line-height: 1;
    margin: 0 auto;
    font-size: $spacer;
    box-shadow: $shadow-sm;
    transform: translateY(-50%);
    transition: none;
    &:hover,
    &:active {
        // background: e-section-color("dark", "bg");
        // color: $white;
        // border-color: e-section-color("dark", "bg");
    }
}

.p-section-collapse-toggle-icon {
    line-height: $spacer;
    transform: rotate(540deg) translateY(0.0675em);
    transition: transform $transition-collapse-speed $transition-collapse-easing;
    .collapsed & {
        transform: rotate(0deg) translateY(0.0675em);
    }
}
