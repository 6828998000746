.headerBanner {
    .p-txt-wrapper > div {
        @media (min-width: 1200px) {
            -ms-flex: 0 0 35%;
            flex: 0 0 35%;
            max-width: 35%;
            margin-left: 8.66667%;
        }
    }
}

.headerBanner {
    .p-txt-wrapper {
        p {
            color: $white;
            margin-bottom: 0;
            line-height: 1.5em;
        }

        h4 {
            color: $white;
            font-size: 1.1em;
            padding-bottom: 0.5em;
        }

        h1 {
            color: $white;
            margin-top: 0;
            font-weight: 900;
            font-size: 2.5em;
        }

        .btn {
            margin-top: 2em;
            font-size: 0.9em;
        }
    }
}

.ProductCatalogue {
    .p-txt-wrapper > div {
        @media (min-width: 1200px) {
            -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
            max-width: 83.33333%;
            padding-right: 50%;
        }
    }
}

.ProductCatalogue {
    .p-txt-wrapper {
        h4 {
            color: theme-color("tertiary");
        }

        h2 {
            color: theme-color("tertiary");
        }

        p {
            color: theme-color("tertiary");
        }
    }
}

.p-section-small, .p-banner-small, .p-carousel-small .p-carousel-item {
    height: auto;
    max-height: initial;

    @media (min-width: 1200px) {
        min-height: 55vh;
    }
}

.p-banner-txt-center-middle::before {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.64) 99%, rgba(0, 0, 0, 0.65) 100%);
}

.p-banner h2 {
    font-size: 2em;
}