.e-nav-container {
    &.navbar {
        flex: unset;
        flex-flow: row wrap;
        display: flex;
    }

    .e-header-section {
        order: 2;
        display: block;
        width: 100%;
    }
    @include media-breakpoint-down($nav-hamburger-breakpoint) {
        .e-header-section {
        order: inherit;
        }
    }

    .bg-tertiary {
        order: 1;
        display: block;
        width: 100%;
    }
}

@media (min-width: 992px) {
    .e-logo .e-logo-link {
        height: 4.4em;
    margin: 0 0 1.5em 0;
    padding-bottom: 0.2em;
    }
}

.e-nav-container .bg-tertiary {
    background: #fff !important;
}

@media (min-width: 992px) {
    .e-nav-global > .nav > .nav-item > .nav-link {
            padding: 2.7em 1em 2em 1em;
    }

    .e-nav .e-logo {
        //margin-left: 11%;
    }

    .e-nav-utilities-primary > .nav > .nav-item > .nav-link {
        padding: 1em 1em 0 1em;
    }
}
/*
@media (min-width: 1300px) {
.e-header-section {padding: 0 11vw;}
}
*/

