.e-cart {
    position: relative;
    .e-loading-overlay {
        position: fixed;
        max-height: 100vh;
    }
}

.e-cart-header {

}

.e-cart-body {

}

.e-cart-footer {

}

.e-orderline {
    + .e-orderline {
        border-top: $border-width solid $border-color;
    }
    .form-control {
        max-width: 5em;
    }
}

@include media-breakpoint-up(md) {
    .e-cart-summery {
        position: sticky;
        top: $spacer;
    }
}
