// https://medium.com/wdstack/bootstrap-4-custom-carousel-94a537364fde

// Wrapper for the slide container and indicators
.carousel {
    height: 50vw;
}

.carousel-inner {
    height: 100%;
}

.carousel-item {
    height: 100%;
}

//
// Left/right controls for nav
//
.carousel-control-prev,
.carousel-control-next {
    cursor: pointer;
    width: $carousel-control-width;
    background: no-repeat center center;
    background-size: $carousel-control-size $carousel-control-size;
    overflow: hidden; // TODO: Hack!! Fix!!
    .carousel:not(.image-gallery):hover & {
        opacity: 1;
    }
}

.carousel-control-prev {
    background-image: $carousel-control-prev-icon;
}

.carousel-control-next {
    background-image: $carousel-control-next-icon;
}

// Optional indicator pips
//
// Add an unordered list with the following class and add a list item for each
// slide your carousel holds.
.carousel-indicators {
    bottom: $spacer / 2;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    li {
        font-size: 0.75em;
        border-radius: 50%;
        background: $carousel-indicator-active-bg;
        border: $border-width solid transparent;
        margin-bottom: 0;
        padding: 0;
        background-clip: content-box;
        transition: border-color $transition-slide-speed, background-color $transition-slide-speed;
    }
    .active {
        background: none;
        border-color: $carousel-indicator-active-bg;
    }
}

// Optional captions
//
//
.carousel-caption {
    @include e-canvas-padding;
    right: 0;
    bottom: $spacer;
    left: 0;
    padding-top: $spacer;
    padding-bottom: $spacer;
    text-align: left;
    .active & {
        opacity: 1;
        transform: translateY(0);
        transition: opacity $transition-slide-speed $transition-slide-easing, transform $transition-slide-speed * 2 $transition-slide-easing;
    }
    .active.carousel-item-left & {
        //På vej ud mod ventre
        opacity: 0.5;
        transition: opacity $transition-slide-speed * 1.5 $transition-slide-easing;
    }
    .active.carousel-item-right & {
        //På vej ud mod højre
        opacity: 0.5;
        transition: opacity $transition-slide-speed * 1 $transition-slide-easing;
    }
    .carousel-item-next.carousel-item-left & {
        // På vej ind fra højre
        transform: translateY($spacer*2);
        opacity: 0;
    }
    .carousel-item-prev.carousel-item-right & {
        // På vej ind fra ventre
        opacity: 0;
        transform: translateY($spacer*2);
    }
    h1,
    h2,
    h3,
    h4,
    p {
        color: rgba($carousel-caption-color, 1);
        line-height: $headings-line-height;
        margin-bottom: 0.5rem;
    }
    h4 {
        color: rgba($carousel-caption-color, 0.75);
        margin-bottom: 0.25rem;
    }
    p {
        color: rgba($carousel-caption-color, 0.75);
    }
    .btn {
        margin-top: 1rem;
        pointer-events: auto;
    }
}
