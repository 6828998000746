// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s or `<ul>`s.
.nav {
    margin: 0;
}

.nav-item {
    margin: 0;
    padding: 0;
    &:last-child {
        .dropdown-menu {
            left: auto;
            right: 0;
        }
    }

}

.nav-link {
    cursor: pointer;
    text-decoration: none;
}

//
// Pills
//
.nav-pills {
    margin-bottom: $spacer;
    .nav-item {
        margin: 0 ($spacer / 3) ($spacer / 3) 0;
        + .nav-item {
            margin-left: 0;
        }
    }
    .nav-link {
        color: $component-active-bg;
        border: $border-width solid $component-active-bg;
    }
}
