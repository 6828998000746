@mixin button-variant($background, $border: $background, $color: color-yiq($background, $yiq-headings-light, $yiq-headings-dark), $hover-background: $background, $hover-border: $border, $hover-color: $color, $active-background: $background, $active-border: $border, $active-color: $color) {
    color: $color; // e4
    @include gradient-bg($background);
    border-color: $border;
    @include box-shadow($btn-box-shadow);
    @include hover {
        color: $hover-color; // e4
        @include gradient-bg($hover-background);
        border-color: $hover-border;
    }
    &:focus,
    &.focus {
        // Avoid using mixin so we can pass custom focus shadow properly
        @if $btn-box-shadow !=none {
            // e4
            @if $enable-shadows {
                box-shadow: $btn-box-shadow, 0 0 0 $input-btn-focus-width rgba($border, .5);
            }
            @else {
                box-shadow: 0 0 0 $input-btn-focus-width rgba($border, .5);
            }
        }
    }
    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        background-color: $background;
        border-color: $border;
    }
    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: $active-color; // e4
        background-color: $active-background;
        @if $enable-gradients {
            background-image: none; // Remove the gradient for the pressed/active state
        }
        border-color: $active-border;
        // Avoid using mixin so we can pass custom focus shadow properly
        @if $btn-box-shadow !=none {
            // e4
            @if $enable-shadows {
                box-shadow: $btn-active-box-shadow, 0 0 0 $input-btn-focus-width rgba($border, .5);
            }
            @else {
                box-shadow: 0 0 0 $input-btn-focus-width rgba($border, .5);
            }
        }
    }
}

@mixin button-outline-variant($color, $color-hover: color-yiq($color, $yiq-headings-light, $yiq-headings-dark)) {
    color: $color;
    background-color: transparent;
    background-image: none;
    border-color: $color;
    @include hover {
        color: $color-hover;
        background-color: $color;
        border-color: $color;
    }
    &:focus,
    &.focus {
        box-shadow: 0 0 0 $input-btn-focus-width rgba($color, .5);
    }
    &.disabled,
    &:disabled {
        color: $color;
        background-color: transparent;
    }
    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: $color-hover;
        background-color: $color;
        border-color: $color;
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: 0 0 0 $input-btn-focus-width rgba($color, .5);
    }
}
