.e-products {
    .e-products-item {
        // Limit hover state to larger/non-touch devices.
        // TODO: Lav media query til target touch device
        position: relative;
        padding: ($spacer * 1.5) 0;
        cursor: pointer;
        @include media-breakpoint-up(xl) {
            &:hover {
                border: 0;
            }
            &::before {
                content: "";
                display: none;
                position: absolute;
                top: $spacer / -2;
                bottom: $spacer / -2;
                left: $spacer / -2;
                right: $spacer / -2;
                z-index: 1;
                background-color: $body-bg;
                box-shadow: $shadow-md;
            }
            &:hover {
                z-index: 2;
                &::before {
                    display: block;
                }
                .is-hidden-on-hover {
                    display: none;
                }
                .is-visible-on-hover {
                    display: block;
                }
            }
        }
        .is-hidden-on-hover {
            display: block;
        }
        .is-visible-on-hover {
            display: none;
        }
    }
    .e-products-item-container {
        position: relative;
        z-index: 2;
    }
    .e-products-item-image-container {
        position: relative;
        margin-bottom: $spacer;
        padding-top: percentage( 3 / 4);
    }
    .e-products-item-image-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .e-products-item-image {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }
    .e-products-item-text-container {
        a {
            color: inherit;
            text-decoration: none;
        }
    }
    .e-products-item-name {
        color: $headings-color;
    }
    .e-products-item-text {}
    .e-products-item-price {}
}

.e-productslist-header {
    //border-bottom: $border-width solid $border-color;
    padding-bottom: 0.5em;
}

.e-productlist-paging {

}

.e-products-footer {
    // border-top: $border-width solid $border-color;
}
