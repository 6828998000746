.e-products-loading-overlay {
    background: rgba($loading-bg, .95);
    z-index: $zindex-fixed - 2;
    .e-loading-spinner {
        top: 20vh;
    }
}

.e-productlist-product-count {
    &:empty {
        display: none;
    }
    &::before {
        content: '(';
    }
    &::after {
        content: ')';
    }
}

.e-productlist-filters-range-group {
    .e-productlist-filters-clear-range {
        color: theme-color("danger");
    }
    &.is-empty {
        .e-productlist-filters-apply-range {
            opacity: $text-muted;
        }
        .e-productlist-filters-clear-range {
            pointer-events: none;
            opacity: 0;
        }
    }
}

.e-productlist-filters-group {
    &:not(:last-child) {
        border-bottom: $border-width solid $border-color;
    }

}

.e-productlist-filters-group-header {
    color: inherit;
    display: flex;
    //justify-content: space-between;
    align-items: center;
    .e-productlist-filters-group-collapse-toggle {
        cursor: pointer;
        flex-grow: 1;
        display: flex;
        align-items: center;
        &,
        &:hover {
            text-decoration: none;
        }
        .material-icons {
            transform: rotate(0deg) scale(1.5);
            transition: transform $transition-collapse-speed $transition-collapse-easing;
        }
        &.collapsed {
            .material-icons {
                transform: rotate(-90deg) scale(1.5);
            }
        }
    }
    .e-productlist-filters-group-name {
        flex-grow: 1;
        padding: ($spacer*0.75) 0 ($spacer*0.75) 0.375em;

    }
}

.e-productlist-filters-option-selected {
    .material-icons {
        transform: rotate(45deg);
    }
}

.e-productlist-filters-group-options {
    padding-left: 0.25em;
    .e-nav-local {
        position: relative;
        .e-nav-local-container {
            font-size: 1em;
            .nav {
                > .nav-item {
                    border: 0;
                    > .nav-link {
                        color: $link-color;
                        font-weight: inherit;
                        padding: ($spacer * 0.375) 0;
                    }
                }
            }
        }
    }
}

.e-productlist-filters-options-collapse-peek {
    overflow: hidden;
    position: relative;
    max-height: 100vh;
    padding-bottom: $spacer * 1.5;
    transition: all $transition-collapse-speed $transition-collapse-easing;
    .e-productlist-filters-options-collapse-peek-toggle {
        text-decoration: none;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;
        transition: all $transition-collapse-speed $transition-collapse-easing;
        &::after {
            content: attr(data-open-text);
            padding-left: 0.375em;
        }
        .material-icons {
            transform: rotate(180deg) scale(1.5);
            transition: transform $transition-collapse-speed $transition-collapse-easing;
        }
    }
    &.is-peeking {
        max-height: 33vh;
        .e-productlist-filters-options-collapse-peek-toggle {
            &::before {
                content: "";
                height: $spacer * 2;
                position: absolute;
                top: $spacer * -2;
                left: 0;
                right: 0;
                background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0.01) 95%);
            }
            &::after {
                content: attr(data-closed-text);
                padding-left: 0.375em;
            }
            .material-icons {
                transform: rotate(0) scale(1.5);
            }
        }
    }
}

.e-productlist-filters-option {
    &.is-selected {
        label {
            // font-weight: $font-weight-bold;
        }
    }
    &.is-disabled {
        label {
            cursor: not-allowed;
        }
    }
}

.e-productlist-filters-search-input {
    // TODO: Fix this
    // font-size: 1em!important;
    // &:hover,
    // &:focus {
    //     font-size: 1em!important;
    // }
}

.e-productlist-filters-modal {
    .modal-header {

    }
    .modal-body {
        .e-productlist-filters-group {
        }
        .e-productlist-filters-group-summery {
            order: 1;
            border-top: $border-width solid $border-color;
            border-bottom: 0;
        }
    }
    .modal-footer {
        position: sticky;
        bottom: 0;
    }
}
