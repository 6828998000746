.e-cart-confirm {
    position: fixed;
    top: -1px; //$spacer;
    right: $spacer;
    width: 100 / $grid-columns * 5%;
    z-index: $zindex-fixed - 1;
    overflow: hidden;
    background: $body-bg;
    box-shadow: $shadow-md;
    transition: opacity $transition-fade-speed $transition-fade-easing, top $transition-slide-speed $transition-slide-easing;
    &.is-hidden {
        top: -100%;
        //right: -100%;
        opacity: 0;
        pointer-events: none;
        //transition: all 2s ease;
        transition: opacity $transition-fade-speed $transition-fade-easing, top $transition-slide-speed * 2 $transition-slide-easing;
    }
}

@include media-breakpoint-down($nav-hamburger-breakpoint) {
    .e-cart-confirm-body,
    .e-cart-confirm-footer {
        //display: none;
    }
}

@include media-breakpoint-up(breakpoint-next($nav-hamburger-breakpoint)) {
    .e-cart-confirm-footer {
        .btn {
            flex-grow: 1;
        }
    }
}
