/*.p-section-columns {
    .formtable {
        tr {
            display: block;
        }

        td {
            display: block;
        }

        .labelCell {
            font-size: 1em;
            color: theme-color("tertiary");
            padding-bottom: 0px;
            margin: 0;

            label {
                margin: 0;
            }
        }
    }
}

.formtable input[type=text], .formtable input[type=password], .formtable textarea {
    min-width: 250px;
    border-radius: 0.3em;
    box-shadow: none;
    border: 0.1em solid #C9D1CE;
    padding: 0.5em;
    width: 100%;
}

.formtable input[type="submit"] {
    border-radius: 10em;
    background: black;
    color: #fff;
    padding: 1em 2.2em;
    font-size: 0.9em;
    border: none;
}
*/