html {
    @include media-breakpoint-up(xs) {
        font-size: $font-size-root * 0.875;
    }
    @include media-breakpoint-up(sm) {
        font-size: $font-size-root * 0.9375;
    }
    @include media-breakpoint-up(md) {
        font-size: $font-size-root * 1;
    }
    @include media-breakpoint-up(lg) {
        font-size: $font-size-root * 1;
    }
    @include media-breakpoint-up(xl) {
        font-size: $font-size-root * 1;
    }
}

body {
}

//
// Headings
//
h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
    color: $headings-color;
    line-height: $headings-line-height;
    margin-top: $headings-margin-top;
    margin-bottom: $headings-margin-bottom;
    &:first-child {
        margin-top: 0;
    }
    a {
        color: inherit;
        text-decoration: none;
        &:hover {
            color: inherit;
            text-decoration: underline;
        }
    }
}

h1,
.h1 {
    font-family: $h1-font-family;
    font-size: $h1-font-size;
    font-weight: $h1-font-weight;
    // letter-spacing: -0.025em;
    &.small {
        font-size: $h1-font-size * 0.875;
        font-weight: $h1-font-weight;
    }
    @include media-breakpoint-down(sm) {
        font-size: $h1-font-size * 0.875;
        &.small {
            font-size: e-strip-unit($h1-font-size) * $small-font-size * 0.875;
        }
    }
    @include media-breakpoint-down(xs) {
        font-size: $h1-font-size * 0.75;
        &.small {
            font-size: e-strip-unit($h1-font-size) * $small-font-size * 0.75;
        }
    }
}

h2,
.h2 {
    font-family: $h2-font-family;
    font-size: $h2-font-size;
    font-weight: $h2-font-weight;
    &.small {
        font-size: $h2-font-size * 0.875;
        font-weight: $h2-font-weight;
    }
    @include media-breakpoint-down(sm) {
        font-size: $h2-font-size * 0.9375;
        &.small {
            font-size: e-strip-unit($h2-font-size) * $small-font-size * 0.9375;
        }
    }
    @include media-breakpoint-down(xs) {
        font-size: $h2-font-size * 0.875;
        &.small {
            font-size: e-strip-unit($h2-font-size) * $small-font-size * 0.875;
        }
    }
}

h3,
.h3 {
    font-family: $h3-font-family;
    font-size: $h3-font-size;
    font-weight: $h3-font-weight;
    line-height: $headings-line-height * 1.125;
    margin-bottom: $headings-margin-bottom / 2;
    &.small {
        font-size: $h3-font-size * 0.875;
        font-weight: $h3-font-weight;
    }
    @include media-breakpoint-down(sm) {
        font-size: $h3-font-size * 1.0625;
        &.small {
            font-size: e-strip-unit($h3-font-size) * $small-font-size * 1.0625;
        }
    }
    @include media-breakpoint-down(xs) {
        font-size: $h3-font-size * 1.125;
        &.small {
            font-size: e-strip-unit($h3-font-size) * $small-font-size * 1.125;
        }
    }
}

h4,
.h4 {
    font-family: $h4-font-family;
    font-size: $h4-font-size;
    font-weight: $h4-font-weight;
    margin-bottom: $headings-margin-bottom;
    text-transform: uppercase;
    letter-spacing: 0.0625em;
    &.small {
        font-size: $h4-font-size * 0.875;
        font-weight: $h4-font-weight;
    }
    + h1,
    + h2,
    + h3,
    + h4,
    + .h1,
    + .h2,
    + .h3,
    + .h4{
        margin-top: 0;
    }
}

.lead {
    font-family: $lead-font-family;
    font-size: $lead-font-size;
    font-weight: $lead-font-weight;
    &.small {
        font-size: $lead-font-size * 0.875;
        font-weight: $lead-font-weight;
    }
    @include media-breakpoint-down(xs) {
        font-size: $lead-font-size * 0.9375;
        &.small {
            font-size: e-strip-unit($lead-font-size) * $small-font-size * 0.9375;
        }
    }
}

p,
.p ul,
.p ol {
    @include media-breakpoint-down(md) {
        font-size: $font-size-base * 1.0625;
        &.small {
            font-size: e-strip-unit($font-size-base) * $small-font-size * 1.0625;
        }
    }
    @include media-breakpoint-down(sm) {
        font-size: $font-size-base * 1.125;
        &.small {
            font-size: e-strip-unit($font-size-base) * $small-font-size * 1.125;
        }
    }
}

//
// Horizontal rules
//
hr {
    margin: $hr-margin;
    border: 0;
    border-top: $hr-border-width solid $hr-border-color;
}

//
// Emphasis
//
strong {
    font-weight: $font-weight-bold;
}

small,
.small {
    font-size: $small-font-size;
    font-weight: $small-font-weight;
}

//
// Links
//
a[href^="tel:"] {
    @include media-breakpoint-up(md) {
        color: inherit !important;
        text-decoration: none !important;
        pointer-events: none !important;
    }
}

a[data-toggle],
a[data-target] {
    cursor: pointer;
}

a.arrow-left::before,
a.arrow-right::after,
a.arrow-up::before,
a.arrow-down::after {
    @include material-icons;
    color: inherit;
    text-decoration: none;
    transition: transform $transition-slide-speed / 2 $transition-slide-easing;
}

a.arrow-right::after,
a.arrow-down::after {
    margin: 0 0 0 0.5em;
}

a.arrow-left::before,
a.arrow-up::before {
    margin: 0 0.5em 0 0;
}

a.arrow-right {
    &::after {
        content: "\E5C8" // "arrow_forward";
    }
    &:hover::after {
        transform: translateX(0.5em);
    }
}

a.arrow-left {
    &::before {
        content: "\E5C4"; // "arrow_back"
    }
    &:hover::before {
        transform: translateX(-0.5em);
    }
}

a.arrow-up {
    &::before {
        content: "\E5D8" // "arrow_upward";
    }
    &:hover::before {
        transform: translateY(-0.5em);
    }
}

a.arrow-down {
    &::after {
        content: "\E5DB" // "arrow_downwards";
    }
    &:hover::after {
        transform: translateY(0.5em);
    }
}

//
// Lists
//
ol,
ul {
    margin-top: $paragraph-margin-bottom;
    margin-bottom: $paragraph-margin-bottom * 1.5;
    padding-left: $spacer * 1.5;
    &:first-child {
        margin-top: 0;
    }
    h1 + &,
    h2 + &,
    h3 + &,
    h4 + & {
        margin-top: $paragraph-margin-bottom;
    }
    li {
        margin-bottom: $paragraph-margin-bottom * 0.5;
        padding-left: $spacer * 0.5;
        // &:not(.nav-item)>a:first-child:last-child {
        //     text-decoration: none;
        //     &:hover {
        //         text-decoration: underline;
        //     }
        // }
    }
}

//
// Lists
//
.list-unstyled {
    @include list-unstyled;
}

.list-condensed {
    > li {
        line-height: $line-height-sm;
        margin-bottom: $paragraph-margin-bottom * 0.4375;
    }
}

// Inline turns list items into inline-block
.list-inline {
    @include list-unstyled;
}

.list-inline-item {
    display: inline-block;
}

//
// Misc
//
// // Builds on `abbr`
// .initialism {
//   font-size: 90%;
//   text-transform: uppercase;
// }
// // Blockquotes
// .blockquote {
//   margin-bottom: $spacer;
//   font-size: $blockquote-font-size;
// }
// .blockquote-footer {
//   display: block;
//   font-size: 80%; // back to default font-size
//   color: $blockquote-small-color;
//   &::before {
//     content: "\2014 \00A0"; // em dash, nbsp
//   }
// }
// dw styles
pre.dw-error {
    white-space: normal;
}
