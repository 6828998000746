.e-cc-list {
}

.e-cc-list-collapse-toggle-icon {
    transform:  rotate(0deg) scale(1.5);
    transition: transform $transition-collapse-speed $transition-collapse-easing;
    .collapsed & {
        transform:  rotate(-90deg) scale(1.5);
    }
}
.e-cc-list-delete-icon{
    transform: rotate(45deg);
}
