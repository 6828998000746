@charset "UTF-8";
/*!
 * Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url("https://fonts.googleapis.com/css?family=Ruda:400,700,900");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Ruda", sans-serif;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.75em;
}

p {
  margin-top: 0;
  margin-bottom: 1.5em;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #2d9ee0;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #2d9ee0;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 1.3125em 0.1875em;
  padding-bottom: 1.3125em 0.1875em;
  color: 0.65;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

html {
  font-size: 14px;
}

@media (min-width: 576px) {
  html {
    font-size: 15px;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 16px;
  }
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.25;
  margin-top: 1.5em;
  margin-bottom: 0.75em;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
.h1:first-child,
.h2:first-child,
.h3:first-child,
.h4:first-child {
  margin-top: 0;
}

h1 a,
h2 a,
h3 a,
h4 a,
.h1 a,
.h2 a,
.h3 a,
.h4 a {
  color: inherit;
  text-decoration: none;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
.h1 a:hover,
.h2 a:hover,
.h3 a:hover,
.h4 a:hover {
  color: inherit;
  text-decoration: underline;
}

h1,
.h1 {
  font-family: inherit;
  font-size: 2.5em;
  font-weight: 900;
}

h1.small,
.h1.small {
  font-size: 2.1875em;
  font-weight: 900;
}

@media (max-width: 767px) {
  h1,
  .h1 {
    font-size: 2.1875em;
  }
  h1.small,
  .h1.small {
    font-size: 1.91406em;
  }
}

@media (max-width: 575px) {
  h1,
  .h1 {
    font-size: 1.875em;
  }
  h1.small,
  .h1.small {
    font-size: 1.64063em;
  }
}

h2,
.h2 {
  font-family: inherit;
  font-size: 1.625em;
  font-weight: 900;
}

h2.small,
.h2.small {
  font-size: 1.42188em;
  font-weight: 900;
}

@media (max-width: 767px) {
  h2,
  .h2 {
    font-size: 1.52344em;
  }
  h2.small,
  .h2.small {
    font-size: 1.33301em;
  }
}

@media (max-width: 575px) {
  h2,
  .h2 {
    font-size: 1.42188em;
  }
  h2.small,
  .h2.small {
    font-size: 1.24414em;
  }
}

h3,
.h3 {
  font-family: inherit;
  font-size: 1.125em;
  font-weight: 900;
  line-height: 1.40625;
  margin-bottom: 0.375em;
}

h3.small,
.h3.small {
  font-size: 0.98438em;
  font-weight: 900;
}

@media (max-width: 767px) {
  h3,
  .h3 {
    font-size: 1.19531em;
  }
  h3.small,
  .h3.small {
    font-size: 1.0459em;
  }
}

@media (max-width: 575px) {
  h3,
  .h3 {
    font-size: 1.26563em;
  }
  h3.small,
  .h3.small {
    font-size: 1.10742em;
  }
}

h4,
.h4 {
  font-family: inherit;
  font-size: 1.0625em;
  font-weight: 400;
  margin-bottom: 0.75em;
  text-transform: uppercase;
  letter-spacing: 0.0625em;
}

h4.small,
.h4.small {
  font-size: 0.92969em;
  font-weight: 400;
}

h4 + h1,
h4 + h2,
h4 + h3,
h4 + h4,
h4 + .h1,
h4 + .h2,
h4 + .h3,
h4 + .h4,
.h4 + h1,
.h4 + h2,
.h4 + h3,
.h4 + h4,
.h4 + .h1,
.h4 + .h2,
.h4 + .h3,
.h4 + .h4 {
  margin-top: 0;
}

.lead {
  font-family: inherit;
  font-size: 1.5em;
  font-weight: 400;
}

.lead.small {
  font-size: 1.3125em;
  font-weight: 400;
}

@media (max-width: 575px) {
  .lead {
    font-size: 1.40625em;
  }
  .lead.small {
    font-size: 1.23047em;
  }
}

@media (max-width: 991px) {
  p,
  .p ul,
  .p ol {
    font-size: 1.0625em;
  }
  p.small,
  .p ul.small,
  .p ol.small {
    font-size: 0.92969em;
  }
}

@media (max-width: 767px) {
  p,
  .p ul,
  .p ol {
    font-size: 1.125em;
  }
  p.small,
  .p ul.small,
  .p ol.small {
    font-size: 0.98438em;
  }
}

hr {
  margin: 1.5em 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

strong {
  font-weight: 900;
}

small,
.small {
  font-size: 0.875em;
  font-weight: inherit;
}

@media (min-width: 768px) {
  a[href^="tel:"] {
    color: inherit !important;
    text-decoration: none !important;
    pointer-events: none !important;
  }
}

a[data-toggle],
a[data-target] {
  cursor: pointer;
}

a.arrow-left::before,
a.arrow-right::after,
a.arrow-up::before,
a.arrow-down::after {
  content: "";
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1em;
  vertical-align: -10%;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  font-smoothing: antialiased;
  color: inherit;
  text-decoration: none;
  transition: transform 0.125s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

a.arrow-right::after,
a.arrow-down::after {
  margin: 0 0 0 0.5em;
}

a.arrow-left::before,
a.arrow-up::before {
  margin: 0 0.5em 0 0;
}

a.arrow-right::after {
  content: "";
}

a.arrow-right:hover::after {
  transform: translateX(0.5em);
}

a.arrow-left::before {
  content: "\E5C4";
}

a.arrow-left:hover::before {
  transform: translateX(-0.5em);
}

a.arrow-up::before {
  content: "";
}

a.arrow-up:hover::before {
  transform: translateY(-0.5em);
}

a.arrow-down::after {
  content: "";
}

a.arrow-down:hover::after {
  transform: translateY(0.5em);
}

ol,
ul {
  margin-top: 1.5em;
  margin-bottom: 2.25em;
  padding-left: 2.25em;
}

ol:first-child,
ul:first-child {
  margin-top: 0;
}

h1 + ol,
h2 + ol,
h3 + ol,
h4 + ol, h1 +
ul,
h2 +
ul,
h3 +
ul,
h4 +
ul {
  margin-top: 1.5em;
}

ol li,
ul li {
  margin-bottom: 0.75em;
  padding-left: 0.75em;
}

.list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list-unstyled > li {
  padding-left: 0;
}

.list-condensed > li {
  line-height: 1.25;
  margin-bottom: 0.65625em;
}

.list-inline {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list-inline > li {
  padding-left: 0;
}

.list-inline-item {
  display: inline-block;
}

pre.dw-error {
  white-space: normal;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25em;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25em;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.75em;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: 0.65;
}

.container {
  width: 100%;
  padding-right: 2.5%;
  padding-left: 2.5%;
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  width: 100%;
  padding-right: 2.5%;
  padding-left: 2.5%;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.5%;
  margin-left: -2.5%;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-2point4, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2point4, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-2point4, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2point4, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2point4, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 0;
  padding-right: 2.5%;
  padding-left: 2.5%;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-2point4 {
  flex: 0 0 20%;
  max-width: 20%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.order-last {
  order: 13;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-2point4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .order-sm-last {
    order: 13;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-2point4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .order-md-last {
    order: 13;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-2point4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .order-lg-last {
    order: 13;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-2point4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .order-xl-last {
    order: 13;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1.5em;
  background-color: transparent;
}

.table th,
.table td {
  padding: 1.3125em 0.1875em;
  vertical-align: top;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.table tbody + tbody {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.75em 0.1875em;
}

.table-bordered {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.table-bordered th,
.table-bordered td {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #e9eff8;
}

.table-hover .table-primary:hover {
  background-color: #d6e1f2;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #d6e1f2;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #fceded;
}

.table-hover .table-secondary:hover {
  background-color: #f8d7d7;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #f8d7d7;
}

.table-tertiary,
.table-tertiary > th,
.table-tertiary > td {
  background-color: #ebebeb;
}

.table-hover .table-tertiary:hover {
  background-color: #dedede;
}

.table-hover .table-tertiary:hover > td,
.table-hover .table-tertiary:hover > th {
  background-color: #dedede;
}

.table-quaternary,
.table-quaternary > th,
.table-quaternary > td {
  background-color: #fafafa;
}

.table-hover .table-quaternary:hover {
  background-color: #ededed;
}

.table-hover .table-quaternary:hover > td,
.table-hover .table-quaternary:hover > th {
  background-color: #ededed;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #fdffff;
}

.table-hover .table-info:hover {
  background-color: #e4ffff;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #e4ffff;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #eaf7ef;
}

.table-hover .table-success:hover {
  background-color: #d8f0e1;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #d8f0e1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #feeceb;
}

.table-hover .table-danger:hover {
  background-color: #fdd5d3;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #fdd5d3;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fefefe;
}

.table-hover .table-light:hover {
  background-color: #f1f1f1;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #f1f1f1;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #ececec;
}

.table-hover .table-dark:hover {
  background-color: #dfdfdf;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #dfdfdf;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212121;
  border-color: #343434;
}

.table .thead-light th {
  color: #616161;
  background-color: #eee;
  border-color: rgba(0, 0, 0, 0.1);
}

.table-dark {
  color: #fff;
  background-color: #212121;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #343434;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm.table-bordered {
    border: 0;
  }
}

@media (max-width: 767px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md.table-bordered {
    border: 0;
  }
}

@media (max-width: 991px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg.table-bordered {
    border: 0;
  }
}

@media (max-width: 1199px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl.table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive.table-bordered {
  border: 0;
}

.table {
  font-size: 1em;
}

.table h1:only-child,
.table h2:only-child,
.table h3:only-child,
.table h4:only-child,
.table p:only-child,
.table ul:only-child {
  line-height: inherit;
}

.table h1:last-child,
.table h2:last-child,
.table h3:last-child,
.table h4:last-child,
.table p:last-child,
.table ul:last-child {
  margin-bottom: 0;
}

.table th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 900;
}

.table thead:last-child tr:last-child th,
.table thead:last-child tr:last-child td,
.table tbody:last-child tr:last-child th,
.table tbody:last-child tr:last-child td,
.table tfoot:last-child tr:last-child th,
.table tfoot:last-child tr:last-child td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375em 0.75em;
  font-size: 1em;
  line-height: 1.25;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25em;
  box-shadow: 0 0 0 transparent;
  transition: border-color 0.375s cubic-bezier(0.33, 0, 0.2, 1), box-shadow 0.375s cubic-bezier(0.33, 0, 0.2, 1);
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.1);
  outline: none;
  box-shadow: 0 0 0 transparent, none;
}

.form-control::placeholder {
  color: #757575;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #eee;
  opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2em + 2px);
}

select.form-control:focus::-ms-value {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
}

.col-form-label {
  padding-top: calc(0.375em + 1px);
  padding-bottom: calc(0.375em + 1px);
  margin-bottom: 0;
  line-height: 1.25;
}

.col-form-label-lg {
  padding-top: calc(0.375em + 1px);
  padding-bottom: calc(0.375em + 1px);
  font-size: 1.25em;
  line-height: 1.25;
}

.col-form-label-sm {
  padding-top: calc(0.375em + 1px);
  padding-bottom: calc(0.375em + 1px);
  font-size: 0.875em;
  line-height: 1.25;
}

.col-form-legend {
  padding-top: 0.375em;
  padding-bottom: 0.375em;
  margin-bottom: 0;
  font-size: 1em;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375em;
  padding-bottom: 0.375em;
  margin-bottom: 0;
  line-height: 1.25;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  padding: 0.375em 0.5em;
  font-size: 0.875em;
  line-height: 1.25;
  border-radius: 0.125em;
}

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.84375em + 2px);
}

.form-control-lg {
  padding: 0.375em 0.75em;
  font-size: 1.25em;
  line-height: 1.25;
  border-radius: 0.25em;
}

select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.3125em + 2px);
}

.form-group {
  margin-bottom: 1.5em;
}

.form-text {
  display: block;
  margin-top: 0.3em;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.75em;
}

.form-check.disabled .form-check-label {
  color: 0.65;
}

.form-check-label {
  padding-left: 1.5em;
  margin-bottom: 0;
}

.form-check-input {
  position: absolute;
  margin-top: 0.375em;
  margin-left: -1.5em;
}

.form-check-inline {
  display: inline-block;
  margin-right: 0.3em;
}

.form-check-inline .form-check-label {
  vertical-align: middle;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
  }
  .form-inline .form-check-label {
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.375em;
    margin-left: 0;
  }
  .form-inline .custom-control {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
  }
  .form-inline .custom-control-indicator {
    position: static;
    display: inline-block;
    margin-right: 0.375em;
    vertical-align: text-bottom;
  }
  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

input[type=text]::-ms-clear {
  display: none;
}

.form-legend {
  font-size: 1em;
}

.e-form-dw-labels-bold .form-legend {
  font-weight: 900;
}

.form-control-label {
  margin-bottom: 0.3em;
}

.e-form-dw-labels-bold .form-control-label {
  font-weight: 900;
}

.e-form-labels-hide .form-control-label {
  display: none;
}

.form-control.is-invalid {
  border-color: #f44336;
}

.form-check-label {
  cursor: pointer;
}

.is-invalid .form-check-label {
  color: #f44336;
}

.form-text.is-invalid {
  color: #f44336;
}

.invalid-feedback {
  display: none;
}

.is-invalid .invalid-feedback {
  display: block;
}

.btn {
  font-family: inherit;
  cursor: pointer;
  display: inline-block;
  font-weight: 900;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 2px solid transparent;
  text-decoration: none;
  padding: 1em 2.5em;
  font-size: 0.875em;
  line-height: 1.25;
  border-radius: 10em;
  transition: none;
}

.btn:focus, .btn:hover {
  text-decoration: none;
  transition: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: none;
}

.btn.disabled, .btn:disabled {
  opacity: .65;
  box-shadow: none;
}

.btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active {
  background-image: none;
  box-shadow: none, none;
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

.btn-primary {
  color: white;
  background-color: #212121;
  border-color: #212121;
  box-shadow: none;
}

.btn-primary:hover {
  color: white;
  background-color: #212121;
  border-color: #212121;
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #212121;
  border-color: #212121;
}

.btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: white;
  background-color: #212121;
  border-color: #212121;
}

.btn-secondary {
  color: #212121;
  background-color: #fff;
  border-color: #212121;
  box-shadow: none;
}

.btn-secondary:hover {
  color: #212121;
  background-color: #fff;
  border-color: #212121;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #fff;
  border-color: #212121;
}

.btn-secondary:not([disabled]):not(.disabled):active, .btn-secondary:not([disabled]):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #212121;
  background-color: #fff;
  border-color: #212121;
}

.btn-tertiary {
  color: rgba(0, 0, 0, 0.65);
  background-color: #F0F5F3;
  border-color: #F0F5F3;
  box-shadow: none;
}

.btn-tertiary:hover {
  color: rgba(0, 0, 0, 0.85);
  background-color: #F0F5F3;
  border-color: #F0F5F3;
}

.btn-tertiary.disabled, .btn-tertiary:disabled {
  background-color: #F0F5F3;
  border-color: #F0F5F3;
}

.btn-tertiary:not([disabled]):not(.disabled):active, .btn-tertiary:not([disabled]):not(.disabled).active,
.show > .btn-tertiary.dropdown-toggle {
  color: rgba(0, 0, 0, 0.65);
  background-color: #F0F5F3;
  border-color: #F0F5F3;
}

.btn-success {
  color: white;
  background-color: #1F63B5;
  border-color: #1F63B5;
  box-shadow: none;
}

.btn-success:hover {
  color: white;
  background-color: #1F63B5;
  border-color: #1F63B5;
}

.btn-success.disabled, .btn-success:disabled {
  background-color: #1F63B5;
  border-color: #1F63B5;
}

.btn-success:not([disabled]):not(.disabled):active, .btn-success:not([disabled]):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: white;
  background-color: #1F63B5;
  border-color: #1F63B5;
}

.btn-tertiary {
  text-transform: uppercase;
  letter-spacing: 0.0625em;
  font-weight: 400;
}

.btn-link {
  font-weight: inherit;
  text-transform: uppercase;
  color: #2d9ee0;
  background-color: transparent;
}

.btn-link:hover {
  color: #2d9ee0;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus, .btn-link.focus {
  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #757575;
}

.btn-lg {
  padding: 0.875em 2.5em;
  font-size: 1.125em;
  line-height: 1.25;
  border-radius: 10em;
}

.btn-sm {
  padding: 0.5em 1em;
  font-size: 0.75em;
  line-height: 1.25;
  border-radius: 10em;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 1.125em;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  transition: opacity 0.375s cubic-bezier(0.33, 0, 0.2, 1);
}

.fade.show {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.show {
  display: block;
}

tr.collapse.show {
  display: table-row;
}

tbody.collapse.show {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.5s cubic-bezier(0.58, 0, 0.29, 0.845);
}

@keyframes zoom {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1.25, 1.25);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0%);
  }
  5% {
    transform: translateY(20%);
  }
  10% {
    transform: translateY(0%);
  }
  15% {
    transform: translateY(20%);
  }
  20% {
    transform: translateY(0%);
  }
}

.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.31875em;
  vertical-align: 0.31875em;
  content: "";
  border-top: 0.375em solid;
  border-right: 0.375em solid transparent;
  border-bottom: 0;
  border-left: 0.375em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10em;
  padding: 1em 0;
  margin: 0 0 0;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.65);
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25em;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.31875em;
  vertical-align: 0.31875em;
  content: "";
  border-top: 0;
  border-right: 0.375em solid transparent;
  border-bottom: 0.375em solid;
  border-left: 0.375em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.75em 0;
  overflow: hidden;
  border-top: 1px solid #eee;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5em 1.5em;
  clear: both;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: rgba(0, 0, 0, 0.65);
  text-decoration: none;
  background-color: #F0F5F3;
}

.dropdown-item.active, .dropdown-item:active {
  color: rgba(0, 0, 0, 0.65);
  text-decoration: none;
  background-color: #F0F5F3;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #9e9e9e;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 1em 1.5em;
  margin-bottom: 0;
  font-size: 0.875em;
  color: rgba(0, 0, 0, 0.85);
  white-space: nowrap;
}

.dropdown-toggle::before, .dropdown-toggle::after {
  display: none;
}

.dropdown-toggle .e-nav-pageicon-dropdown {
  margin: 0 0.125em;
  transform: rotate(0deg) translate(0, 0.025em);
  transition: transform 0.5s cubic-bezier(0.58, 0, 0.29, 0.845);
}

.dropdown.show .dropdown-toggle::before, .dropdown.show .dropdown-toggle::after {
  display: none;
}

.dropdown.show .dropdown-toggle .e-nav-pageicon-dropdown {
  transform: rotate(-540deg) translate(0, 0);
}

.dropdown-menu {
  font-size: 0.875em;
  width: 15em;
  color: inherit;
}

.dropdown-item {
  white-space: normal;
  line-height: 1.25;
}

.dropdown-header {
  padding-top: 1.125em;
  font-size: 1em;
  white-space: normal;
  border-bottom: 1px solid #eee;
}

.custom-control {
  position: relative;
  display: inline-flex;
  min-height: 1.5rem;
  padding-left: 1.875em;
  margin-right: 0;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-indicator {
  color: #fff;
  background-color: #1F63B5;
  box-shadow: none;
}

.custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: none;
}

.custom-control-input:active ~ .custom-control-indicator {
  color: #fff;
  background-color: #99bfee;
  box-shadow: none;
}

.custom-control-input:disabled ~ .custom-control-indicator {
  background-color: #f5f5f5;
}

.custom-control-input:disabled ~ .custom-control-description {
  color: #757575;
}

.custom-control-indicator {
  position: absolute;
  top: 0.25em;
  left: 0;
  display: block;
  width: 1em;
  height: 1em;
  pointer-events: none;
  user-select: none;
  background-color: #e0e0e0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 90% 90%;
  box-shadow: none;
}

.custom-checkbox .custom-control-indicator {
  border-radius: 0.25em;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg fill='%23fff' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-color: #e0e0e0;
  background-image: none;
  box-shadow: none;
}

.custom-radio .custom-control-indicator {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg fill='%23fff' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z'/%3E%3C/svg%3E");
}

.custom-controls-stacked {
  display: flex;
  flex-direction: column;
}

.custom-controls-stacked .custom-control {
  margin-bottom: 0.375em;
}

.custom-controls-stacked .custom-control + .custom-control {
  margin-left: 0;
}

.custom-select {
  display: inline-block;
  max-width: 100%;
  height: calc(2em + 2px);
  padding: 0.375em 1.75em 0.375em 0.75em;
  line-height: 1.25;
  color: rgba(0, 0, 0, 0.65);
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23424242' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75em center;
  background-size: 8px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25em;
  appearance: none;
}

.custom-select:focus {
  border-color: #6da4e6;
  outline: none;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(109, 164, 230, 0.5);
}

.custom-select:focus::-ms-value {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
}

.custom-select[multiple] {
  height: auto;
  background-image: none;
}

.custom-select:disabled {
  color: #757575;
  background-color: #eee;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.84375em + 2px);
  padding-top: 0.375em;
  padding-bottom: 0.375em;
  font-size: 0.875em;
}

.custom-file {
  position: relative;
  display: inline-block;
  max-width: 100%;
  height: calc(2em + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  min-width: 14em;
  max-width: 100%;
  height: calc(2em + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-control {
  box-shadow: 0 0 0 0.075em #fff, 0 0 0 0.2em #1F63B5;
}

.custom-file-control {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: calc(2em + 2px);
  padding: 0.375em 0.75em;
  line-height: 1.25;
  color: rgba(0, 0, 0, 0.65);
  pointer-events: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25em;
  box-shadow: 0 0 0 transparent;
}

.custom-file-control:lang(en):empty::after {
  content: "Choose file...";
}

.custom-file-control::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  z-index: 6;
  display: block;
  height: calc(2em + 2px);
  padding: 0.375em 0.75em;
  line-height: 1.25;
  color: rgba(0, 0, 0, 0.65);
  background-color: #eee;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0 0.25em 0.25em 0;
}

.custom-file-control:lang(en)::before {
  content: "Browse";
}

.custom-control {
  min-height: 1.25em;
  line-height: 1.25;
  display: flex;
  align-items: center;
}

.custom-control-indicator {
  top: 0.125em;
  transition: background-color 0.375s cubic-bezier(0.33, 0, 0.2, 1);
}

.custom-checkbox {
  cursor: pointer;
}

.custom-radio {
  cursor: pointer;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5em 1em;
}

.nav-link:focus, .nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  color: #757575;
}

.nav-tabs {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25em;
  border-top-right-radius: 0.25em;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #eee #eee rgba(0, 0, 0, 0.1);
}

.nav-tabs .nav-link.disabled {
  color: #757575;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #616161;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25em;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1F63B5;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.nav {
  margin: 0;
}

.nav-item {
  margin: 0;
  padding: 0;
}

.nav-item:last-child .dropdown-menu {
  left: auto;
  right: 0;
}

.nav-link {
  cursor: pointer;
  text-decoration: none;
}

.nav-pills {
  margin-bottom: 1.5em;
}

.nav-pills .nav-item {
  margin: 0 0.5em 0.5em 0;
}

.nav-pills .nav-item + .nav-item {
  margin-left: 0;
}

.nav-pills .nav-link {
  color: #1F63B5;
  border: 1px solid #1F63B5;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25em;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25em;
  border-top-right-radius: 0.25em;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.5em;
}

.card-title {
  margin-bottom: 1.125em;
}

.card-subtitle {
  margin-top: -0.5625em;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.5em;
}

.card-header {
  padding: 1.125em 1.5em;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.card-header:first-child {
  border-radius: calc(0.25em - 1px) calc(0.25em - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 1.125em 1.5em;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25em - 1px) calc(0.25em - 1px);
}

.card-header-tabs {
  margin-right: -0.75em;
  margin-bottom: -1.125em;
  margin-left: -0.75em;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.75em;
  margin-left: -0.75em;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25em;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25em - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25em - 1px);
  border-top-right-radius: calc(0.25em - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25em - 1px);
  border-bottom-left-radius: calc(0.25em - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 2.5%;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -2.5%;
    margin-left: -2.5%;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 2.5%;
    margin-bottom: 0;
    margin-left: 2.5%;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group .card {
  margin-bottom: 2.5%;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group .card:first-child .card-img-top,
  .card-group .card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group .card:first-child .card-img-bottom,
  .card-group .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group .card:last-child .card-img-top,
  .card-group .card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group .card:last-child .card-img-bottom,
  .card-group .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group .card:only-child {
    border-radius: 0.25em;
  }
  .card-group .card:only-child .card-img-top,
  .card-group .card:only-child .card-header {
    border-top-left-radius: 0.25em;
    border-top-right-radius: 0.25em;
  }
  .card-group .card:only-child .card-img-bottom,
  .card-group .card:only-child .card-footer {
    border-bottom-right-radius: 0.25em;
    border-bottom-left-radius: 0.25em;
  }
  .card-group .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 1.125em;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25em;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.card {
  margin-bottom: 0;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.e-breadcrumb {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.e-breadcrumb-light-shadow {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, transparent 95%);
}

.e-breadcrumb-light-shadow::after {
  content: "";
  display: block;
  height: 2.25em;
  pointer-events: none;
}

.breadcrumb {
  font-size: 0.75em;
  padding: 1.5em 0;
}

.breadcrumb-back-link + .breadcrumb-prefix-text::before {
  content: "\007C";
  padding: 0.375em 0.75em;
}

.breadcrumb-item {
  color: rgba(0, 0, 0, 0.65);
}

.breadcrumb-item + .breadcrumb-item::before {
  color: inherit;
  content: "›";
}

.breadcrumb-item a {
  padding: 0.375em 0.75em;
  color: inherit;
  text-decoration: underline;
}

.breadcrumb-item a:hover {
  text-decoration: none;
}

.breadcrumb-item:last-child {
  color: rgba(0, 0, 0, 0.85);
}

.breadcrumb-item:last-child a {
  text-decoration: none;
}

.e-breadcrumb-light .breadcrumb-item,
.e-breadcrumb-light-shadow .breadcrumb-item {
  color: rgba(255, 255, 255, 0.75);
}

.e-breadcrumb-light .breadcrumb-item:last-child,
.e-breadcrumb-light-shadow .breadcrumb-item:last-child {
  color: white;
}

.e-breadcrumb-dark .breadcrumb-item {
  color: rgba(0, 0, 0, 0.65);
}

.e-breadcrumb-dark .breadcrumb-item:last-child {
  color: rgba(0, 0, 0, 0.85);
}

.pagination {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  border-radius: 0.25em;
}

.pagination > li {
  padding-left: 0;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5em 0.75em;
  margin-left: -1px;
  line-height: 1.25;
  color: #2d9ee0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.page-link:focus, .page-link:hover {
  color: #2d9ee0;
  text-decoration: none;
  background-color: #eee;
  border-color: rgba(0, 0, 0, 0.1);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #2d9ee0;
  border-color: #2d9ee0;
}

.page-item.disabled .page-link {
  color: #757575;
  pointer-events: none;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.1);
}

.pagination-lg .page-link {
  padding: 0.75em 1.5em;
  font-size: 1.25em;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}

.pagination-sm .page-link {
  padding: 0.25em 0.5em;
  font-size: 0.875em;
  line-height: 1.25;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.125em;
  border-bottom-left-radius: 0.125em;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.125em;
  border-bottom-right-radius: 0.125em;
}

.page-link:hover {
  text-decoration: none;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.375em;
  font-size: 0.75em;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.25em;
  padding-left: 0.25em;
  border-radius: 10em;
}

.badge-primary {
  color: white;
  background-color: #1F63B5;
}

.badge-primary[href]:focus, .badge-primary[href]:hover {
  color: white;
  text-decoration: none;
  background-color: #184b89;
}

.badge-secondary {
  color: white;
  background-color: #E24747;
}

.badge-secondary[href]:focus, .badge-secondary[href]:hover {
  color: white;
  text-decoration: none;
  background-color: #d52121;
}

.badge-tertiary {
  color: white;
  background-color: #333333;
}

.badge-tertiary[href]:focus, .badge-tertiary[href]:hover {
  color: white;
  text-decoration: none;
  background-color: #1a1a1a;
}

.badge-quaternary {
  color: rgba(0, 0, 0, 0.85);
  background-color: #C9D1CE;
}

.badge-quaternary[href]:focus, .badge-quaternary[href]:hover {
  color: rgba(0, 0, 0, 0.85);
  text-decoration: none;
  background-color: #adbab5;
}

.badge-info {
  color: rgba(0, 0, 0, 0.85);
  background-color: #ebfbff;
}

.badge-info[href]:focus, .badge-info[href]:hover {
  color: rgba(0, 0, 0, 0.85);
  text-decoration: none;
  background-color: #b8f1ff;
}

.badge-success {
  color: white;
  background-color: #28af5f;
}

.badge-success[href]:focus, .badge-success[href]:hover {
  color: white;
  text-decoration: none;
  background-color: #1f8548;
}

.badge-danger {
  color: white;
  background-color: #f44336;
}

.badge-danger[href]:focus, .badge-danger[href]:hover {
  color: white;
  text-decoration: none;
  background-color: #ea1c0d;
}

.badge-light {
  color: rgba(0, 0, 0, 0.85);
  background-color: #F0F5F3;
}

.badge-light[href]:focus, .badge-light[href]:hover {
  color: rgba(0, 0, 0, 0.85);
  text-decoration: none;
  background-color: #d1e1db;
}

.badge-dark {
  color: white;
  background-color: #424242;
}

.badge-dark[href]:focus, .badge-dark[href]:hover {
  color: white;
  text-decoration: none;
  background-color: #292929;
}

.badge {
  transition: opacity 0.375s cubic-bezier(0.33, 0, 0.2, 1);
}

.badge:empty {
  opacity: 0;
  display: inline-block;
}

.badge-pill {
  min-width: 1.5em;
}

.alert {
  position: relative;
  padding: 0.75em 1.25em;
  margin-bottom: 1em;
  border: 1px solid transparent;
  border-radius: 0.25em;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 900;
}

.alert-dismissible {
  padding-right: 4em;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75em 1.25em;
  color: inherit;
}

.alert-primary {
  color: #0c2848;
  background-color: white;
  border-color: #e9eff8;
}

.alert-primary hr {
  border-top-color: #d6e1f2;
}

.alert-primary .alert-link {
  color: #05101c;
}

.alert-secondary {
  color: #5a1c1c;
  background-color: white;
  border-color: #fceded;
}

.alert-secondary hr {
  border-top-color: #f8d7d7;
}

.alert-secondary .alert-link {
  color: #331010;
}

.alert-tertiary {
  color: #141414;
  background-color: white;
  border-color: #ebebeb;
}

.alert-tertiary hr {
  border-top-color: #dedede;
}

.alert-tertiary .alert-link {
  color: black;
}

.alert-quaternary {
  color: #505452;
  background-color: white;
  border-color: #fafafa;
}

.alert-quaternary hr {
  border-top-color: #ededed;
}

.alert-quaternary .alert-link {
  color: #373a39;
}

.alert-info {
  color: #5e6466;
  background-color: white;
  border-color: #fdffff;
}

.alert-info hr {
  border-top-color: #e4ffff;
}

.alert-info .alert-link {
  color: #464a4b;
}

.alert-success {
  color: #104626;
  background-color: white;
  border-color: #eaf7ef;
}

.alert-success hr {
  border-top-color: #d8f0e1;
}

.alert-success .alert-link {
  color: #071c0f;
}

.alert-danger {
  color: #621b16;
  background-color: white;
  border-color: #feeceb;
}

.alert-danger hr {
  border-top-color: #fdd5d3;
}

.alert-danger .alert-link {
  color: #38100d;
}

.alert-light {
  color: #606261;
  background-color: white;
  border-color: #fefefe;
}

.alert-light hr {
  border-top-color: #f1f1f1;
}

.alert-light .alert-link {
  color: #474848;
}

.alert-dark {
  color: #1a1a1a;
  background-color: white;
  border-color: #ececec;
}

.alert-dark hr {
  border-top-color: #dfdfdf;
}

.alert-dark .alert-link {
  color: #010101;
}

.alert-danger {
  background-color: rgba(244, 67, 54, 0.2);
  color: #f44336;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #616161;
  text-align: inherit;
}

.list-group-item-action:focus, .list-group-item-action:hover {
  color: #616161;
  text-decoration: none;
  background-color: #f5f5f5;
}

.list-group-item-action:active {
  color: rgba(0, 0, 0, 0.65);
  background-color: #eee;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75em 1.25em;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25em;
  border-top-right-radius: 0.25em;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
}

.list-group-item:focus, .list-group-item:hover {
  text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #757575;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 1;
  color: #fff;
  background-color: #1F63B5;
  border-color: #1F63B5;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #0c2848;
  background-color: #e9eff8;
}

a.list-group-item-primary,
button.list-group-item-primary {
  color: #0c2848;
}

a.list-group-item-primary:focus, a.list-group-item-primary:hover,
button.list-group-item-primary:focus,
button.list-group-item-primary:hover {
  color: #0c2848;
  background-color: #d6e1f2;
}

a.list-group-item-primary.active,
button.list-group-item-primary.active {
  color: #fff;
  background-color: #0c2848;
  border-color: #0c2848;
}

.list-group-item-secondary {
  color: #5a1c1c;
  background-color: #fceded;
}

a.list-group-item-secondary,
button.list-group-item-secondary {
  color: #5a1c1c;
}

a.list-group-item-secondary:focus, a.list-group-item-secondary:hover,
button.list-group-item-secondary:focus,
button.list-group-item-secondary:hover {
  color: #5a1c1c;
  background-color: #f8d7d7;
}

a.list-group-item-secondary.active,
button.list-group-item-secondary.active {
  color: #fff;
  background-color: #5a1c1c;
  border-color: #5a1c1c;
}

.list-group-item-tertiary {
  color: #141414;
  background-color: #ebebeb;
}

a.list-group-item-tertiary,
button.list-group-item-tertiary {
  color: #141414;
}

a.list-group-item-tertiary:focus, a.list-group-item-tertiary:hover,
button.list-group-item-tertiary:focus,
button.list-group-item-tertiary:hover {
  color: #141414;
  background-color: #dedede;
}

a.list-group-item-tertiary.active,
button.list-group-item-tertiary.active {
  color: #fff;
  background-color: #141414;
  border-color: #141414;
}

.list-group-item-quaternary {
  color: #505452;
  background-color: #fafafa;
}

a.list-group-item-quaternary,
button.list-group-item-quaternary {
  color: #505452;
}

a.list-group-item-quaternary:focus, a.list-group-item-quaternary:hover,
button.list-group-item-quaternary:focus,
button.list-group-item-quaternary:hover {
  color: #505452;
  background-color: #ededed;
}

a.list-group-item-quaternary.active,
button.list-group-item-quaternary.active {
  color: #fff;
  background-color: #505452;
  border-color: #505452;
}

.list-group-item-info {
  color: #5e6466;
  background-color: #fdffff;
}

a.list-group-item-info,
button.list-group-item-info {
  color: #5e6466;
}

a.list-group-item-info:focus, a.list-group-item-info:hover,
button.list-group-item-info:focus,
button.list-group-item-info:hover {
  color: #5e6466;
  background-color: #e4ffff;
}

a.list-group-item-info.active,
button.list-group-item-info.active {
  color: #fff;
  background-color: #5e6466;
  border-color: #5e6466;
}

.list-group-item-success {
  color: #104626;
  background-color: #eaf7ef;
}

a.list-group-item-success,
button.list-group-item-success {
  color: #104626;
}

a.list-group-item-success:focus, a.list-group-item-success:hover,
button.list-group-item-success:focus,
button.list-group-item-success:hover {
  color: #104626;
  background-color: #d8f0e1;
}

a.list-group-item-success.active,
button.list-group-item-success.active {
  color: #fff;
  background-color: #104626;
  border-color: #104626;
}

.list-group-item-danger {
  color: #621b16;
  background-color: #feeceb;
}

a.list-group-item-danger,
button.list-group-item-danger {
  color: #621b16;
}

a.list-group-item-danger:focus, a.list-group-item-danger:hover,
button.list-group-item-danger:focus,
button.list-group-item-danger:hover {
  color: #621b16;
  background-color: #fdd5d3;
}

a.list-group-item-danger.active,
button.list-group-item-danger.active {
  color: #fff;
  background-color: #621b16;
  border-color: #621b16;
}

.list-group-item-light {
  color: #606261;
  background-color: #fefefe;
}

a.list-group-item-light,
button.list-group-item-light {
  color: #606261;
}

a.list-group-item-light:focus, a.list-group-item-light:hover,
button.list-group-item-light:focus,
button.list-group-item-light:hover {
  color: #606261;
  background-color: #f1f1f1;
}

a.list-group-item-light.active,
button.list-group-item-light.active {
  color: #fff;
  background-color: #606261;
  border-color: #606261;
}

.list-group-item-dark {
  color: #1a1a1a;
  background-color: #ececec;
}

a.list-group-item-dark,
button.list-group-item-dark {
  color: #1a1a1a;
}

a.list-group-item-dark:focus, a.list-group-item-dark:hover,
button.list-group-item-dark:focus,
button.list-group-item-dark:hover {
  color: #1a1a1a;
  background-color: #dfdfdf;
}

a.list-group-item-dark.active,
button.list-group-item-dark.active {
  color: #fff;
  background-color: #1a1a1a;
  border-color: #1a1a1a;
}

.close {
  float: right;
  font-size: 1.5em;
  font-weight: 900;
  line-height: 1;
  color: inherit;
  text-shadow: none;
  opacity: .5;
}

.close:focus, .close:hover {
  color: inherit;
  text-decoration: none;
  opacity: .75;
}

button.close {
  padding: 0;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

button.close {
  opacity: 1;
  cursor: pointer;
}

button.close:hover {
  opacity: inherit;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.75em;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: translate(0, -25%);
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25em;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.65;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.5em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top-left-radius: 0.25em;
  border-top-right-radius: 0.25em;
}

.modal-header .close {
  padding: 1.5em;
  margin: -1.5em -1.5em -1.5em auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.25;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.5em;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.5em;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 50%;
    margin: 5vmax auto;
  }
  .modal-content {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  }
  .modal-sm {
    max-width: 25%;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 75%;
  }
}

.modal-open .modal-fullscreen {
  overflow-y: scroll;
  padding-right: 0 !important;
}

.modal-fullscreen .modal-dialog {
  max-width: 100%;
  margin: 0;
  transition: none;
}

.modal-fullscreen .modal-content {
  min-height: 100vh;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}

.modal-fullscreen .modal-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
  padding: 0 4vw;
  align-items: center;
}

.modal-fullscreen .modal-header .close {
  align-self: stretch;
  padding: 0 4vw;
  margin: 0 -4vw 0 0;
}

.modal-fullscreen .modal-title {
  padding: 1.125em 0;
}

.modal-fullscreen .modal-body {
  padding: 1.5em 4vw;
}

.modal-fullscreen .modal-body .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 1.5em;
}

.modal-fullscreen .modal-footer {
  padding: 1.5em 4vw;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  backface-visibility: hidden;
  perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6em;
  color: #eee;
  text-align: center;
  opacity: 0.875;
}

.carousel-control-prev:focus, .carousel-control-prev:hover,
.carousel-control-next:focus,
.carousel-control-next:hover {
  color: #eee;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23eee' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23eee' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 6em;
  margin-left: 6em;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 1em;
  height: 1em;
  margin-right: 0.25em;
  margin-left: 0.25em;
  text-indent: -999px;
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators .active {
  background-color: rgba(255, 255, 255, 0.875);
}

.carousel-caption {
  position: absolute;
  right: 5%;
  bottom: 20px;
  left: 5%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.carousel {
  height: 50vw;
}

.carousel-inner {
  height: 100%;
}

.carousel-item {
  height: 100%;
}

.carousel-control-prev,
.carousel-control-next {
  cursor: pointer;
  width: 6em;
  background: no-repeat center center;
  background-size: 4em 4em;
  overflow: hidden;
}

.carousel:not(.image-gallery):hover .carousel-control-prev, .carousel:not(.image-gallery):hover
.carousel-control-next {
  opacity: 1;
}

.carousel-control-prev {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23eee' d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'/%3E%3C/svg%3E");
}

.carousel-control-next {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23eee' d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  bottom: 0.75em;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
}

.carousel-indicators li {
  font-size: 0.75em;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.875);
  border: 1px solid transparent;
  margin-bottom: 0;
  padding: 0;
  background-clip: content-box;
  transition: border-color 0.25s, background-color 0.25s;
}

.carousel-indicators .active {
  background: none;
  border-color: rgba(255, 255, 255, 0.875);
}

.carousel-caption {
  padding-right: 1.5vw;
  padding-left: 1.5vw;
  right: 0;
  bottom: 1.5em;
  left: 0;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  text-align: left;
}

@media (min-width: 1200px) {
  .carousel-caption {
    padding-right: 3vw;
    padding-left: 3vw;
  }
}

.active .carousel-caption {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.active.carousel-item-left .carousel-caption {
  opacity: 0.5;
  transition: opacity 0.375s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.active.carousel-item-right .carousel-caption {
  opacity: 0.5;
  transition: opacity 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.carousel-item-next.carousel-item-left .carousel-caption {
  transform: translateY(3em);
  opacity: 0;
}

.carousel-item-prev.carousel-item-right .carousel-caption {
  opacity: 0;
  transform: translateY(3em);
}

.carousel-caption h1,
.carousel-caption h2,
.carousel-caption h3,
.carousel-caption h4,
.carousel-caption p {
  color: white;
  line-height: 1.25;
  margin-bottom: 0.5rem;
}

.carousel-caption h4 {
  color: rgba(255, 255, 255, 0.75);
  margin-bottom: 0.25rem;
}

.carousel-caption p {
  color: rgba(255, 255, 255, 0.75);
}

.carousel-caption .btn {
  margin-top: 1rem;
  pointer-events: auto;
}

.collapse {
  will-change: height;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #1F63B5 !important;
}

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #184b89 !important;
}

.bg-secondary {
  background-color: #E24747 !important;
}

a.bg-secondary:focus, a.bg-secondary:hover {
  background-color: #d52121 !important;
}

.bg-tertiary {
  background-color: #333333 !important;
}

a.bg-tertiary:focus, a.bg-tertiary:hover {
  background-color: #1a1a1a !important;
}

.bg-quaternary {
  background-color: #C9D1CE !important;
}

a.bg-quaternary:focus, a.bg-quaternary:hover {
  background-color: #adbab5 !important;
}

.bg-info {
  background-color: #ebfbff !important;
}

a.bg-info:focus, a.bg-info:hover {
  background-color: #b8f1ff !important;
}

.bg-success {
  background-color: #28af5f !important;
}

a.bg-success:focus, a.bg-success:hover {
  background-color: #1f8548 !important;
}

.bg-danger {
  background-color: #f44336 !important;
}

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #ea1c0d !important;
}

.bg-light {
  background-color: #F0F5F3 !important;
}

a.bg-light:focus, a.bg-light:hover {
  background-color: #d1e1db !important;
}

.bg-dark {
  background-color: #424242 !important;
}

a.bg-dark:focus, a.bg-dark:hover {
  background-color: #292929 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #1F63B5 !important;
}

.border-secondary {
  border-color: #E24747 !important;
}

.border-tertiary {
  border-color: #333333 !important;
}

.border-quaternary {
  border-color: #C9D1CE !important;
}

.border-info {
  border-color: #ebfbff !important;
}

.border-success {
  border-color: #28af5f !important;
}

.border-danger {
  border-color: #f44336 !important;
}

.border-light {
  border-color: #F0F5F3 !important;
}

.border-dark {
  border-color: #424242 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded, .p-img-corner-rounded {
  border-radius: 0.25em !important;
}

.rounded-top {
  border-top-left-radius: 0.25em !important;
  border-top-right-radius: 0.25em !important;
}

.rounded-right {
  border-top-right-radius: 0.25em !important;
  border-bottom-right-radius: 0.25em !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25em !important;
  border-bottom-left-radius: 0.25em !important;
}

.rounded-left {
  border-top-left-radius: 0.25em !important;
  border-bottom-left-radius: 0.25em !important;
}

.rounded-circle, .p-img-circle {
  border-radius: 50% !important;
}

.rounded-0, .p-img-corner-sharp {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

.d-print-block {
  display: none !important;
}

@media print {
  .d-print-block {
    display: block !important;
  }
}

.d-print-inline {
  display: none !important;
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
}

.d-print-inline-block {
  display: none !important;
}

@media print {
  .d-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.75em !important;
}

.mt-1,
.my-1 {
  margin-top: 0.75em !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.75em !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.75em !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.75em !important;
}

.m-2 {
  margin: 1.5em !important;
}

.mt-2,
.my-2 {
  margin-top: 1.5em !important;
}

.mr-2,
.mx-2 {
  margin-right: 1.5em !important;
}

.mb-2,
.my-2 {
  margin-bottom: 1.5em !important;
}

.ml-2,
.mx-2 {
  margin-left: 1.5em !important;
}

.m-3 {
  margin: 2.25em !important;
}

.mt-3,
.my-3 {
  margin-top: 2.25em !important;
}

.mr-3,
.mx-3 {
  margin-right: 2.25em !important;
}

.mb-3,
.my-3 {
  margin-bottom: 2.25em !important;
}

.ml-3,
.mx-3 {
  margin-left: 2.25em !important;
}

.m-4 {
  margin: 3em !important;
}

.mt-4,
.my-4 {
  margin-top: 3em !important;
}

.mr-4,
.mx-4 {
  margin-right: 3em !important;
}

.mb-4,
.my-4 {
  margin-bottom: 3em !important;
}

.ml-4,
.mx-4 {
  margin-left: 3em !important;
}

.m-5 {
  margin: 3.75em !important;
}

.mt-5,
.my-5 {
  margin-top: 3.75em !important;
}

.mr-5,
.mx-5 {
  margin-right: 3.75em !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3.75em !important;
}

.ml-5,
.mx-5 {
  margin-left: 3.75em !important;
}

.m-6 {
  margin: 4.5em !important;
}

.mt-6,
.my-6 {
  margin-top: 4.5em !important;
}

.mr-6,
.mx-6 {
  margin-right: 4.5em !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4.5em !important;
}

.ml-6,
.mx-6 {
  margin-left: 4.5em !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.75em !important;
}

.pt-1,
.py-1 {
  padding-top: 0.75em !important;
}

.pr-1,
.px-1 {
  padding-right: 0.75em !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.75em !important;
}

.pl-1,
.px-1 {
  padding-left: 0.75em !important;
}

.p-2 {
  padding: 1.5em !important;
}

.pt-2,
.py-2 {
  padding-top: 1.5em !important;
}

.pr-2,
.px-2 {
  padding-right: 1.5em !important;
}

.pb-2,
.py-2 {
  padding-bottom: 1.5em !important;
}

.pl-2,
.px-2 {
  padding-left: 1.5em !important;
}

.p-3 {
  padding: 2.25em !important;
}

.pt-3,
.py-3 {
  padding-top: 2.25em !important;
}

.pr-3,
.px-3 {
  padding-right: 2.25em !important;
}

.pb-3,
.py-3 {
  padding-bottom: 2.25em !important;
}

.pl-3,
.px-3 {
  padding-left: 2.25em !important;
}

.p-4 {
  padding: 3em !important;
}

.pt-4,
.py-4 {
  padding-top: 3em !important;
}

.pr-4,
.px-4 {
  padding-right: 3em !important;
}

.pb-4,
.py-4 {
  padding-bottom: 3em !important;
}

.pl-4,
.px-4 {
  padding-left: 3em !important;
}

.p-5 {
  padding: 3.75em !important;
}

.pt-5,
.py-5 {
  padding-top: 3.75em !important;
}

.pr-5,
.px-5 {
  padding-right: 3.75em !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3.75em !important;
}

.pl-5,
.px-5 {
  padding-left: 3.75em !important;
}

.p-6 {
  padding: 4.5em !important;
}

.pt-6,
.py-6 {
  padding-top: 4.5em !important;
}

.pr-6,
.px-6 {
  padding-right: 4.5em !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4.5em !important;
}

.pl-6,
.px-6 {
  padding-left: 4.5em !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.75em !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.75em !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.75em !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.75em !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.75em !important;
  }
  .m-sm-2 {
    margin: 1.5em !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1.5em !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1.5em !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1.5em !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1.5em !important;
  }
  .m-sm-3 {
    margin: 2.25em !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 2.25em !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 2.25em !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 2.25em !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 2.25em !important;
  }
  .m-sm-4 {
    margin: 3em !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 3em !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 3em !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 3em !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 3em !important;
  }
  .m-sm-5 {
    margin: 3.75em !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3.75em !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3.75em !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3.75em !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3.75em !important;
  }
  .m-sm-6 {
    margin: 4.5em !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4.5em !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4.5em !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4.5em !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4.5em !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.75em !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.75em !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.75em !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.75em !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.75em !important;
  }
  .p-sm-2 {
    padding: 1.5em !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1.5em !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1.5em !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1.5em !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1.5em !important;
  }
  .p-sm-3 {
    padding: 2.25em !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 2.25em !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 2.25em !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 2.25em !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 2.25em !important;
  }
  .p-sm-4 {
    padding: 3em !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 3em !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 3em !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 3em !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 3em !important;
  }
  .p-sm-5 {
    padding: 3.75em !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3.75em !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3.75em !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3.75em !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3.75em !important;
  }
  .p-sm-6 {
    padding: 4.5em !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4.5em !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4.5em !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4.5em !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4.5em !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.75em !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.75em !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.75em !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.75em !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.75em !important;
  }
  .m-md-2 {
    margin: 1.5em !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1.5em !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1.5em !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1.5em !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1.5em !important;
  }
  .m-md-3 {
    margin: 2.25em !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 2.25em !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 2.25em !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 2.25em !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 2.25em !important;
  }
  .m-md-4 {
    margin: 3em !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 3em !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 3em !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 3em !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 3em !important;
  }
  .m-md-5 {
    margin: 3.75em !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3.75em !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3.75em !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3.75em !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3.75em !important;
  }
  .m-md-6 {
    margin: 4.5em !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4.5em !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4.5em !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4.5em !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4.5em !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.75em !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.75em !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.75em !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.75em !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.75em !important;
  }
  .p-md-2 {
    padding: 1.5em !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1.5em !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1.5em !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1.5em !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1.5em !important;
  }
  .p-md-3 {
    padding: 2.25em !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 2.25em !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 2.25em !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 2.25em !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 2.25em !important;
  }
  .p-md-4 {
    padding: 3em !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 3em !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 3em !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 3em !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 3em !important;
  }
  .p-md-5 {
    padding: 3.75em !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3.75em !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3.75em !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3.75em !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3.75em !important;
  }
  .p-md-6 {
    padding: 4.5em !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4.5em !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4.5em !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4.5em !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4.5em !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.75em !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.75em !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.75em !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.75em !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.75em !important;
  }
  .m-lg-2 {
    margin: 1.5em !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1.5em !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1.5em !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1.5em !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1.5em !important;
  }
  .m-lg-3 {
    margin: 2.25em !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 2.25em !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 2.25em !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 2.25em !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 2.25em !important;
  }
  .m-lg-4 {
    margin: 3em !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 3em !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 3em !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 3em !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 3em !important;
  }
  .m-lg-5 {
    margin: 3.75em !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3.75em !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3.75em !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3.75em !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3.75em !important;
  }
  .m-lg-6 {
    margin: 4.5em !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4.5em !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4.5em !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4.5em !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4.5em !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.75em !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.75em !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.75em !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.75em !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.75em !important;
  }
  .p-lg-2 {
    padding: 1.5em !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1.5em !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1.5em !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1.5em !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1.5em !important;
  }
  .p-lg-3 {
    padding: 2.25em !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 2.25em !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 2.25em !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 2.25em !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 2.25em !important;
  }
  .p-lg-4 {
    padding: 3em !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 3em !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 3em !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 3em !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 3em !important;
  }
  .p-lg-5 {
    padding: 3.75em !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3.75em !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3.75em !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3.75em !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3.75em !important;
  }
  .p-lg-6 {
    padding: 4.5em !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4.5em !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4.5em !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4.5em !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4.5em !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.75em !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.75em !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.75em !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.75em !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.75em !important;
  }
  .m-xl-2 {
    margin: 1.5em !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 1.5em !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 1.5em !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 1.5em !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 1.5em !important;
  }
  .m-xl-3 {
    margin: 2.25em !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 2.25em !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 2.25em !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 2.25em !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 2.25em !important;
  }
  .m-xl-4 {
    margin: 3em !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 3em !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 3em !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 3em !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 3em !important;
  }
  .m-xl-5 {
    margin: 3.75em !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3.75em !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3.75em !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3.75em !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3.75em !important;
  }
  .m-xl-6 {
    margin: 4.5em !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4.5em !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4.5em !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4.5em !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4.5em !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.75em !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.75em !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.75em !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.75em !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.75em !important;
  }
  .p-xl-2 {
    padding: 1.5em !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 1.5em !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 1.5em !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 1.5em !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 1.5em !important;
  }
  .p-xl-3 {
    padding: 2.25em !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 2.25em !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 2.25em !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 2.25em !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 2.25em !important;
  }
  .p-xl-4 {
    padding: 3em !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 3em !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 3em !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 3em !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 3em !important;
  }
  .p-xl-5 {
    padding: 3.75em !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3.75em !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3.75em !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3.75em !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3.75em !important;
  }
  .p-xl-6 {
    padding: 4.5em !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4.5em !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4.5em !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4.5em !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4.5em !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 400 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 900 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #1F63B5 !important;
}

a.text-primary:focus, a.text-primary:hover {
  color: #184b89 !important;
}

.text-secondary {
  color: #E24747 !important;
}

a.text-secondary:focus, a.text-secondary:hover {
  color: #d52121 !important;
}

.text-tertiary {
  color: #333333 !important;
}

a.text-tertiary:focus, a.text-tertiary:hover {
  color: #1a1a1a !important;
}

.text-quaternary {
  color: #C9D1CE !important;
}

a.text-quaternary:focus, a.text-quaternary:hover {
  color: #adbab5 !important;
}

.text-info {
  color: #ebfbff !important;
}

a.text-info:focus, a.text-info:hover {
  color: #b8f1ff !important;
}

.text-success {
  color: #28af5f !important;
}

a.text-success:focus, a.text-success:hover {
  color: #1f8548 !important;
}

.text-danger {
  color: #f44336 !important;
}

a.text-danger:focus, a.text-danger:hover {
  color: #ea1c0d !important;
}

.text-light {
  color: #F0F5F3 !important;
}

a.text-light:focus, a.text-light:hover {
  color: #d1e1db !important;
}

.text-dark {
  color: #424242 !important;
}

a.text-dark:focus, a.text-dark:hover {
  color: #292929 !important;
}

.text-muted {
  color: 0.65 !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.bg-black {
  background-color: #000 !important;
}

.bg-left-top {
  background-position: 0 0 !important;
}

.bg-left-middle {
  background-position: 0 50% !important;
}

.bg-left-bottom {
  background-position: 0 100% !important;
}

.bg-center-top {
  background-position: 50% 0 !important;
}

.bg-center-middle {
  background-position: 50% 50% !important;
}

.bg-center-bottom {
  background-position: 50% 100% !important;
}

.bg-right-top {
  background-position: 100% 0 !important;
}

.bg-right-middle {
  background-position: 100% 50% !important;
}

.bg-right-bottom {
  background-position: 100% 100% !important;
}

.bg-cover {
  background-size: cover !important;
}

.bg-contain {
  background-size: contain !important;
}

.pos-t-0 {
  top: 0%;
}

.pos-r-0 {
  right: 0%;
}

.pos-b-0 {
  bottom: 0%;
}

.pos-l-0 {
  left: 0%;
}

.pos-t-1 {
  top: 2.25%;
}

.pos-r-1 {
  right: 2.25%;
}

.pos-b-1 {
  bottom: 2.25%;
}

.pos-l-1 {
  left: 2.25%;
}

.pos-t-2 {
  top: 4.5%;
}

.pos-r-2 {
  right: 4.5%;
}

.pos-b-2 {
  bottom: 4.5%;
}

.pos-l-2 {
  left: 4.5%;
}

.pos-t-3 {
  top: 6.75%;
}

.pos-r-3 {
  right: 6.75%;
}

.pos-b-3 {
  bottom: 6.75%;
}

.pos-l-3 {
  left: 6.75%;
}

.pos-t-4 {
  top: 9%;
}

.pos-r-4 {
  right: 9%;
}

.pos-b-4 {
  bottom: 9%;
}

.pos-l-4 {
  left: 9%;
}

.pos-t-5 {
  top: 11.25%;
}

.pos-r-5 {
  right: 11.25%;
}

.pos-b-5 {
  bottom: 11.25%;
}

.pos-l-5 {
  left: 11.25%;
}

.pos-t-6 {
  top: 13.5%;
}

.pos-r-6 {
  right: 13.5%;
}

.pos-b-6 {
  bottom: 13.5%;
}

.pos-l-6 {
  left: 13.5%;
}

.text-black {
  color: #000 !important;
}

.text-heading {
  color: rgba(0, 0, 0, 0.85) !important;
}

.text-link {
  color: #2d9ee0 !important;
}

.text-auto {
  color: inherit !important;
}

.text-muted {
  opacity: 0.65;
}

.text-monospace {
  font-family: "Menlo", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", Courier, monospace;
}

.text-decoration-none, .text-decoration-none:hover {
  text-decoration: none;
}

.line-height-sm {
  line-height: 1.25;
}

.shadow-none {
  box-shadow: none !important;
}

.e-text-dark, .p-section-txt-dark {
  color: rgba(0, 0, 0, 0.65);
}

.e-text-dark h1, .p-section-txt-dark h1,
.e-text-dark .h1, .p-section-txt-dark .h1,
.e-text-dark h2, .p-section-txt-dark h2,
.e-text-dark .h2, .p-section-txt-dark .h2,
.e-text-dark h3, .p-section-txt-dark h3,
.e-text-dark .h3, .p-section-txt-dark .h3,
.e-text-dark h4, .p-section-txt-dark h4,
.e-text-dark .h4, .p-section-txt-dark .h4 {
  color: rgba(0, 0, 0, 0.85);
}

.e-text-dark p a:not(.btn), .p-section-txt-dark p a:not(.btn),
.e-text-dark ul a:not(.btn), .p-section-txt-dark ul a:not(.btn) {
  color: #2d9ee0;
}

.e-text-light, .p-section-txt-light {
  color: rgba(255, 255, 255, 0.75);
}

.e-text-light h1, .p-section-txt-light h1,
.e-text-light .h1, .p-section-txt-light .h1,
.e-text-light h2, .p-section-txt-light h2,
.e-text-light .h2, .p-section-txt-light .h2,
.e-text-light h3, .p-section-txt-light h3,
.e-text-light .h3, .p-section-txt-light .h3,
.e-text-light h4, .p-section-txt-light h4,
.e-text-light .h4, .p-section-txt-light .h4 {
  color: white;
}

.e-text-light p a:not(.btn), .p-section-txt-light p a:not(.btn),
.e-text-light ul a:not(.btn), .p-section-txt-light ul a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.e-text-light p a:not(.btn):hover, .p-section-txt-light p a:not(.btn):hover,
.e-text-light ul a:not(.btn):hover, .p-section-txt-light ul a:not(.btn):hover {
  text-decoration: none;
}

.e-section-height-xs {
  min-height: 17.5vw;
}

@media (max-width: 575px) {
  .e-section-height-xs {
    min-height: 26.25vw;
  }
}

@media (min-width: 2561px) {
  .e-section-height-xs {
    min-height: 448px;
  }
}

.e-section-height-sm {
  min-height: 25vw;
}

@media (max-width: 575px) {
  .e-section-height-sm {
    min-height: 37.5vw;
  }
}

@media (min-width: 2561px) {
  .e-section-height-sm {
    min-height: 640px;
  }
}

.e-section-height-md {
  min-height: 37.5vw;
}

@media (max-width: 575px) {
  .e-section-height-md {
    min-height: 56.25vw;
  }
}

@media (min-width: 2561px) {
  .e-section-height-md {
    min-height: 960px;
  }
}

.e-section-height-lg {
  min-height: 50vw;
}

@media (max-width: 575px) {
  .e-section-height-lg {
    min-height: 75vw;
  }
}

@media (min-width: 2561px) {
  .e-section-height-lg {
    min-height: 1280px;
  }
}

.e-section-height-xl {
  min-height: 62.5vw;
}

@media (max-width: 575px) {
  .e-section-height-xl {
    min-height: 93.75vw;
  }
}

@media (min-width: 2561px) {
  .e-section-height-xl {
    min-height: 1600px;
  }
}

.e-section-height-fullscreen {
  min-height: 100vh;
}

.e-section-primary, .p-section-bg-brand-primary {
  background: #1F63B5;
  border-color: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.75);
}

.e-section-primary hr, .p-section-bg-brand-primary hr {
  border-color: rgba(0, 0, 0, 0.1);
}

.e-section-primary h1, .p-section-bg-brand-primary h1,
.e-section-primary .h1, .p-section-bg-brand-primary .h1,
.e-section-primary h2, .p-section-bg-brand-primary h2,
.e-section-primary .h2, .p-section-bg-brand-primary .h2,
.e-section-primary h3, .p-section-bg-brand-primary h3,
.e-section-primary .h3, .p-section-bg-brand-primary .h3,
.e-section-primary h4, .p-section-bg-brand-primary h4,
.e-section-primary .h4, .p-section-bg-brand-primary .h4 {
  color: white;
}

.e-section-primary p a:not(.btn), .p-section-bg-brand-primary p a:not(.btn),
.e-section-primary ul a:not(.btn), .p-section-bg-brand-primary ul a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.e-section-primary p a:not(.btn):hover, .p-section-bg-brand-primary p a:not(.btn):hover,
.e-section-primary ul a:not(.btn):hover, .p-section-bg-brand-primary ul a:not(.btn):hover {
  text-decoration: none;
}

.e-section-secondary, .p-section-bg-brand-secondary {
  background: #E24747;
  border-color: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.75);
}

.e-section-secondary hr, .p-section-bg-brand-secondary hr {
  border-color: rgba(0, 0, 0, 0.1);
}

.e-section-secondary h1, .p-section-bg-brand-secondary h1,
.e-section-secondary .h1, .p-section-bg-brand-secondary .h1,
.e-section-secondary h2, .p-section-bg-brand-secondary h2,
.e-section-secondary .h2, .p-section-bg-brand-secondary .h2,
.e-section-secondary h3, .p-section-bg-brand-secondary h3,
.e-section-secondary .h3, .p-section-bg-brand-secondary .h3,
.e-section-secondary h4, .p-section-bg-brand-secondary h4,
.e-section-secondary .h4, .p-section-bg-brand-secondary .h4 {
  color: white;
}

.e-section-secondary p a:not(.btn), .p-section-bg-brand-secondary p a:not(.btn),
.e-section-secondary ul a:not(.btn), .p-section-bg-brand-secondary ul a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.e-section-secondary p a:not(.btn):hover, .p-section-bg-brand-secondary p a:not(.btn):hover,
.e-section-secondary ul a:not(.btn):hover, .p-section-bg-brand-secondary ul a:not(.btn):hover {
  text-decoration: none;
}

.e-section-tertiary, .p-section-bg-brand-tertiary {
  background: #333333;
  border-color: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.75);
}

.e-section-tertiary hr, .p-section-bg-brand-tertiary hr {
  border-color: rgba(0, 0, 0, 0.1);
}

.e-section-tertiary h1, .p-section-bg-brand-tertiary h1,
.e-section-tertiary .h1, .p-section-bg-brand-tertiary .h1,
.e-section-tertiary h2, .p-section-bg-brand-tertiary h2,
.e-section-tertiary .h2, .p-section-bg-brand-tertiary .h2,
.e-section-tertiary h3, .p-section-bg-brand-tertiary h3,
.e-section-tertiary .h3, .p-section-bg-brand-tertiary .h3,
.e-section-tertiary h4, .p-section-bg-brand-tertiary h4,
.e-section-tertiary .h4, .p-section-bg-brand-tertiary .h4 {
  color: white;
}

.e-section-tertiary p a:not(.btn), .p-section-bg-brand-tertiary p a:not(.btn),
.e-section-tertiary ul a:not(.btn), .p-section-bg-brand-tertiary ul a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.e-section-tertiary p a:not(.btn):hover, .p-section-bg-brand-tertiary p a:not(.btn):hover,
.e-section-tertiary ul a:not(.btn):hover, .p-section-bg-brand-tertiary ul a:not(.btn):hover {
  text-decoration: none;
}

.e-section-quaternary, .p-section-bg-brand-quaternary {
  background: #C9D1CE;
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.65);
}

.e-section-quaternary hr, .p-section-bg-brand-quaternary hr {
  border-color: rgba(0, 0, 0, 0.1);
}

.e-section-quaternary h1, .p-section-bg-brand-quaternary h1,
.e-section-quaternary .h1, .p-section-bg-brand-quaternary .h1,
.e-section-quaternary h2, .p-section-bg-brand-quaternary h2,
.e-section-quaternary .h2, .p-section-bg-brand-quaternary .h2,
.e-section-quaternary h3, .p-section-bg-brand-quaternary h3,
.e-section-quaternary .h3, .p-section-bg-brand-quaternary .h3,
.e-section-quaternary h4, .p-section-bg-brand-quaternary h4,
.e-section-quaternary .h4, .p-section-bg-brand-quaternary .h4 {
  color: rgba(0, 0, 0, 0.85);
}

.e-section-quaternary p a:not(.btn), .p-section-bg-brand-quaternary p a:not(.btn),
.e-section-quaternary ul a:not(.btn), .p-section-bg-brand-quaternary ul a:not(.btn) {
  color: #2d9ee0;
}

.e-section-white, .p-section-bg-white {
  background: #fff;
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.65);
}

.e-section-white hr, .p-section-bg-white hr {
  border-color: rgba(0, 0, 0, 0.1);
}

.e-section-white h1, .p-section-bg-white h1,
.e-section-white .h1, .p-section-bg-white .h1,
.e-section-white h2, .p-section-bg-white h2,
.e-section-white .h2, .p-section-bg-white .h2,
.e-section-white h3, .p-section-bg-white h3,
.e-section-white .h3, .p-section-bg-white .h3,
.e-section-white h4, .p-section-bg-white h4,
.e-section-white .h4, .p-section-bg-white .h4 {
  color: rgba(0, 0, 0, 0.85);
}

.e-section-white p a:not(.btn), .p-section-bg-white p a:not(.btn),
.e-section-white ul a:not(.btn), .p-section-bg-white ul a:not(.btn) {
  color: #2d9ee0;
}

.e-section-light, .p-section-bg-light {
  background: #F0F5F3;
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.65);
}

.e-section-light hr, .p-section-bg-light hr {
  border-color: rgba(0, 0, 0, 0.1);
}

.e-section-light h1, .p-section-bg-light h1,
.e-section-light .h1, .p-section-bg-light .h1,
.e-section-light h2, .p-section-bg-light h2,
.e-section-light .h2, .p-section-bg-light .h2,
.e-section-light h3, .p-section-bg-light h3,
.e-section-light .h3, .p-section-bg-light .h3,
.e-section-light h4, .p-section-bg-light h4,
.e-section-light .h4, .p-section-bg-light .h4 {
  color: rgba(0, 0, 0, 0.85);
}

.e-section-light p a:not(.btn), .p-section-bg-light p a:not(.btn),
.e-section-light ul a:not(.btn), .p-section-bg-light ul a:not(.btn) {
  color: #2d9ee0;
}

.e-section-dark, .p-section-bg-dark {
  background: #424242;
  border-color: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.75);
}

.e-section-dark hr, .p-section-bg-dark hr {
  border-color: rgba(0, 0, 0, 0.1);
}

.e-section-dark h1, .p-section-bg-dark h1,
.e-section-dark .h1, .p-section-bg-dark .h1,
.e-section-dark h2, .p-section-bg-dark h2,
.e-section-dark .h2, .p-section-bg-dark .h2,
.e-section-dark h3, .p-section-bg-dark h3,
.e-section-dark .h3, .p-section-bg-dark .h3,
.e-section-dark h4, .p-section-bg-dark h4,
.e-section-dark .h4, .p-section-bg-dark .h4 {
  color: white;
}

.e-section-dark p a:not(.btn), .p-section-bg-dark p a:not(.btn),
.e-section-dark ul a:not(.btn), .p-section-bg-dark ul a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.e-section-dark p a:not(.btn):hover, .p-section-bg-dark p a:not(.btn):hover,
.e-section-dark ul a:not(.btn):hover, .p-section-bg-dark ul a:not(.btn):hover {
  text-decoration: none;
}

.e-section-black, .p-section-bg-black {
  background: #000;
  border-color: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.75);
}

.e-section-black hr, .p-section-bg-black hr {
  border-color: rgba(0, 0, 0, 0.1);
}

.e-section-black h1, .p-section-bg-black h1,
.e-section-black .h1, .p-section-bg-black .h1,
.e-section-black h2, .p-section-bg-black h2,
.e-section-black .h2, .p-section-bg-black .h2,
.e-section-black h3, .p-section-bg-black h3,
.e-section-black .h3, .p-section-bg-black .h3,
.e-section-black h4, .p-section-bg-black h4,
.e-section-black .h4, .p-section-bg-black .h4 {
  color: white;
}

.e-section-black p a:not(.btn), .p-section-bg-black p a:not(.btn),
.e-section-black ul a:not(.btn), .p-section-bg-black ul a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.e-section-black p a:not(.btn):hover, .p-section-bg-black p a:not(.btn):hover,
.e-section-black ul a:not(.btn):hover, .p-section-bg-black ul a:not(.btn):hover {
  text-decoration: none;
}

.e-block-link {
  cursor: pointer;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.material-icons {
  font-size: 1em;
  vertical-align: -10%;
}

.material-icons-large {
  padding-left: .25em;
  padding-right: 0.75em;
  transform: scale(1.5);
}

.material-icons-2x {
  padding-left: .325em;
  padding-right: 0.75em;
  transform: scale(2);
}

.material-icons-fixed {
  box-sizing: content-box;
  width: 1.28571429em;
  text-align: center;
}

.material-icons.ml-1 {
  margin-left: 0.375em !important;
}

.material-icons.mr-1 {
  margin-right: 0.375em !important;
}

.material-icons-ul {
  padding-left: 0;
  margin-left: 0;
  list-style-type: none;
}

.material-icons-ul li {
  position: relative;
  padding-left: 2.25em;
}

.material-icons-ul li .material-icons {
  position: absolute;
  left: 0;
  text-align: left;
  transform: scale(1.25) translateY(0.125em);
}

.material-icons-ul.list-condensed li {
  padding-left: 1.875em;
}

.e-canvas {
  position: relative;
  max-width: 2560px;
  margin-right: auto;
  margin-left: auto;
}

.e-canvas.is-fullscreen {
  max-width: 100%;
}

.e-header {
  position: relative;
  z-index: 1030;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  transform: translateY(0);
  transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  will-change: transform;
}

.e-header.is-sticky {
  position: sticky;
  top: 0;
}

.e-header.is-stuck {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.e-header.is-hidden {
  transform: translateY(-110%);
}

.e-header.is-transparent {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  color: white;
  background: none;
}

.e-header.is-transparent::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: -50%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, transparent 95%);
  pointer-events: none;
}

.e-header.is-transparent.is-stuck {
  box-shadow: none;
}

@media (max-width: 991px) {
  .e-nav {
    height: 100vh;
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    transform: translate3d(-101%, 0, 0);
    will-change: transform;
    transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .e-nav.show {
    transform: translate3d(0, 0, 0);
    transition: transform 0.16667s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .e-nav .collapse {
    display: none;
  }
  .e-nav .collapse.show {
    display: block;
  }
  .e-nav .dropdown-toggle::after {
    display: none;
  }
  .e-nav .dropdown-menu {
    position: static;
    float: none;
    min-width: 100%;
    border: 0;
    box-shadow: none;
    background: none;
    padding: 0;
    font-size: inherit;
  }
  .e-nav .dropdown-item {
    background: none;
  }
  .e-nav .e-nav-container {
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .e-nav .e-nav-container .e-header-section {
    background: none !important;
    display: flex;
    flex-direction: column;
    flex: 0 0 100%;
    width: 100%;
  }
  .e-nav .e-nav-container .e-header-section-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .e-nav .e-nav-container .e-logo {
    flex: 0 0 75%;
    width: 75%;
    order: 1;
  }
  .e-nav .e-nav-container .e-nav-global {
    flex: 0 0 100%;
    width: 100%;
    order: 4;
  }
  .e-nav .e-nav-container .e-nav-utilities-primary {
    flex: 0 0 100%;
    width: 100%;
  }
  .e-nav .e-nav-container .e-nav-utilities-secondary {
    flex: 0 0 100%;
    width: 100%;
  }
  .e-nav .e-nav-container .e-nav-hamburger-aside {
    flex: 0 1 25%;
    width: 25%;
    min-height: 100vh;
  }
  .e-nav .e-nav-container .e-nav-item-hamburger-close {
    flex: 0 1 25%;
    width: 25%;
    order: 2;
  }
}

@media (min-width: 992px) {
  .e-header-section > .container-fluid > .row > .col-12 {
    position: static;
  }
  .e-header-section-content {
    position: static;
    display: flex;
  }
  .e-nav {
    display: block;
    position: static;
    overflow: visible;
    opacity: 1;
  }
  .e-nav .e-nav-container {
    color: rgba(0, 0, 0, 0.85);
  }
  .e-nav .e-logo {
    order: 1;
  }
  .e-nav .e-nav-global {
    order: 2;
    margin-left: auto;
  }
  .e-nav .e-nav-utilities-primary {
    order: 2;
    flex: 1 1 100%;
  }
  .e-header .e-nav .e-nav-utilities-secondary {
    display: none;
  }
}

@media (max-width: 991px) {
  .e-header-hamburger {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .is-stuck .e-header-hamburger {
    border-color: transparent;
  }
  .e-header-hamburger .e-header-section-content {
    display: flex;
    align-items: center;
  }
  .e-header-hamburger .e-nav-hamburger-header {
    margin-left: auto;
    margin-right: -4vw;
    flex: 0 1 auto;
  }
}

@media (min-width: 992px) {
  .e-header-hamburger {
    display: none;
  }
}

.e-content {
  position: relative;
  background: #fff;
}

.e-content-main {
  padding-top: 4.5em;
  padding-bottom: 4.5em;
}

.e-content-main.pt-1 {
  padding-top: 3em !important;
}

.e-content-main.pb-1 {
  padding-bottom: 3em !important;
}

.e-content-main.pt-2 {
  padding-top: 6em !important;
}

.e-content-main.pb-2 {
  padding-bottom: 6em !important;
}

.e-content-main.pt-3 {
  padding-top: 9em !important;
}

.e-content-main.pb-3 {
  padding-bottom: 9em !important;
}

.e-content-main.pt-4 {
  padding-top: 12em !important;
}

.e-content-main.pb-4 {
  padding-bottom: 12em !important;
}

.e-content-main.pt-5 {
  padding-top: 15em !important;
}

.e-content-main.pb-5 {
  padding-bottom: 15em !important;
}

.e-section {
  padding-right: 1.5vw;
  padding-left: 1.5vw;
}

@media (min-width: 1200px) {
  .e-section {
    padding-right: 3vw;
    padding-left: 3vw;
  }
}

.e-footer {
  font-size: 0.875em;
}

.e-footer-content {
  background: #424242;
  border-color: rgba(255, 255, 255, 0.75);
  color: rgba(255, 255, 255, 0.75);
  padding-top: 2.25em;
  padding-bottom: 2.25em;
}

.e-footer-content hr {
  border-color: rgba(0, 0, 0, 0.1);
}

.e-footer-content h1,
.e-footer-content .h1,
.e-footer-content h2,
.e-footer-content .h2,
.e-footer-content h3,
.e-footer-content .h3,
.e-footer-content h4,
.e-footer-content .h4 {
  color: white;
}

.e-footer-content p a:not(.btn),
.e-footer-content ul a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.e-footer-content p a:not(.btn):hover,
.e-footer-content ul a:not(.btn):hover {
  text-decoration: none;
}

.e-footer-content svg [id*="Filled_Icons"] {
  fill: rgba(255, 255, 255, 0.75);
  opacity: 0.85;
}

.e-footer-content svg [id*="Outline_Icons"] path,
.e-footer-content svg [id*="Outline_Icons"] rect,
.e-footer-content svg [id*="Outline_Icons"] circle,
.e-footer-content svg [id*="Outline_Icons"] ellipse,
.e-footer-content svg [id*="Outline_Icons"] line,
.e-footer-content svg [id*="Outline_Icons"] polyline,
.e-footer-content svg [id*="Outline_Icons"] polygon {
  stroke: rgba(255, 255, 255, 0.75);
  opacity: 0.85;
}

.e-footer-content .form-control {
  background-color: white;
}

.e-footer-copyright {
  font-size: 0.875em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  color: rgba(255, 255, 255, 0.75);
  background: #212121;
}

.e-footer-copyright p {
  margin: 0;
  color: rgba(255, 255, 255, 0.75);
}

.e-footer-copyright a {
  color: rgba(255, 255, 255, 0.75);
}

.e-footer-copyright a:hover {
  color: white;
}

.e-cta {
  font-size: 0.875em;
  background: #F0F5F3;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  z-index: 1029;
  transform: translateY(0);
  transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.25s;
  will-change: transform;
}

.e-cta.is-hidden {
  transform: translateY(-110%);
  transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.e-cta .row {
  margin: 0;
}

.e-cta .row > [class*="col-"] {
  padding: 0;
}

.e-cta p {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0.375em 0;
  font-size: 0.875em;
  line-height: 1.25;
}

@media (max-width: 575px) {
  .e-cta p {
    justify-content: space-around;
  }
}

@media (min-width: 576px) {
  .e-cta p {
    font-size: 1em;
  }
}

.e-cta a {
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.85);
  padding: 0.5em 0.5em;
}

@media (min-width: 768px) {
  .e-cta a {
    margin-right: 1.5em;
    margin-left: 1.5em;
  }
}

.e-cta a .material-icons {
  transform: scale(1.25) translateY(0.0675em);
  margin-right: 0.5625em;
  color: #1F63B5;
}

.e-cta a:hover {
  color: #1F63B5;
}

.e-cta a:hover .material-icons {
  color: #1F63B5;
}

@media (min-width: 576px) {
  .e-cta a:first-child {
    padding: 0.5em 1em;
    font-size: 1em;
    line-height: 1.25;
    border-radius: 10em;
    color: white;
    background-color: #1F63B5;
    border-color: #1F63B5;
    box-shadow: none;
    transition: none;
    font-family: inherit;
    cursor: pointer;
    font-weight: 900;
    border: 1px solid transparent;
    text-decoration: none;
    margin-top: 0.375em;
    margin-bottom: 0.375em;
    margin-right: 1.5em;
  }
  .e-cta a:first-child:hover {
    color: white;
    background-color: #1F63B5;
    border-color: #1F63B5;
  }
  .e-cta a:first-child.disabled, .e-cta a:first-child:disabled {
    background-color: #1F63B5;
    border-color: #1F63B5;
  }
  .e-cta a:first-child:not([disabled]):not(.disabled):active, .e-cta a:first-child:not([disabled]):not(.disabled).active,
  .show > .e-cta a:first-child.dropdown-toggle {
    color: white;
    background-color: #1F63B5;
    border-color: #1F63B5;
  }
}

@media (min-width: 576px) and (min-width: 768px) {
  .e-cta a:first-child {
    margin-right: 3em;
  }
}

@media (min-width: 576px) {
  .e-cta a:first-child .material-icons {
    display: none;
  }
}

@media (max-width: 991px) {
  .e-nav-global {
    font-size: 1.125em;
    background: #fff;
  }
  .e-nav-global::before {
    content: "";
    display: block;
    margin: 0 -4vw;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .e-nav-global .collapsing {
    display: block;
  }
  .e-nav-global .nav {
    flex-direction: column;
    margin: 0 0 1.0125em 1.875em;
  }
  .e-nav-global .nav-link {
    padding: 0.75em 0em;
    color: rgba(0, 0, 0, 0.85);
    background: none;
    white-space: normal;
    line-height: 1.25;
  }
  .e-nav-global .e-nav-pageicon {
    display: none;
  }
  .e-nav-global > .nav {
    margin: 1.5em 0 0;
  }
  .e-nav-global > .nav > .nav-item {
    opacity: 0;
    transform: translateX(-3em);
    transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .e-nav-global > .nav > .nav-item:nth-child(1) {
    transition-delay: 0.05s;
  }
  .e-nav-global > .nav > .nav-item:nth-child(2) {
    transition-delay: 0.1s;
  }
  .e-nav-global > .nav > .nav-item:nth-child(3) {
    transition-delay: 0.15s;
  }
  .e-nav-global > .nav > .nav-item:nth-child(4) {
    transition-delay: 0.2s;
  }
  .e-nav-global > .nav > .nav-item:nth-child(5) {
    transition-delay: 0.25s;
  }
  .e-nav-global > .nav > .nav-item:nth-child(6) {
    transition-delay: 0.3s;
  }
  .e-nav-global > .nav > .nav-item:nth-child(7) {
    transition-delay: 0.35s;
  }
  .e-nav-global > .nav > .nav-item:nth-child(8) {
    transition-delay: 0.4s;
  }
  .e-nav-global > .nav > .nav-item:nth-child(9) {
    transition-delay: 0.45s;
  }
  .e-nav-global > .nav > .nav-item:nth-child(10) {
    transition-delay: 0.5s;
  }
  .e-nav-global > .nav > .nav-item > .nav-link {
    font-size: 1.125em;
    padding: 0.5625em 0;
    font-weight: 900;
  }
  .show .e-nav-global > .nav > .nav-item {
    opacity: 1;
    transform: translateX(0);
  }
  .e-nav-global .e-megamenu-container .close {
    display: none;
  }
  .e-nav-global .e-megamenu .container-fluid {
    padding: 0;
  }
  .e-nav-global .e-megamenu .row {
    margin: 0;
    display: block;
  }
  .e-nav-global .e-megamenu .e-megamenu-content {
    display: none;
  }
}

@media (min-width: 992px) {
  .e-nav-global .collapse {
    display: none;
  }
  .e-nav-global .dropdown.show > .collapse,
  .e-nav-global .e-dropdown-mega.show .collapse {
    display: block;
  }
  .e-nav-global {
    display: flex;
    font-size: 1.125em;
  }
  .e-nav-global > .nav {
    display: flex;
    justify-content: center;
    align-items: stretch;
  }
  .e-nav-global > .nav > .nav-item {
    display: flex;
    align-items: stretch;
    padding: 0 0.09375em;
  }
  .e-nav-global > .nav > .nav-item > .nav-link {
    display: flex;
    align-items: center;
    padding: 0.75em 1.125em;
    color: rgba(0, 0, 0, 0.85);
    font-size: 0.875em;
    line-height: 1.25;
    font-weight: 900;
    position: relative;
  }
  .e-nav-global > .nav > .nav-item > .nav-link::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: #C9D1CE;
    height: 0.25em;
    background: currentColor;
    transform: scaleX(0);
  }
  .e-nav-global > .nav > .nav-item > .nav-link:hover::before {
    transform: scaleX(1);
    transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .e-nav-global > .nav > .nav-item > .nav-link.is-inpath::before, .e-nav-global > .nav > .nav-item > .nav-link.is-active::before {
    transform: scaleX(1);
    background: #1F63B5;
  }
  .e-nav-global > .nav > .nav-item > .nav-link .e-nav-pageicon {
    transform: scale(1.5);
  }
  .e-nav-global > .nav > .nav-item > .nav-link .e-nav-pageicon + .e-nav-pagename {
    margin-left: 0.875em;
  }
  .e-nav-global > .nav > .nav-item > .nav-link .e-nav-pagename.is-hidden {
    display: none;
  }
  .e-nav-global > .nav > .nav-item > .nav-link .e-nav-pagename.is-hidden + .e-nav-pageicon-dropdown {
    display: none;
  }
  .e-nav-global > .nav > .nav-item > .nav {
    display: none;
  }
  .e-nav-global .dropdown-menu {
    font-family: "Ruda", sans-serif;
    border: 0;
    border-radius: 0 0 0.25em 0.25em;
    margin-left: -1.25em;
  }
  .e-nav-global .dropdown-menu .dropdown-item {
    padding: 0;
  }
  .e-nav-global .dropdown-menu .dropdown-item .nav-link {
    padding: 0.5em 2.5em;
    display: block;
    color: inherit;
  }
  .e-nav-global .e-dropdown-mega {
    position: static;
  }
  .e-nav-global .e-megamenu-container {
    left: 0;
    right: 0;
    width: auto;
    max-height: calc(100vh - 4.425em);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
  }
  .e-nav-global .e-megamenu {
    padding-right: 1.5vw;
    padding-left: 1.5vw;
    max-width: 2560px;
    margin: 0 auto;
    padding: 2.25em 1.5vw 3em;
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .e-nav-global .e-megamenu {
    padding-right: 3vw;
    padding-left: 3vw;
  }
}

@media (min-width: 992px) {
  .e-nav-global .e-megamenu .e-megamenu-content {
    font-size: 0.875em;
    padding-bottom: 2.25em;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 2.5%;
    padding-left: 2.5%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) and (min-width: 768px) {
  .e-nav-global .e-megamenu .e-megamenu-content {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    margin-right: 8.33333%;
    margin-left: 8.33333%;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  .e-nav-global .e-megamenu .e-megamenu-content {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    margin-right: 16.66667%;
    margin-left: 16.66667%;
  }
}

@media (min-width: 992px) {
  .e-nav-global .e-megamenu .e-megamenu-content h1,
  .e-nav-global .e-megamenu .e-megamenu-content h2,
  .e-nav-global .e-megamenu .e-megamenu-content h3,
  .e-nav-global .e-megamenu .e-megamenu-content h4,
  .e-nav-global .e-megamenu .e-megamenu-content p {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  .e-nav-global .e-megamenu .e-megamenu-content a {
    color: inherit;
    text-decoration: underline;
  }
  .e-nav-global .e-megamenu .e-megamenu-content a:hover {
    text-decoration: none;
  }
  .e-nav-global .e-megamenu .e-megamenu-nav {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 2.5%;
    padding-left: 2.5%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .e-nav-global .e-megamenu .e-megamenu-nav > .nav {
    display: flex;
    flex-wrap: wrap;
    margin-right: -2.5%;
    margin-left: -2.5%;
  }
  .e-nav-global .e-megamenu .e-megamenu-item {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 2.5%;
    padding-left: 2.5%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .e-nav-global .e-megamenu .e-megamenu-item .e-megamenu-header {
    font-size: 1.125em;
    font-weight: 900;
    color: rgba(0, 0, 0, 0.65);
    margin-top: 1.5em;
    margin-bottom: 0.375em;
    pointer-events: none;
  }
  .e-nav-global .e-megamenu .e-megamenu-item .nav .nav-item {
    line-height: 1.375;
  }
  .e-nav-global .e-megamenu .e-megamenu-item .nav .nav-link {
    display: block;
    color: rgba(0, 0, 0, 0.65);
    padding-left: 1.5em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    position: relative;
  }
  .e-nav-global .e-megamenu .e-megamenu-item .nav .nav-link::before {
    content: '–';
    color: rgba(0, 0, 0, 0.65);
    opacity: 0.65;
    position: absolute;
    left: 0;
  }
  .e-nav-global .e-megamenu .e-megamenu-item .nav .nav-link::after {
    content: "arrow_forward";
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1em;
    vertical-align: -10%;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: 'liga';
    font-smoothing: antialiased;
    color: #2d9ee0;
    width: 0;
    opacity: 0;
    transform: translateX(-1em);
  }
  .e-nav-global .e-megamenu .e-megamenu-item .nav .nav-link:hover {
    color: #2d9ee0;
  }
  .e-nav-global .e-megamenu .e-megamenu-item .nav .nav-link:hover::after {
    opacity: 1;
    transform: translateX(0.5em);
    transition: opacity 0.1875s cubic-bezier(0.33, 0, 0.2, 1), transform 0.125s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .e-nav-global .e-megamenu.e-megamenu-item-count-2 .e-megamenu-item {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .e-nav-global .e-megamenu.e-megamenu-item-count-3 .e-megamenu-item, .e-nav-global .e-megamenu.e-megamenu-item-count-6 .e-megamenu-item {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .e-nav-global .e-megamenu.e-megamenu-item-count-5 .e-megamenu-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .e-nav-global .e-megamenu.e-megamenu-item-count-7 .e-megamenu-item, .e-nav-global .e-megamenu.e-megamenu-item-count-8 .e-megamenu-item {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
}

@media (max-width: 991px) {
  .e-nav-utilities-primary {
    margin-top: 2.25em;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 2.25em;
  }
  .e-nav-utilities-primary .nav {
    flex-direction: column;
    margin: 0 0 1.0125em 1.875em;
  }
  .e-nav-utilities-primary .nav-link {
    padding: 0.75em 0em;
    color: rgba(0, 0, 0, 0.85);
    background: none;
    white-space: normal;
    line-height: 1.25;
  }
  .e-nav-utilities-primary .e-nav-pageicon {
    transform: scale(1.5);
  }
  .e-nav-utilities-primary .e-nav-pageicon + .e-nav-pagename {
    margin-left: 0.875em;
  }
  .e-nav-utilities-primary .e-nav-pageicon-language {
    display: inline-block;
    height: 1em;
    width: 1em;
    vertical-align: middle;
    border-radius: 50%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    transform: translateY(-0.125em);
  }
  .e-nav-utilities-primary > .nav {
    flex-direction: column-reverse;
    margin: 0;
    opacity: 0;
    transform: translateY(4.5em);
    transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s;
  }
  .show .e-nav-utilities-primary > .nav {
    opacity: 1;
    transform: translateY(0);
  }
  .e-nav-utilities-primary > .nav > .nav-item > .nav-link {
    padding: 0.5625em 0;
  }
}

@media (min-width: 992px) {
  .e-nav-utilities-primary {
    font-size: 0.875em;
  }
  .e-nav-utilities-primary .nav-link {
    color: inherit;
  }
  .e-nav-utilities-primary .e-nav-pageicon {
    color: rgba(0, 0, 0, 0.85);
    transform: scale(1.5);
  }
  .e-nav-utilities-primary .e-nav-pageicon + .e-nav-pagename {
    margin-left: 0.875em;
  }
  .e-nav-utilities-primary .e-nav-pageicon-language {
    display: inline-block;
    height: 1em;
    width: 1em;
    vertical-align: middle;
    border-radius: 50%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    transform: translateY(-0.0675em);
  }
  .e-nav-utilities-primary .e-nav-pagename.is-hidden {
    display: none;
  }
  .e-nav-utilities-primary .e-nav-pagename.is-hidden + .e-nav-pageicon-dropdown {
    display: none;
  }
  .e-nav-utilities-primary .dropdown-menu {
    font-size: 1em;
    margin-top: -1px;
    min-width: 100%;
  }
  .e-nav-utilities-primary .dropdown-menu .nav-link {
    padding: 0.5em 1.5em;
  }
  .e-nav-utilities-primary > .nav {
    justify-content: flex-end;
  }
  .e-nav-utilities-primary > .nav > .nav-item {
    padding: 0 0.09375em;
  }
  .e-nav-utilities-primary > .nav > .nav-item > .nav-link {
    padding: 1.0125em 1.5em;
    display: flex;
    align-items: center;
  }
  .e-nav-utilities-primary > .nav > .nav-item > .nav-link .e-nav-pageicon-language + .e-nav-pagename,
  .e-nav-utilities-primary > .nav > .nav-item > .nav-link .e-nav-pageicon + .e-nav-pagename {
    margin-left: 0.75em;
  }
  .e-nav-utilities-primary > .nav > .nav-item > .nav-link .e-nav-pagename.is-hidden {
    display: none;
  }
  .e-nav-utilities-primary > .nav > .nav-item > .nav-link .e-nav-pagename.is-hidden + .e-nav-pageicon-dropdown {
    display: none;
  }
  .e-nav-utilities-primary > .nav > .nav-item > .nav-link .e-nav-pagename + .e-nav-pageicon-dropdown {
    margin-left: 0.375em;
  }
}

@media (max-width: 991px) {
  .e-nav .e-nav-utilities-secondary {
    font-size: 0.875em;
    margin-bottom: 3em;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.05);
    opacity: 0;
  }
  .show .e-nav .e-nav-utilities-secondary {
    transition: all 0.375s cubic-bezier(0.33, 0, 0.2, 1) 0.125s;
    opacity: 1;
  }
  .e-nav .e-nav-utilities-secondary > .nav {
    margin-top: 2.25em;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 2.25em;
  }
  .e-nav .e-nav-utilities-secondary > .nav > .nav-item > .nav-link {
    display: block;
    padding: 0.75em 0em;
    color: inherit;
    background: none;
    white-space: normal;
  }
}

@media (min-width: 992px) {
  .e-nav-utilities-secondary .nav {
    margin-left: -1em;
  }
}

@media (max-width: 991px) {
  .e-nav-hamburger-header .nav-item {
    display: flex;
  }
  .e-nav-hamburger-header .nav-link {
    color: inherit;
    display: flex;
    align-items: center;
  }
  .e-nav-hamburger-header .e-nav-pageicon {
    color: rgba(0, 0, 0, 0.85);
    transform: scale(1.5);
  }
  .e-nav-hamburger-header .e-nav-pageicon + .e-nav-pagename {
    margin-left: 0.875em;
  }
  .e-nav-hamburger-header .e-nav-pagename.is-hidden {
    display: none;
  }
}

@media (max-width: 991px) {
  .e-nav-hamburger-aside .nav {
    color: rgba(0, 0, 0, 0.85);
    flex-direction: column;
    margin: 0 0 0.75em 1.875em;
  }
  .e-nav-hamburger-aside .nav-link {
    display: block;
    padding: 0.75em 0em;
    color: rgba(0, 0, 0, 0.85);
    background: none;
    white-space: normal;
  }
  .e-nav-hamburger-aside .e-nav-pageicon {
    transform: scale(1.5);
    margin-right: 0.75em;
  }
  .e-nav-hamburger-aside .e-nav-pageicon-language {
    display: inline-block;
    height: 1em;
    width: 1em;
    vertical-align: middle;
    border-radius: 50%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    transform: translateY(-0.0675em);
  }
  .e-nav-hamburger-aside .e-nav-pageicon + .e-nav-pagename,
  .e-nav-hamburger-aside .e-nav-pageicon-language + .e-nav-pagename {
    margin-left: 0.875em;
  }
  .e-nav-hamburger-aside .e-nav-pageicon-dropdown {
    display: none;
  }
  .e-nav-hamburger-aside .dropdown-toggle::after {
    display: none;
  }
  .e-nav-hamburger-aside > .nav {
    flex-direction: column-reverse;
    margin: 1.5em 2.5% 7.5em;
    padding: 0 1.5vw;
  }
}

@media (max-width: 991px) {
  .e-nav-item-hamburger-close .nav-link {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 0;
    color: inherit;
    text-transform: uppercase;
  }
  .show .e-nav-item-hamburger-close .e-nav-pageicon {
    transform: rotate(360deg);
    transition: transform 0.25s linear 0.21875s;
  }
  .e-nav-item-hamburger-close .e-nav-pagename {
    font-size: 0.875em;
    margin: 0 0.75em 0.25em;
    opacity: 0;
  }
  .show .e-nav-item-hamburger-close .e-nav-pagename {
    opacity: 1;
    transition: opacity 0.375s cubic-bezier(0.33, 0, 0.2, 1) 0.75s;
  }
}

@media (min-width: 992px) {
  .e-nav-item-hamburger-close {
    display: none;
  }
}

@media (max-width: 991px) {
  .e-nav-local {
    display: none !important;
  }
}

.e-nav-local .e-nav-local-container {
  font-size: 0.875em;
}

.e-nav-local .nav-link:not(.btn) {
  color: inherit;
}

.e-nav-local-horizontal {
  position: relative;
  z-index: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.e-nav-local-horizontal:first-child {
  display: inline-block;
  width: 100%;
}

.e-nav-local-horizontal.is-sticky {
  position: sticky;
  top: -1px;
  background: #fff;
}

.e-nav-local-horizontal.is-sticky.is-stuck {
  border: 0;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.e-nav-local-horizontal.is-absolute {
  color: white;
  position: absolute;
  top: auto;
  width: 100%;
  background: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.65);
}

.e-nav-local-horizontal.is-absolute::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: -50%;
  left: 0;
  opacity: 1;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.45) 0, transparent 100%);
  transition: opacity, bottom 0.375s cubic-bezier(0.33, 0, 0.2, 1) 0.375s;
}

.e-nav-local-horizontal.is-absolute.is-sticky {
  background: none;
}

.e-nav-local-horizontal.is-absolute.is-sticky::after {
  content: "";
  background: rgba(0, 0, 0, 0.85);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transform: scaleX(0);
  transition: opacity, transform 0.375s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  will-change: opacity, transform;
}

.e-nav-local-horizontal.is-absolute.is-sticky.is-stuck {
  border: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.e-nav-local-horizontal.is-absolute.is-sticky.is-stuck::before {
  bottom: 0;
  opacity: 0;
}

.e-nav-local-horizontal.is-absolute.is-sticky.is-stuck::after {
  opacity: 1;
  transform: scaleX(1);
}

.e-nav-local-horizontal .e-nav-local-container {
  position: relative;
  z-index: 1;
}

.e-nav-local-horizontal .e-nav-local-heading {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 auto 0 0;
}

.e-nav-local-horizontal .e-nav-local-heading h2 {
  color: inherit;
  font-size: 1.375em;
  font-weight: 900;
  margin: 0;
}

.e-nav-local-horizontal .nav-link.is-active, .e-nav-local-horizontal .nav-link.is-inpath {
  font-weight: 900;
}

.e-nav-local-horizontal .e-nav-local-container > .nav > .nav-item {
  display: flex;
  align-items: center;
}

.e-nav-local-horizontal .e-nav-local-container > .nav > .nav-item:first-child {
  margin-left: -1.5em;
}

.e-nav-local-horizontal .e-nav-local-container > .nav > .nav-item:last-child:not(.is-cta) {
  margin-right: -1.5em;
}

.e-nav-local-horizontal .e-nav-local-container > .nav > .nav-item > .nav-link {
  display: flex;
  align-items: center;
  line-height: 1.09375;
  padding: 1.3125em 1.5em 1.125em;
}

.e-nav-local-horizontal .e-nav-local-container > .nav > .nav-item > .nav-link.btn {
  padding: 0.75em 1.5em;
}

.e-nav-local-horizontal .dropdown-toggle::after {
  display: none;
}

.e-nav-local-horizontal .dropdown-menu {
  font-size: 1em;
}

.e-nav-local-horizontal .dropdown-menu .nav-link {
  padding: 0.75em 1.6875em;
}

.e-nav-local-vertical {
  position: absolute;
  top: auto;
  width: 100%;
  z-index: 1;
  pointer-events: none;
}

.e-nav-local-vertical.is-sticky.is-stuck {
  position: fixed;
  top: 1.5em;
}

.e-nav-local-vertical.is-sticky.is-stuck.has-reached-bottom {
  position: absolute;
  top: auto;
  bottom: 0;
}

.e-nav-local-vertical.is-sticky.is-stuck .e-nav-local-container, .e-nav-local-vertical.is-absolute .e-nav-local-container {
  margin: 0 -1.5em 1.5em;
  padding: 2.25em 1.5em;
  background: rgba(255, 255, 255, 0.85);
}

.e-nav-local-vertical .e-nav-local-container {
  pointer-events: auto;
}

.e-nav-local-vertical .e-nav-local-heading {
  padding: 0;
  margin: 0;
}

.e-nav-local-vertical .e-nav-local-heading h2 {
  margin-bottom: 0.375em;
}

.e-nav-local-vertical .nav-link {
  color: inherit;
  padding: 0.46875em 0;
  line-height: 1.25;
}

.e-nav-local-vertical .nav-link:hover {
  color: #1F63B5;
}

.e-nav-local-vertical .nav-link.is-active {
  font-weight: 900;
  color: #1F63B5;
}

.e-nav-local-vertical .nav .nav {
  margin: 0.375em 0 1.5em 1.5em;
}

.e-nav-local-vertical .nav .nav .nav-link {
  transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.e-nav-local-vertical .nav .nav .nav-link:hover:not(.is-active) {
  transition: transform 0.125s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: translateX(0.75em);
}

.e-nav-local-vertical .nav .nav .nav-link.is-active {
  position: relative;
  padding-left: 0.75em;
}

.e-nav-local-vertical .nav .nav .nav-link.is-active::before {
  content: "";
  position: absolute;
  top: 0.1875em;
  left: 0;
  bottom: 0.1875em;
  width: 0.28125em;
  background: currentColor;
}

.e-nav-local-vertical .e-nav-local-container > .nav {
  font-size: 0.875em;
}

.e-nav-local-vertical .e-nav-local-container > .nav > .nav-item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.e-nav-local-vertical .e-nav-local-container > .nav > .nav-item:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.e-nav-local-vertical .e-nav-local-container > .nav > .nav-item > .nav-link {
  font-weight: 900;
  padding: 1.0125em 0;
}

.e-nav-local-vertical .e-nav-local-container > .nav > .nav-item > .nav {
  margin-top: 0;
}

.e-section > .container-fluid > .row > div > .row > .p-width-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 2.5%;
  padding-left: 2.5%;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 768px) {
  .e-section > .container-fluid > .row > .col-lg-12 > .row > .p-width-auto {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    margin-right: 8.33333%;
    margin-left: 8.33333%;
  }
}

@media (min-width: 992px) {
  .e-section > .container-fluid > .row > .col-lg-12 > .row > .p-width-auto {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    margin-right: 16.66667%;
    margin-left: 16.66667%;
  }
}

.p-img {
  max-width: 100%;
  height: auto;
}

.p-img-shadow-none {
  box-shadow: none;
}

.p-img-shadow-small {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-img-shadow-medium {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.p-img-shadow-large {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.p-img-border-none {
  border: 0;
}

.p-img-border-thin, .p-img-border-double {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.p-img-border-thick {
  border: 0.375em solid rgba(0, 0, 0, 0.1);
}

.p-img-border-double {
  padding: 0.25em;
}

.p-img-link {
  text-decoration: none;
}

.p-imagetext[class*="mb-"] .p-txt-container > :last-child {
  margin-bottom: 0;
}

.p-imagetext .p-img-container .col-pos-center {
  margin-left: auto;
  margin-right: auto;
}

.p-imagetext .p-img-container .col-pos-right {
  margin-left: auto;
}

.p-imagetext .p-img-container .p-img[src$="svg"] {
  width: 100%;
}

.p-imagetext-lr[class*="mb-"] .p-img-container > :last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .e-section > .container-fluid > .row > .col-12.col-lg-8 .p-imagetext-tb.col-xl-10 .p-txt-container h1[style*="text-align"], .e-section > .container-fluid > .row > .col-12.col-lg-8 .p-imagetext-tb.col-xl-12 .p-txt-container h1[style*="text-align"], .e-section > .container-fluid > .row > .col-12.col-lg-8 .p-imagetext-tb.p-width-auto .p-txt-container h1[style*="text-align"],
  .e-section > .container-fluid > .row > .col-12.col-lg-10 .p-imagetext-tb.col-xl-10 .p-txt-container h1[style*="text-align"],
  .e-section > .container-fluid > .row > .col-12.col-lg-10 .p-imagetext-tb.col-xl-12 .p-txt-container h1[style*="text-align"],
  .e-section > .container-fluid > .row > .col-12.col-lg-10 .p-imagetext-tb.p-width-auto .p-txt-container h1[style*="text-align"],
  .e-section > .container-fluid > .row > .col-12.col-lg-12 .p-imagetext-tb.col-xl-10 .p-txt-container h1[style*="text-align"],
  .e-section > .container-fluid > .row > .col-12.col-lg-12 .p-imagetext-tb.col-xl-12 .p-txt-container h1[style*="text-align"],
  .e-section > .container-fluid > .row > .col-12.col-lg-12 .p-imagetext-tb.p-width-auto .p-txt-container h1[style*="text-align"] {
    padding-left: 10%;
    padding-right: 10%;
  }
  .e-section > .container-fluid > .row > .col-12.col-lg-8 .p-imagetext-tb.col-xl-10 .p-txt-container h2[style*="text-align"], .e-section > .container-fluid > .row > .col-12.col-lg-8 .p-imagetext-tb.col-xl-12 .p-txt-container h2[style*="text-align"], .e-section > .container-fluid > .row > .col-12.col-lg-8 .p-imagetext-tb.p-width-auto .p-txt-container h2[style*="text-align"],
  .e-section > .container-fluid > .row > .col-12.col-lg-10 .p-imagetext-tb.col-xl-10 .p-txt-container h2[style*="text-align"],
  .e-section > .container-fluid > .row > .col-12.col-lg-10 .p-imagetext-tb.col-xl-12 .p-txt-container h2[style*="text-align"],
  .e-section > .container-fluid > .row > .col-12.col-lg-10 .p-imagetext-tb.p-width-auto .p-txt-container h2[style*="text-align"],
  .e-section > .container-fluid > .row > .col-12.col-lg-12 .p-imagetext-tb.col-xl-10 .p-txt-container h2[style*="text-align"],
  .e-section > .container-fluid > .row > .col-12.col-lg-12 .p-imagetext-tb.col-xl-12 .p-txt-container h2[style*="text-align"],
  .e-section > .container-fluid > .row > .col-12.col-lg-12 .p-imagetext-tb.p-width-auto .p-txt-container h2[style*="text-align"] {
    padding-left: 15%;
    padding-right: 15%;
  }
}

.p-icontext {
  margin-bottom: 1.5em;
}

.p-icontext :last-child {
  margin-bottom: 0;
}

.p-icontext .p-img {
  margin-bottom: 0.75em;
  position: relative;
  padding-top: 100%;
}

.p-icontext .p-img svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  max-height: 100%;
}

.p-icontext-white svg [id*="Filled_Icon"] {
  fill: #fff;
}

.p-icontext-white svg [id*="Outline_Icon"] path,
.p-icontext-white svg [id*="Outline_Icon"] rect,
.p-icontext-white svg [id*="Outline_Icon"] circle,
.p-icontext-white svg [id*="Outline_Icon"] ellipse,
.p-icontext-white svg [id*="Outline_Icon"] line,
.p-icontext-white svg [id*="Outline_Icon"] polyline,
.p-icontext-white svg [id*="Outline_Icon"] polygon {
  stroke: #fff;
}

.p-icontext-black svg [id*="Filled_Icon"] {
  fill: #000;
}

.p-icontext-black svg [id*="Outline_Icon"] path,
.p-icontext-black svg [id*="Outline_Icon"] rect,
.p-icontext-black svg [id*="Outline_Icon"] circle,
.p-icontext-black svg [id*="Outline_Icon"] ellipse,
.p-icontext-black svg [id*="Outline_Icon"] line,
.p-icontext-black svg [id*="Outline_Icon"] polyline,
.p-icontext-black svg [id*="Outline_Icon"] polygon {
  stroke: #000;
}

.p-icontext-light svg [id*="Filled_Icon"] {
  fill: #bdbdbd;
}

.p-icontext-light svg [id*="Outline_Icon"] path,
.p-icontext-light svg [id*="Outline_Icon"] rect,
.p-icontext-light svg [id*="Outline_Icon"] circle,
.p-icontext-light svg [id*="Outline_Icon"] ellipse,
.p-icontext-light svg [id*="Outline_Icon"] line,
.p-icontext-light svg [id*="Outline_Icon"] polyline,
.p-icontext-light svg [id*="Outline_Icon"] polygon {
  stroke: #bdbdbd;
}

.p-icontext-dark svg [id*="Filled_Icon"] {
  fill: #757575;
}

.p-icontext-dark svg [id*="Outline_Icon"] path,
.p-icontext-dark svg [id*="Outline_Icon"] rect,
.p-icontext-dark svg [id*="Outline_Icon"] circle,
.p-icontext-dark svg [id*="Outline_Icon"] ellipse,
.p-icontext-dark svg [id*="Outline_Icon"] line,
.p-icontext-dark svg [id*="Outline_Icon"] polyline,
.p-icontext-dark svg [id*="Outline_Icon"] polygon {
  stroke: #757575;
}

.p-icontext-brand-primary svg [id*="Filled_Icon"] {
  fill: #1F63B5;
}

.p-icontext-brand-primary svg [id*="Outline_Icon"] path,
.p-icontext-brand-primary svg [id*="Outline_Icon"] rect,
.p-icontext-brand-primary svg [id*="Outline_Icon"] circle,
.p-icontext-brand-primary svg [id*="Outline_Icon"] ellipse,
.p-icontext-brand-primary svg [id*="Outline_Icon"] line,
.p-icontext-brand-primary svg [id*="Outline_Icon"] polyline,
.p-icontext-brand-primary svg [id*="Outline_Icon"] polygon {
  stroke: #1F63B5;
}

.p-icontext-brand-secondary svg [id*="Filled_Icon"] {
  fill: #E24747;
}

.p-icontext-brand-secondary svg [id*="Outline_Icon"] path,
.p-icontext-brand-secondary svg [id*="Outline_Icon"] rect,
.p-icontext-brand-secondary svg [id*="Outline_Icon"] circle,
.p-icontext-brand-secondary svg [id*="Outline_Icon"] ellipse,
.p-icontext-brand-secondary svg [id*="Outline_Icon"] line,
.p-icontext-brand-secondary svg [id*="Outline_Icon"] polyline,
.p-icontext-brand-secondary svg [id*="Outline_Icon"] polygon {
  stroke: #E24747;
}

.p-icontext-brand-tertiary svg [id*="Filled_Icon"] {
  fill: #333333;
}

.p-icontext-brand-tertiary svg [id*="Outline_Icon"] path,
.p-icontext-brand-tertiary svg [id*="Outline_Icon"] rect,
.p-icontext-brand-tertiary svg [id*="Outline_Icon"] circle,
.p-icontext-brand-tertiary svg [id*="Outline_Icon"] ellipse,
.p-icontext-brand-tertiary svg [id*="Outline_Icon"] line,
.p-icontext-brand-tertiary svg [id*="Outline_Icon"] polyline,
.p-icontext-brand-tertiary svg [id*="Outline_Icon"] polygon {
  stroke: #333333;
}

.p-icontext-brand-quaternary svg [id*="Filled_Icon"] {
  fill: #C9D1CE;
}

.p-icontext-brand-quaternary svg [id*="Outline_Icon"] path,
.p-icontext-brand-quaternary svg [id*="Outline_Icon"] rect,
.p-icontext-brand-quaternary svg [id*="Outline_Icon"] circle,
.p-icontext-brand-quaternary svg [id*="Outline_Icon"] ellipse,
.p-icontext-brand-quaternary svg [id*="Outline_Icon"] line,
.p-icontext-brand-quaternary svg [id*="Outline_Icon"] polyline,
.p-icontext-brand-quaternary svg [id*="Outline_Icon"] polygon {
  stroke: #C9D1CE;
}

.p-icontext-auto svg [id*="Filled_Icon"],
.p-icontext .p-img[style] svg [id*="Filled_Icon"] {
  fill: currentColor;
}

.p-icontext-auto svg [id*="Outline_Icon"] path,
.p-icontext-auto svg [id*="Outline_Icon"] rect,
.p-icontext-auto svg [id*="Outline_Icon"] circle,
.p-icontext-auto svg [id*="Outline_Icon"] ellipse,
.p-icontext-auto svg [id*="Outline_Icon"] line,
.p-icontext-auto svg [id*="Outline_Icon"] polyline,
.p-icontext-auto svg [id*="Outline_Icon"] polygon,
.p-icontext .p-img[style] svg [id*="Outline_Icon"] path,
.p-icontext .p-img[style] svg [id*="Outline_Icon"] rect,
.p-icontext .p-img[style] svg [id*="Outline_Icon"] circle,
.p-icontext .p-img[style] svg [id*="Outline_Icon"] ellipse,
.p-icontext .p-img[style] svg [id*="Outline_Icon"] line,
.p-icontext .p-img[style] svg [id*="Outline_Icon"] polyline,
.p-icontext .p-img[style] svg [id*="Outline_Icon"] polygon {
  stroke: currentColor;
}

.p-banner {
  padding-right: 1.5vw;
  padding-left: 1.5vw;
  position: relative;
  margin-bottom: 1.5em;
  display: flex;
}

@media (min-width: 1200px) {
  .p-banner {
    padding-right: 3vw;
    padding-left: 3vw;
  }
}

.p-banner.pt-1 {
  padding-top: 1.5em !important;
}

.p-banner.pb-1 {
  padding-bottom: 1.5em !important;
}

.p-banner.pt-2 {
  padding-top: 3em !important;
}

.p-banner.pb-2 {
  padding-bottom: 3em !important;
}

.p-banner.pt-3 {
  padding-top: 4.5em !important;
}

.p-banner.pb-3 {
  padding-bottom: 4.5em !important;
}

.p-banner.pt-4 {
  padding-top: 6em !important;
}

.p-banner.pb-4 {
  padding-bottom: 6em !important;
}

.p-banner.pt-5 {
  padding-top: 7.5em !important;
}

.p-banner.pb-5 {
  padding-bottom: 7.5em !important;
}

.p-banner .p-txt-container::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (max-width: 767px) {
  .p-banner .p-txt-container::before {
    background: rgba(0, 0, 0, 0.45);
  }
}

.p-banner .p-txt-wrapper {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

@media (min-width: 768px) {
  .p-banner .p-txt-wrapper {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
  }
}

@media (min-width: 992px) {
  .p-banner .p-txt-wrapper {
    padding-top: 2.25em;
    padding-bottom: 2.25em;
  }
}

.p-banner h1,
.p-banner h2,
.p-banner h3,
.p-banner h4,
.p-banner p {
  color: white;
  margin-bottom: 0.5rem;
}

.p-banner h1:last-child,
.p-banner h2:last-child,
.p-banner h3:last-child,
.p-banner h4:last-child,
.p-banner p:last-child {
  margin-bottom: 0;
}

.p-banner h4 {
  color: rgba(255, 255, 255, 0.75);
}

.p-banner p {
  color: rgba(255, 255, 255, 0.75);
}

.p-banner a:not(.btn) {
  text-decoration: underline;
  color: inherit;
}

.p-banner .btn {
  margin-top: 1.5rem;
}

.p-banner .p-img-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
}

.p-banner-auto .p-txt-wrapper {
  padding-top: 3em;
  padding-bottom: 3em;
}

.p-banner-txt-left-top {
  padding-bottom: 3em;
}

.p-banner-txt-left-top::before {
  background: linear-gradient(157deg, rgba(0, 0, 0, 0.45) 0%, transparent 80%);
}

.p-banner-txt-left-middle {
  padding-top: 3em;
  padding-bottom: 3em;
}

.p-banner-txt-left-middle::before {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.45) 0%, transparent 80%);
}

.p-banner-txt-left-bottom {
  padding-top: 3em;
}

.p-banner-txt-left-bottom::before {
  background: linear-gradient(22deg, rgba(0, 0, 0, 0.45) 0%, transparent 80%);
}

.p-banner-txt-center-top {
  padding-bottom: 3em;
}

.p-banner-txt-center-top::before {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.45) 0%, transparent 65%);
}

.p-banner-txt-center-middle {
  padding-top: 3em;
  padding-bottom: 3em;
}

.p-banner-txt-center-middle::before {
  background: rgba(0, 0, 0, 0.55);
}

.p-banner-txt-center-bottom {
  padding-top: 3em;
}

.p-banner-txt-center-bottom::before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.45) 0%, transparent 65%);
}

.p-banner-txt-right-top {
  padding-bottom: 3em;
}

.p-banner-txt-right-top::before {
  background: linear-gradient(202deg, rgba(0, 0, 0, 0.45) 0%, transparent 80%);
}

.p-banner-txt-right-middle {
  padding-top: 3em;
  padding-bottom: 3em;
}

.p-banner-txt-right-middle::before {
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.45) 0%, transparent 80%);
}

.p-banner-txt-right-bottom {
  padding-top: 3em;
}

.p-banner-txt-right-bottom::before {
  background: linear-gradient(338deg, rgba(0, 0, 0, 0.45) 0%, transparent 80%);
}

.p-banner-gradient-disabled .p-txt-container::before {
  background: none;
}

.p-banner-animation-image-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  transform: translateZ(0);
}

.p-banner-animation-image-container .p-banner-animation-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: 50% 50%;
  opacity: 0;
  will-change: transform;
}

.p-banner-animation-image-container .p-banner-animation-image.animate-in {
  opacity: 1;
  transition: opacity 0.9375s cubic-bezier(0.33, 0, 0.2, 1);
  animation: zoom 9.375s linear forwards;
  -ms-animation-name: none;
}

.p-banner-animation-image-container .p-banner-animation-image.animate-out {
  opacity: 0;
  transition: opacity 1.875s cubic-bezier(0.33, 0, 0.2, 1);
  transform: scale(1.25, 1.25);
}

.p-banner-next-section-link {
  color: white;
  font-size: 3em;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.p-banner-next-section-link .p-banner-next-section-link-icon {
  animation: bounce 5s infinite;
  animation-fill-mode: both;
  transform-origin: center bottom;
}

.p-banner-next-section-link:hover, .p-banner-next-section-link:active {
  color: white;
}

@media (max-width: 767px) {
  .p-banner-next-section-link {
    display: none;
  }
}

.p-banner-img-left-top {
  background-position: 0 0;
}

.p-banner-img-left-middle {
  background-position: 0 50%;
}

.p-banner-img-left-bottom {
  background-position: 0 100%;
}

.p-banner-img-center-top {
  background-position: 50% 0;
}

.p-banner-img-center-middle {
  background-position: 50% 50%;
}

.p-banner-img-center-bottom {
  background-position: 50% 100%;
}

.p-banner-img-right-top {
  background-position: 100% 0;
}

.p-banner-img-right-middle {
  background-position: 100% 50%;
}

.p-banner-img-right-bottom {
  background-position: 100% 100%;
}

.p-mosaic .p-img {
  background-repeat: no-repeat;
}

.p-mosaic .p-txt-container {
  padding-top: 2.25em;
  padding-bottom: 2.25em;
  padding-right: 10%;
  padding-left: 10%;
}

.p-mosaic .p-txt-container :last-child {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .p-mosaic-auto .p-img-container {
    min-height: 56.25vw;
  }
}

.p-section {
  position: relative;
  border: 0 solid rgba(0, 0, 0, 0.1);
  padding-top: 3em;
  padding-bottom: 3em;
}

.p-section.pt-1 {
  padding-top: 1.5em !important;
}

.p-section.pb-1 {
  padding-bottom: 1.5em !important;
}

.p-section.pt-2 {
  padding-top: 3em !important;
}

.p-section.pb-2 {
  padding-bottom: 3em !important;
}

.p-section.pt-3 {
  padding-top: 4.5em !important;
}

.p-section.pb-3 {
  padding-bottom: 4.5em !important;
}

.p-section.pt-4 {
  padding-top: 6em !important;
}

.p-section.pb-4 {
  padding-bottom: 6em !important;
}

.p-section.pt-5 {
  padding-top: 7.5em !important;
}

.p-section.pb-5 {
  padding-bottom: 7.5em !important;
}

.p-section.pl-0 .container-fluid {
  padding-left: 0;
}

.p-section.pl-0 .container-fluid > .row {
  margin-left: 0;
}

.p-section.pl-0 .col-12.col-md-12.col-lg-12 {
  padding-left: 0;
}

.p-section.pl-0 .col-12.col-md-12.col-lg-12 > .row {
  margin-left: 0;
}

.p-section.pl-0 .col-12.col-md-12.col-lg-12 > .row > .p:first-child {
  padding-left: 0;
}

@media (min-width: 768px) {
  .p-section.pl-0 .col-12.col-md-12.col-lg-12 > .row > .p-columns:first-child {
    padding-left: 0;
  }
  .p-section.pl-0 .col-12.col-md-12.col-lg-12 > .row > .p-columns:first-child > .row {
    margin-left: 0;
  }
  .p-section.pl-0 .col-12.col-md-12.col-lg-12 > .row > .p-columns:first-child > .row > .col-12 {
    padding-left: 0;
  }
}

.p-section.pr-0 .container-fluid {
  padding-right: 0;
}

.p-section.pr-0 .container-fluid > .row {
  margin-right: 0;
}

.p-section.pr-0 .col-12.col-md-12.col-lg-12 {
  padding-right: 0;
}

.p-section.pr-0 .col-12.col-md-12.col-lg-12 > .row {
  margin-right: 0;
}

.p-section.pr-0 .col-12.col-md-12.col-lg-12 > .row > .p:last-child {
  padding-right: 0;
}

@media (min-width: 768px) {
  .p-section.pr-0 .col-12.col-md-12.col-lg-12 > .row > .p-columns:last-child {
    padding-right: 0;
  }
  .p-section.pr-0 .col-12.col-md-12.col-lg-12 > .row > .p-columns:last-child > .row {
    margin-right: 0;
  }
  .p-section.pr-0 .col-12.col-md-12.col-lg-12 > .row > .p-columns:last-child > .row > .col-12 {
    padding-right: 0;
  }
}

.p-section .row.no-gutters .p-columns > .row {
  margin-right: 0;
  margin-left: 0;
}

.p-section .row.no-gutters .p-columns > .row > .col,
.p-section .row.no-gutters .p-columns > .row > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.p-section-small, .p-banner-small, .p-carousel-small .p-carousel-item {
  height: 25vw;
  max-height: 90vh;
}

@media (max-width: 575px) {
  .p-section-small, .p-banner-small, .p-carousel-small .p-carousel-item {
    height: auto;
    min-height: 50vw;
  }
}

@media (min-width: 2561px) {
  .p-section-small, .p-banner-small, .p-carousel-small .p-carousel-item {
    height: 640px;
  }
}

.p-section-medium, .p-banner-medium, .p-carousel-medium .p-carousel-item {
  height: 37.5vw;
  max-height: 90vh;
}

@media (max-width: 575px) {
  .p-section-medium, .p-banner-medium, .p-carousel-medium .p-carousel-item {
    height: auto;
    min-height: 75vw;
  }
}

@media (min-width: 2561px) {
  .p-section-medium, .p-banner-medium, .p-carousel-medium .p-carousel-item {
    height: 960px;
  }
}

.p-section-large, .p-banner-large, .p-carousel-large .p-carousel-item {
  height: 50vw;
  max-height: 90vh;
}

@media (max-width: 575px) {
  .p-section-large, .p-banner-large, .p-carousel-large .p-carousel-item {
    height: auto;
    min-height: 100vw;
  }
}

@media (min-width: 2561px) {
  .p-section-large, .p-banner-large, .p-carousel-large .p-carousel-item {
    height: 1280px;
  }
}

.p-section-fullscreen, .p-banner-fullscreen, .p-carousel-fullscreen .p-carousel-item {
  height: 100vh;
}

.p-section-bg-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.p-section-bg-contain {
  background-size: contain;
}

.p-section-bg-left-top {
  background-position: 0 0;
}

.p-section-bg-left-middle {
  background-position: 0 50%;
}

.p-section-bg-left-bottom {
  background-position: 0 100%;
}

.p-section-bg-center-top {
  background-position: 50% 0;
}

.p-section-bg-center-bottom {
  background-position: 50% 100%;
}

.p-section-bg-right-top {
  background-position: 100% 0;
}

.p-section-bg-right-middle {
  background-position: 100% 50%;
}

.p-section-bg-right-bottom {
  background-position: 100% 100%;
}

.p-section-bg-mt-1 {
  top: 2.5%;
}

.p-section-bg-mr-1 {
  right: 2.5%;
}

.p-section-bg-mb-1 {
  bottom: 2.5%;
}

.p-section-bg-ml-1 {
  left: 2.5%;
}

.p-section-bg-mt-2 {
  top: 5%;
}

.p-section-bg-mr-2 {
  right: 5%;
}

.p-section-bg-mb-2 {
  bottom: 5%;
}

.p-section-bg-ml-2 {
  left: 5%;
}

.p-section-bg-mt-3 {
  top: 7.5%;
}

.p-section-bg-mr-3 {
  right: 7.5%;
}

.p-section-bg-mb-3 {
  bottom: 7.5%;
}

.p-section-bg-ml-3 {
  left: 7.5%;
}

.p-section-bg-mt-4 {
  top: 10%;
}

.p-section-bg-mr-4 {
  right: 10%;
}

.p-section-bg-mb-4 {
  bottom: 10%;
}

.p-section-bg-ml-4 {
  left: 10%;
}

.p-section-bg-mt-5 {
  top: 12.5%;
}

.p-section-bg-mr-5 {
  right: 12.5%;
}

.p-section-bg-mb-5 {
  bottom: 12.5%;
}

.p-section-bg-ml-5 {
  left: 12.5%;
}

.p-section-border-top {
  border-top-width: 1px;
}

.p-section-border-bottom {
  border-bottom-width: 1px;
}

.p-section-border-white {
  border-color: #fff;
}

.p-section-border-black {
  border-color: #000;
}

.p-section-border-light {
  border-color: rgba(255, 255, 255, 0.3);
}

.p-section-border-dark {
  border-color: rgba(0, 0, 0, 0.1);
}

.p-section-border-brand-primary {
  border-color: #1F63B5;
}

.p-section-border-brand-secondary {
  border-color: #E24747;
}

.p-section-border-brand-tertiary {
  border-color: #333333;
}

.p-section-border-brand-quaternary {
  border-color: #C9D1CE;
}

.p-section-collapse > .container-fluid > .row::after {
  content: "";
  display: block;
  flex: 0 0 100%;
  order: 2;
}

.p-section-collapse-content:not(.show) {
  display: block;
  overflow: hidden;
  height: 25vh;
}

.p-section-collapse-content:not(.show).is-sm {
  height: 12.5vh;
}

.p-section-collapse-content:not(.show).is-lg {
  height: 37.5vh;
}

.p-section-collapse-content::after {
  content: "";
  display: block;
  height: 1.5em;
}

.p-section-collapse-toggle {
  z-index: 99;
  cursor: pointer;
  font-size: 0.875em;
}

.p-section-collapse-toggle::before {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  bottom: 100%;
  height: 0;
  transition: height 1s cubic-bezier(0.58, 0, 0.29, 0.845);
}

.p-section-collapse-toggle.collapsed::before {
  height: 0.375em;
  background: radial-gradient(50% 100% at 50% 100%, rgba(0, 0, 0, 0.15) 0%, transparent 100%), radial-gradient(50% 25% at 50% 100%, rgba(0, 0, 0, 0.35) 0%, transparent 100%);
}

.p-section-collapse-toggle-text {
  margin-top: -0.375em;
  display: block;
  font-size: 0.875em;
}

.p-section-collapse-toggle-text::after {
  content: attr(data-collapse-text);
}

.collapsed .p-section-collapse-toggle-text::after {
  content: attr(data-expand-text);
}

.p-section-collapse-toggle-btn {
  display: block;
  padding: 0;
  height: 1.5em;
  width: 1.5em;
  line-height: 1;
  margin: 0 auto;
  font-size: 1.5em;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transform: translateY(-50%);
  transition: none;
}

.p-section-collapse-toggle-icon {
  line-height: 1.5em;
  transform: rotate(540deg) translateY(0.0675em);
  transition: transform 0.5s cubic-bezier(0.58, 0, 0.29, 0.845);
}

.collapsed .p-section-collapse-toggle-icon {
  transform: rotate(0deg) translateY(0.0675em);
}

:-webkit-full-screen-ancestor:not(iframe) {
  will-change: initial !important;
}

.p-video {
  margin-bottom: 1.5em;
}

.p-divider {
  margin-bottom: 1.5em;
}

.p-divider hr {
  margin: 0;
}

.p-divider-thin hr {
  border-top-width: 1px;
}

.p-divider-thick hr {
  border-top-width: 0.375em;
}

.p-divider-transparent hr {
  border-color: transparent;
}

.p-divider-white hr {
  border-color: #fff;
}

.p-divider-black hr {
  border-color: #000;
}

.p-divider-light hr {
  border-color: #F0F5F3;
}

.p-divider-dark hr {
  border-color: #424242;
}

.p-divider-brand-primary hr {
  border-color: #1F63B5;
}

.p-divider-brand-secondary hr {
  border-color: #E24747;
}

.p-divider-brand-tertiary hr {
  border-color: #333333;
}

.p-divider-brand-quaternary hr {
  border-color: #C9D1CE;
}

.p-carousel {
  height: auto;
  margin-bottom: 1.5em;
}

@media (max-width: 991px) {
  .p-carousel .carousel-inner {
    box-sizing: content-box;
    padding-bottom: 15em;
    background: #212121;
  }
  .p-carousel .p-carousel-item::before {
    background: none;
  }
  .p-carousel .carousel-caption {
    box-sizing: border-box;
    height: 15em;
    bottom: auto;
    top: 100%;
  }
  .p-carousel .carousel-indicators {
    bottom: 13.5em;
  }
  .p-carousel .carousel-control-prev,
  .p-carousel .carousel-control-next {
    padding-bottom: 7.5em;
  }
}

.p-carousel.p-carousel-fullscreen .carousel-inner {
  box-sizing: inherit;
  padding-bottom: 0;
  background: none;
}

.p-carousel.p-carousel-fullscreen .p-carousel-item::before {
  background: linear-gradient(11deg, rgba(0, 0, 0, 0.65) 0%, transparent 45%);
}

.p-carousel.p-carousel-fullscreen .carousel-caption {
  height: auto;
  bottom: 0;
  top: auto;
}

.p-carousel.p-carousel-fullscreen .carousel-control-prev,
.p-carousel.p-carousel-fullscreen .carousel-control-next {
  padding-bottom: 0;
}

.p-carousel.p-carousel-fullscreen .carousel-indicators {
  bottom: 0.75em;
}

.p-carousel-item {
  background-size: cover;
  background-position: 50% 50%;
}

.p-carousel-item:not(.p-carousel-item-gradient-disabled)::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(22deg, rgba(0, 0, 0, 0.95) 0%, transparent 60%);
}

@media (max-width: 991px) {
  .p-carousel-item:not(.p-carousel-item-gradient-disabled)::before {
    background: none;
  }
}

.p-carousel-fullscreen .p-carousel-item .carousel-caption {
  bottom: 1.5em;
  margin-bottom: 1vmin;
}

.p-carousel-item-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: transparent;
}

@media (max-width: 991px) {
  .p-carousel-item-link {
    bottom: -15em;
  }
}

.p-carousel-item-img-left-top {
  background-position: 0 0;
}

.p-carousel-item-img-left-middle {
  background-position: 0 50%;
}

.p-carousel-item-img-left-bottom {
  background-position: 0 100%;
}

.p-carousel-item-img-center-top {
  background-position: 50% 0;
}

.p-carousel-item-img-center-middle {
  background-position: 50% 50%;
}

.p-carousel-item-img-center-bottom {
  background-position: 50% 100%;
}

.p-carousel-item-img-right-top {
  background-position: 100% 0;
}

.p-carousel-item-img-right-middle {
  background-position: 100% 50%;
}

.p-carousel-item-img-right-bottom {
  background-position: 100% 100%;
}

.p-image-gallery {
  height: auto;
  margin-bottom: 1.5em;
}

@media (max-width: 991px) {
  .p-image-gallery .image-gallery-inner {
    box-sizing: content-box;
    background: #F0F5F3;
  }
  .p-image-gallery .p-image-gallery-item::before {
    background: none;
  }
}

.p-image-gallery .image-gallery-control-prev,
.p-image-gallery .image-gallery-control-next {
  width: 6em;
  opacity: .375;
  transition: opacity 0.5625s cubic-bezier(0.33, 0, 0.2, 1);
  color: white;
}

.p-image-gallery .image-gallery-control-prev:hover,
.p-image-gallery .image-gallery-control-next:hover {
  opacity: .875;
}

.p-image-gallery .image-gallery-control-prev .image-gallery-control-icon,
.p-image-gallery .image-gallery-control-next .image-gallery-control-icon {
  background: #424242;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-image-gallery .image-gallery-control-prev i,
.p-image-gallery .image-gallery-control-next i {
  font-size: 1.25em;
  border-radius: 100%;
}

.p-image-gallery .image-gallery-control-prev {
  justify-content: flex-start;
}

.p-image-gallery .image-gallery-control-prev i {
  margin-left: 0.5em;
}

.p-image-gallery .image-gallery-control-next {
  justify-content: flex-end;
}

.p-image-gallery .image-gallery-control-next i {
  margin-right: 0.5em;
}

.p-image-gallery-item {
  background-size: cover;
  background-position: 50% 50%;
  height: 100%;
  max-height: 100%;
}

.p-image-gallery-ratio-4-3 .p-image-gallery-item {
  padding-top: 75%;
}

.p-image-gallery-ratio-1-1 .p-image-gallery-item {
  padding-top: 100%;
}

.p-image-gallery-ratio-16-9 .p-image-gallery-item {
  padding-top: 56.25%;
}

.p-image-gallery-item-img-left-top {
  background-position: 0 0;
}

.p-image-gallery-item-img-left-middle {
  background-position: 0 50%;
}

.p-image-gallery-item-img-left-bottom {
  background-position: 0 100%;
}

.p-image-gallery-item-img-center-top {
  background-position: 50% 0;
}

.p-image-gallery-item-img-center-middle {
  background-position: 50% 50%;
}

.p-image-gallery-item-img-center-bottom {
  background-position: 50% 100%;
}

.p-image-gallery-item-img-right-top {
  background-position: 100% 0;
}

.p-image-gallery-item-img-right-middle {
  background-position: 100% 50%;
}

.p-image-gallery-item-img-right-bottom {
  background-position: 100% 100%;
}

.p-contact {
  margin-bottom: 3em;
}

.p-contact .p-txt-container .p-txt-wrapper {
  font-size: 0.875em;
}

.p-contact .p-contact-name {
  margin-bottom: 0;
}

.p-contact .p-contact-title {
  margin-bottom: 1.125em;
}

.p-contact .p-contact-phone {
  white-space: nowrap;
  margin-bottom: 0.75em;
}

.p-contact .p-contact-name + .p-contact-phone {
  margin-top: 1.125em;
}

.p-contact .p-contact-email {
  margin: 0;
  white-space: nowrap;
}

.p-contact .p-contact-email .btn {
  margin-top: 1.125em;
}

.p-contact .material-icons {
  font-size: 1.25em;
  vertical-align: -25%;
  width: 1.5em;
  text-align: left;
}

.p-contact .p-img-width-auto {
  max-width: 12.5em;
}

.p-contact .p-img-container .col-pos-center {
  margin-left: auto;
  margin-right: auto;
}

.p-contact .p-img-container .col-pos-right {
  margin-left: auto;
}

.p-card {
  display: flex;
  margin-bottom: 1.5em;
}

.p-card .card {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  width: 100%;
}

.p-card .card h1,
.p-card .card .h1,
.p-card .card h2,
.p-card .card .h2,
.p-card .card h3,
.p-card .card .h3,
.p-card .card h4,
.p-card .card .h4 {
  color: rgba(0, 0, 0, 0.85);
}

.p-card .card p a:not(.btn),
.p-card .card ul a:not(.btn) {
  color: #2d9ee0;
}

.p-card.p-card-top-bottom .card {
  display: flex;
  flex-direction: column;
}

.p-card .p-txt-container {
  font-size: 0.875em;
  width: 100%;
  flex: 1 1 auto;
}

.p-card .p-txt-container h1,
.p-card .p-txt-container h2,
.p-card .p-txt-container h3,
.p-card .p-txt-container h4,
.p-card .p-txt-container p,
.p-card .p-txt-container ul,
.p-card .p-txt-container table {
  margin-bottom: 0.5rem;
}

.p-card .p-txt-container :last-child {
  margin-bottom: 0;
}

.p-card .p-img-container {
  width: 100%;
  flex: 0 0 auto;
}

.p-card .p-img-container .card-body {
  padding-bottom: 0;
}

.p-card .p-img-container .col-pos-center {
  margin-left: auto;
  margin-right: auto;
}

.p-card .p-img-container .col-pos-right {
  margin-left: auto;
}

.p-card .p-img-container .p-img[src$="svg"] {
  width: 100%;
}

.p-card-img-bleed .p-img-container .card-body {
  padding: 0;
}

.p-card-img-bleed .p-img-container .row {
  margin: 0;
}

.p-card-img-bleed .p-img-container .col-12 {
  padding: 0;
}

.p-card-img-bleed .p-img-container .p-img {
  border-radius: 0.25em 0.25em 0 0;
}

.p-card-link .card {
  cursor: pointer;
}

.p-card-txt-small .p-txt-container {
  font-size: 0.875em;
}

.p-card-txt-medium .p-txt-container {
  font-size: 1em;
}

.p-card-txt-large .p-txt-container {
  font-size: 1.5em;
}

.p-card:not(.p-card-hover-disabled):hover .card {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.p-card-shadow-none .card {
  box-shadow: none;
}

.p-card-shadow-none:not(.p-card-hover-disabled):hover .card {
  box-shadow: none;
}

.p-card-shadow-small .card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-card-shadow-small:not(.p-card-hover-disabled):hover .card {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.p-card-shadow-medium .card {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.p-card-shadow-medium:not(.p-card-hover-disabled):hover .card {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.p-card-shadow-large .card {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.p-card-shadow-large:not(.p-card-hover-disabled):hover .card {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.14), 0 12px 17px 2px rgba(0, 0, 0, 0.12), 0 5px 22px 4px rgba(0, 0, 0, 0.2);
}

.p-card-padding-small .card-body {
  padding: 0.75em;
}

.p-card-padding-medium .card-body {
  padding: 1.5em;
}

.p-card-padding-large .card-body {
  padding: 2.25em;
}

.p-card-bg-white .card {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
}

.p-card-bg-white .card h1,
.p-card-bg-white .card .h1,
.p-card-bg-white .card h2,
.p-card-bg-white .card .h2,
.p-card-bg-white .card h3,
.p-card-bg-white .card .h3,
.p-card-bg-white .card h4,
.p-card-bg-white .card .h4 {
  color: rgba(0, 0, 0, 0.85);
}

.p-card-bg-white .card p a:not(.btn),
.p-card-bg-white .card ul a:not(.btn) {
  color: #2d9ee0;
}

.p-card-bg-black .card {
  color: rgba(255, 255, 255, 0.75);
  background-color: #000;
}

.p-card-bg-black .card h1,
.p-card-bg-black .card .h1,
.p-card-bg-black .card h2,
.p-card-bg-black .card .h2,
.p-card-bg-black .card h3,
.p-card-bg-black .card .h3,
.p-card-bg-black .card h4,
.p-card-bg-black .card .h4 {
  color: white;
}

.p-card-bg-black .card p a:not(.btn),
.p-card-bg-black .card ul a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.p-card-bg-black .card p a:not(.btn):hover,
.p-card-bg-black .card ul a:not(.btn):hover {
  text-decoration: none;
}

.p-card-bg-light .card {
  color: rgba(0, 0, 0, 0.65);
  background: #F0F5F3;
  border-color: rgba(0, 0, 0, 0.1);
}

.p-card-bg-light .card h1,
.p-card-bg-light .card .h1,
.p-card-bg-light .card h2,
.p-card-bg-light .card .h2,
.p-card-bg-light .card h3,
.p-card-bg-light .card .h3,
.p-card-bg-light .card h4,
.p-card-bg-light .card .h4 {
  color: rgba(0, 0, 0, 0.85);
}

.p-card-bg-light .card p a:not(.btn),
.p-card-bg-light .card ul a:not(.btn) {
  color: #2d9ee0;
}

.p-card-bg-light .card hr {
  border-color: rgba(0, 0, 0, 0.1);
}

.p-card-bg-dark .card {
  color: rgba(255, 255, 255, 0.75);
  background: #424242;
  border-color: rgba(255, 255, 255, 0.3);
}

.p-card-bg-dark .card h1,
.p-card-bg-dark .card .h1,
.p-card-bg-dark .card h2,
.p-card-bg-dark .card .h2,
.p-card-bg-dark .card h3,
.p-card-bg-dark .card .h3,
.p-card-bg-dark .card h4,
.p-card-bg-dark .card .h4 {
  color: white;
}

.p-card-bg-dark .card p a:not(.btn),
.p-card-bg-dark .card ul a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.p-card-bg-dark .card p a:not(.btn):hover,
.p-card-bg-dark .card ul a:not(.btn):hover {
  text-decoration: none;
}

.p-card-bg-dark .card hr {
  border-color: rgba(0, 0, 0, 0.1);
}

.p-card-bg-brand-primary .card {
  background: #1F63B5;
  border-color: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.75);
}

.p-card-bg-brand-primary .card hr {
  border-color: rgba(0, 0, 0, 0.1);
}

.p-card-bg-brand-primary .card h1,
.p-card-bg-brand-primary .card .h1,
.p-card-bg-brand-primary .card h2,
.p-card-bg-brand-primary .card .h2,
.p-card-bg-brand-primary .card h3,
.p-card-bg-brand-primary .card .h3,
.p-card-bg-brand-primary .card h4,
.p-card-bg-brand-primary .card .h4 {
  color: white;
}

.p-card-bg-brand-primary .card p a:not(.btn),
.p-card-bg-brand-primary .card ul a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.p-card-bg-brand-primary .card p a:not(.btn):hover,
.p-card-bg-brand-primary .card ul a:not(.btn):hover {
  text-decoration: none;
}

.p-card-bg-brand-secondary .card {
  background: #E24747;
  border-color: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.75);
}

.p-card-bg-brand-secondary .card hr {
  border-color: rgba(0, 0, 0, 0.1);
}

.p-card-bg-brand-secondary .card h1,
.p-card-bg-brand-secondary .card .h1,
.p-card-bg-brand-secondary .card h2,
.p-card-bg-brand-secondary .card .h2,
.p-card-bg-brand-secondary .card h3,
.p-card-bg-brand-secondary .card .h3,
.p-card-bg-brand-secondary .card h4,
.p-card-bg-brand-secondary .card .h4 {
  color: white;
}

.p-card-bg-brand-secondary .card p a:not(.btn),
.p-card-bg-brand-secondary .card ul a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.p-card-bg-brand-secondary .card p a:not(.btn):hover,
.p-card-bg-brand-secondary .card ul a:not(.btn):hover {
  text-decoration: none;
}

.p-card-bg-brand-tertiary .card {
  background: #333333;
  border-color: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.75);
}

.p-card-bg-brand-tertiary .card hr {
  border-color: rgba(0, 0, 0, 0.1);
}

.p-card-bg-brand-tertiary .card h1,
.p-card-bg-brand-tertiary .card .h1,
.p-card-bg-brand-tertiary .card h2,
.p-card-bg-brand-tertiary .card .h2,
.p-card-bg-brand-tertiary .card h3,
.p-card-bg-brand-tertiary .card .h3,
.p-card-bg-brand-tertiary .card h4,
.p-card-bg-brand-tertiary .card .h4 {
  color: white;
}

.p-card-bg-brand-tertiary .card p a:not(.btn),
.p-card-bg-brand-tertiary .card ul a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.p-card-bg-brand-tertiary .card p a:not(.btn):hover,
.p-card-bg-brand-tertiary .card ul a:not(.btn):hover {
  text-decoration: none;
}

.p-card-bg-brand-quaternary .card {
  background: #C9D1CE;
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.65);
}

.p-card-bg-brand-quaternary .card hr {
  border-color: rgba(0, 0, 0, 0.1);
}

.p-card-bg-brand-quaternary .card h1,
.p-card-bg-brand-quaternary .card .h1,
.p-card-bg-brand-quaternary .card h2,
.p-card-bg-brand-quaternary .card .h2,
.p-card-bg-brand-quaternary .card h3,
.p-card-bg-brand-quaternary .card .h3,
.p-card-bg-brand-quaternary .card h4,
.p-card-bg-brand-quaternary .card .h4 {
  color: rgba(0, 0, 0, 0.85);
}

.p-card-bg-brand-quaternary .card p a:not(.btn),
.p-card-bg-brand-quaternary .card ul a:not(.btn) {
  color: #2d9ee0;
}

.p-card-txt-dark .card {
  color: rgba(0, 0, 0, 0.65);
}

.p-card-txt-dark .card h1,
.p-card-txt-dark .card .h1,
.p-card-txt-dark .card h2,
.p-card-txt-dark .card .h2,
.p-card-txt-dark .card h3,
.p-card-txt-dark .card .h3,
.p-card-txt-dark .card h4,
.p-card-txt-dark .card .h4 {
  color: rgba(0, 0, 0, 0.85);
}

.p-card-txt-dark .card p a:not(.btn),
.p-card-txt-dark .card ul a:not(.btn) {
  color: #2d9ee0;
}

.p-card-txt-light .card {
  color: rgba(255, 255, 255, 0.75);
}

.p-card-txt-light .card h1,
.p-card-txt-light .card .h1,
.p-card-txt-light .card h2,
.p-card-txt-light .card .h2,
.p-card-txt-light .card h3,
.p-card-txt-light .card .h3,
.p-card-txt-light .card h4,
.p-card-txt-light .card .h4 {
  color: white;
}

.p-card-txt-light .card p a:not(.btn),
.p-card-txt-light .card ul a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.p-card-txt-light .card p a:not(.btn):hover,
.p-card-txt-light .card ul a:not(.btn):hover {
  text-decoration: none;
}

.p-card-txt-bg-white .p-txt-container {
  background-color: #fff;
}

.p-card-txt-bg-black .p-txt-container {
  background-color: #000;
}

.p-card-txt-bg-light .p-txt-container {
  background: #F0F5F3;
}

.p-card-txt-bg-dark .p-txt-container {
  background: #424242;
}

.p-card-txt-bg-brand-primary .p-txt-container {
  background-color: #1F63B5;
}

.p-card-txt-bg-brand-secondary .p-txt-container {
  background-color: #E24747;
}

.p-card-txt-bg-brand-tertiary .p-txt-container {
  background-color: #333333;
}

.p-card-txt-bg-brand-quaternary .p-txt-container {
  background-color: #C9D1CE;
}

.p-card-border-thin .card {
  border-width: 1px;
}

.p-card-border-thick .card {
  border-width: 0.375em;
}

.p-card-border-white {
  border-color: #fff;
}

.p-card-border-black {
  border-color: #000;
}

.p-card-border-light {
  border-color: rgba(255, 255, 255, 0.3);
}

.p-card-border-dark {
  border-color: rgba(0, 0, 0, 0.1);
}

.p-card-border-brand-primary {
  border-color: #1F63B5;
}

.p-card-border-brand-secondary {
  border-color: #E24747;
}

.p-tile {
  padding: 0;
  line-height: 1.35;
}

@media (max-width: 1199px) {
  .p-tile {
    line-height: 1.25;
  }
}

.p-tile .p-tile-container {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
}

.p-tile .p-txt-container {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 2.1em 1.5em;
}

.p-tile .p-txt-container h1,
.p-tile .p-txt-container h2,
.p-tile .p-txt-container h3,
.p-tile .p-txt-container h4,
.p-tile .p-txt-container h5,
.p-tile .p-txt-container h6 {
  margin-bottom: 0;
}

.p-tile .p-txt-container h1 + p, .p-tile .p-txt-container h1 + a,
.p-tile .p-txt-container h2 + p,
.p-tile .p-txt-container h2 + a,
.p-tile .p-txt-container h3 + p,
.p-tile .p-txt-container h3 + a,
.p-tile .p-txt-container h4 + p,
.p-tile .p-txt-container h4 + a,
.p-tile .p-txt-container h5 + p,
.p-tile .p-txt-container h5 + a,
.p-tile .p-txt-container h6 + p,
.p-tile .p-txt-container h6 + a {
  margin-top: 0.9em;
}

.p-tile .p-txt-container p {
  margin-bottom: 1.125em;
}

.p-tile .p-txt-container p:last-child {
  margin-bottom: 0;
}

.p-tile.p-tile-txt-small .p-txt-container {
  font-size: 0.875em;
}

.p-tile.p-tile-txt-medium .p-txt-container {
  font-size: 1em;
}

.p-tile.p-tile-txt-large .p-txt-container {
  font-size: 1.25em;
}

.p-tile a.p-tile-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
}

.p-tile a.p-tile-link:hover .p-img {
  transform: scale(1.25, 1.25);
}

.p-tile-img .p-img-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
}

.p-tile-img .p-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transition: transform 0.375s;
  backface-visibility: hidden;
}

.p-tile-img .p-img:not(.p-tile-gradient-disabled)::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(22deg, rgba(0, 0, 0, 0.65) 0%, transparent 70%);
}

.p-tile-img .p-img-left-top {
  background-position: 0 0;
}

.p-tile-img .p-img-left-middle {
  background-position: 0 50%;
}

.p-tile-img .p-img-left-bottom {
  background-position: 0 100%;
}

.p-tile-img .p-img-center-top {
  background-position: 50% 0;
}

.p-tile-img .p-img-center-middle {
  background-position: 50% 50%;
}

.p-tile-img .p-img-center-bottom {
  background-position: 50% 100%;
}

.p-tile-img .p-img-right-top {
  background-position: 100% 0;
}

.p-tile-img .p-img-right-middle {
  background-position: 100% 50%;
}

.p-tile-img .p-img-right-bottom {
  background-position: 100% 100%;
}

.p-tile-height-auto .p-txt-container {
  position: static;
}

.p-tile-height-xs {
  height: 25vh;
  min-height: 12.5em;
}

.p-tile-height-sm {
  height: 33.33333vh;
  min-height: 16.66666em;
}

.p-tile-height-md {
  height: 50vh;
  min-height: 25em;
}

.p-tile-height-lg {
  height: 66.66666vh;
  min-height: 33.33333em;
}

.p-tile-height-xl {
  height: 75vh;
  min-height: 37.5em;
}

.p-tile-height-fullscreen {
  height: 100vh;
}

@media (min-width: 576px) {
  .p-tile-height-fullscreen {
    min-height: 50em;
  }
}

.p-tile-bg-white {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
}

.p-tile-bg-white h1,
.p-tile-bg-white .h1,
.p-tile-bg-white h2,
.p-tile-bg-white .h2,
.p-tile-bg-white h3,
.p-tile-bg-white .h3,
.p-tile-bg-white h4,
.p-tile-bg-white .h4 {
  color: rgba(0, 0, 0, 0.85);
}

.p-tile-bg-white p a:not(.btn),
.p-tile-bg-white ul a:not(.btn) {
  color: #2d9ee0;
}

.p-tile-bg-black {
  color: rgba(255, 255, 255, 0.75);
  background-color: #000;
}

.p-tile-bg-black h1,
.p-tile-bg-black .h1,
.p-tile-bg-black h2,
.p-tile-bg-black .h2,
.p-tile-bg-black h3,
.p-tile-bg-black .h3,
.p-tile-bg-black h4,
.p-tile-bg-black .h4 {
  color: white;
}

.p-tile-bg-black p a:not(.btn),
.p-tile-bg-black ul a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.p-tile-bg-black p a:not(.btn):hover,
.p-tile-bg-black ul a:not(.btn):hover {
  text-decoration: none;
}

.p-tile-bg-light {
  color: rgba(0, 0, 0, 0.65);
  background: #F0F5F3;
  border-color: rgba(0, 0, 0, 0.1);
}

.p-tile-bg-light h1,
.p-tile-bg-light .h1,
.p-tile-bg-light h2,
.p-tile-bg-light .h2,
.p-tile-bg-light h3,
.p-tile-bg-light .h3,
.p-tile-bg-light h4,
.p-tile-bg-light .h4 {
  color: rgba(0, 0, 0, 0.85);
}

.p-tile-bg-light p a:not(.btn),
.p-tile-bg-light ul a:not(.btn) {
  color: #2d9ee0;
}

.p-tile-bg-light hr {
  border-color: rgba(0, 0, 0, 0.1);
}

.p-tile-bg-dark {
  color: rgba(255, 255, 255, 0.75);
  background: #424242;
  border-color: rgba(255, 255, 255, 0.3);
}

.p-tile-bg-dark h1,
.p-tile-bg-dark .h1,
.p-tile-bg-dark h2,
.p-tile-bg-dark .h2,
.p-tile-bg-dark h3,
.p-tile-bg-dark .h3,
.p-tile-bg-dark h4,
.p-tile-bg-dark .h4 {
  color: white;
}

.p-tile-bg-dark p a:not(.btn),
.p-tile-bg-dark ul a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.p-tile-bg-dark p a:not(.btn):hover,
.p-tile-bg-dark ul a:not(.btn):hover {
  text-decoration: none;
}

.p-tile-bg-dark hr {
  border-color: rgba(0, 0, 0, 0.1);
}

.p-tile-bg-brand-primary {
  background: #1F63B5;
  border-color: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.75);
}

.p-tile-bg-brand-primary hr {
  border-color: rgba(0, 0, 0, 0.1);
}

.p-tile-bg-brand-primary h1,
.p-tile-bg-brand-primary .h1,
.p-tile-bg-brand-primary h2,
.p-tile-bg-brand-primary .h2,
.p-tile-bg-brand-primary h3,
.p-tile-bg-brand-primary .h3,
.p-tile-bg-brand-primary h4,
.p-tile-bg-brand-primary .h4 {
  color: white;
}

.p-tile-bg-brand-primary p a:not(.btn),
.p-tile-bg-brand-primary ul a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.p-tile-bg-brand-primary p a:not(.btn):hover,
.p-tile-bg-brand-primary ul a:not(.btn):hover {
  text-decoration: none;
}

.p-tile-bg-brand-secondary {
  background: #E24747;
  border-color: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.75);
}

.p-tile-bg-brand-secondary hr {
  border-color: rgba(0, 0, 0, 0.1);
}

.p-tile-bg-brand-secondary h1,
.p-tile-bg-brand-secondary .h1,
.p-tile-bg-brand-secondary h2,
.p-tile-bg-brand-secondary .h2,
.p-tile-bg-brand-secondary h3,
.p-tile-bg-brand-secondary .h3,
.p-tile-bg-brand-secondary h4,
.p-tile-bg-brand-secondary .h4 {
  color: white;
}

.p-tile-bg-brand-secondary p a:not(.btn),
.p-tile-bg-brand-secondary ul a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.p-tile-bg-brand-secondary p a:not(.btn):hover,
.p-tile-bg-brand-secondary ul a:not(.btn):hover {
  text-decoration: none;
}

.p-tile-bg-brand-tertiary {
  background: #333333;
  border-color: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.75);
}

.p-tile-bg-brand-tertiary hr {
  border-color: rgba(0, 0, 0, 0.1);
}

.p-tile-bg-brand-tertiary h1,
.p-tile-bg-brand-tertiary .h1,
.p-tile-bg-brand-tertiary h2,
.p-tile-bg-brand-tertiary .h2,
.p-tile-bg-brand-tertiary h3,
.p-tile-bg-brand-tertiary .h3,
.p-tile-bg-brand-tertiary h4,
.p-tile-bg-brand-tertiary .h4 {
  color: white;
}

.p-tile-bg-brand-tertiary p a:not(.btn),
.p-tile-bg-brand-tertiary ul a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.p-tile-bg-brand-tertiary p a:not(.btn):hover,
.p-tile-bg-brand-tertiary ul a:not(.btn):hover {
  text-decoration: none;
}

.p-tile-bg-brand-quaternary {
  background: #C9D1CE;
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.65);
}

.p-tile-bg-brand-quaternary hr {
  border-color: rgba(0, 0, 0, 0.1);
}

.p-tile-bg-brand-quaternary h1,
.p-tile-bg-brand-quaternary .h1,
.p-tile-bg-brand-quaternary h2,
.p-tile-bg-brand-quaternary .h2,
.p-tile-bg-brand-quaternary h3,
.p-tile-bg-brand-quaternary .h3,
.p-tile-bg-brand-quaternary h4,
.p-tile-bg-brand-quaternary .h4 {
  color: rgba(0, 0, 0, 0.85);
}

.p-tile-bg-brand-quaternary p a:not(.btn),
.p-tile-bg-brand-quaternary ul a:not(.btn) {
  color: #2d9ee0;
}

.p-tile-txt-dark {
  color: rgba(0, 0, 0, 0.65);
}

.p-tile-txt-dark h1,
.p-tile-txt-dark .h1,
.p-tile-txt-dark h2,
.p-tile-txt-dark .h2,
.p-tile-txt-dark h3,
.p-tile-txt-dark .h3,
.p-tile-txt-dark h4,
.p-tile-txt-dark .h4 {
  color: rgba(0, 0, 0, 0.85);
}

.p-tile-txt-dark p a:not(.btn),
.p-tile-txt-dark ul a:not(.btn) {
  color: #2d9ee0;
}

.p-tile-txt-light {
  color: rgba(255, 255, 255, 0.75);
}

.p-tile-txt-light h1,
.p-tile-txt-light .h1,
.p-tile-txt-light h2,
.p-tile-txt-light .h2,
.p-tile-txt-light h3,
.p-tile-txt-light .h3,
.p-tile-txt-light h4,
.p-tile-txt-light .h4 {
  color: white;
}

.p-tile-txt-light p a:not(.btn),
.p-tile-txt-light ul a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.p-tile-txt-light p a:not(.btn):hover,
.p-tile-txt-light ul a:not(.btn):hover {
  text-decoration: none;
}

.p-tile.p-tile-center-top .p-tile-container {
  align-items: flex-start;
}

.p-tile.p-tile-center-top .p-txt-wrapper {
  justify-content: center;
}

.p-tile.p-tile-center-top .p-img-container .p-img::after {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.65) 0%, transparent 65%);
}

.p-tile.p-tile-center-middle .p-tile-container {
  align-items: center;
}

.p-tile.p-tile-center-middle .p-txt-wrapper {
  justify-content: center;
}

.p-tile.p-tile-center-middle .p-img-container .p-img::after {
  background: rgba(0, 0, 0, 0.55);
}

.p-tile.p-tile-center-bottom .p-tile-container {
  align-items: flex-end;
}

.p-tile.p-tile-center-bottom .p-txt-wrapper {
  justify-content: center;
}

.p-tile.p-tile-center-bottom .p-img-container .p-img::after {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.65) 0%, transparent 65%);
}

.p-tile.p-tile-left-top .p-tile-container {
  align-items: flex-start;
}

.p-tile.p-tile-left-top .p-txt-wrapper {
  justify-content: flex-start;
}

.p-tile.p-tile-left-top .p-img-container .p-img::after {
  background: linear-gradient(157deg, rgba(0, 0, 0, 0.65) 0%, transparent 70%);
}

.p-tile.p-tile-left-middle .p-tile-container {
  align-items: center;
}

.p-tile.p-tile-left-middle .p-txt-wrapper {
  justify-content: flex-start;
}

.p-tile.p-tile-left-middle .p-img-container .p-img::after {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.65) 0%, transparent 70%);
}

.p-tile.p-tile-left-bottom .p-tile-container {
  align-items: flex-end;
}

.p-tile.p-tile-left-bottom .p-txt-wrapper {
  justify-content: flex-start;
}

.p-tile.p-tile-left-bottom .p-img-container .p-img::after {
  background: linear-gradient(22deg, rgba(0, 0, 0, 0.65) 0%, transparent 70%);
}

.p-tile.p-tile-right-top .p-tile-container {
  align-items: flex-start;
}

.p-tile.p-tile-right-top .p-txt-wrapper {
  justify-content: flex-end;
}

.p-tile.p-tile-right-top .p-img-container .p-img::after {
  background: linear-gradient(202deg, rgba(0, 0, 0, 0.65) 0%, transparent 70%);
}

.p-tile.p-tile-right-middle .p-tile-container {
  align-items: center;
}

.p-tile.p-tile-right-middle .p-txt-wrapper {
  justify-content: flex-end;
}

.p-tile.p-tile-right-middle .p-img-container .p-img::after {
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.65) 0%, transparent 70%);
}

.p-tile.p-tile-right-bottom .p-tile-container {
  align-items: flex-end;
}

.p-tile.p-tile-right-bottom .p-txt-wrapper {
  justify-content: flex-end;
}

.p-tile.p-tile-right-bottom .p-img-container .p-img::after {
  background: linear-gradient(338deg, rgba(0, 0, 0, 0.65) 0%, transparent 70%);
}

.p-tile.p-tile-gradient-disabled .p-img-container .p-img::after {
  background: none;
}

.p-accordion {
  margin-bottom: 1.5em;
}

.p-accordion-toggle {
  cursor: pointer;
}

.p-accordion-toggle-icon {
  transform: rotate(135deg);
  transition: transform 0.5s cubic-bezier(0.58, 0, 0.29, 0.845);
  font-size: 1.5em;
}

.collapsed .p-accordion-toggle-icon {
  transform: rotate(0deg);
}

.e-search-form-container {
  position: sticky;
  top: 0;
  z-index: 2;
}

.e-search-input-group {
  position: relative;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25em;
  background: #fff;
}

.e-search-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4em;
  margin: 0;
}

.e-search-label .material-icons {
  font-size: 1.5em;
  vertical-align: -26%;
}

.e-search-input {
  width: 100%;
}

.e-search-input-group .e-search-input, .e-search-input-group .e-search-input:focus, .e-search-input-group .e-search-input:hover {
  outline: none;
}

@media only screen and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .e-search-input-group .e-search-input, .e-search-input-group .e-search-input:focus, .e-search-input-group .e-search-input:hover {
    font-size: 1.5em !important;
  }
}

.e-search-input:-webkit-autofill {
  transition: none;
  -webkit-box-shadow: 0 0 0 5em #fff inset;
  -webkit-text-fill-color: #212121;
}

.e-search-clear {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4em;
  opacity: 0.85;
  cursor: pointer;
  transition: opacity 0.375s cubic-bezier(0.33, 0, 0.2, 1);
}

.e-search-clear .material-icons {
  font-size: 1.5em;
}

.e-search-input-group.is-empty .e-search-clear {
  opacity: 0;
  pointer-events: none;
}

.e-search-submit {
  border: 0;
  box-shadow: none;
  font-size: 1em;
  padding: 0.5em 1em;
  margin: 0.25em 0.25em 0.25em 0;
  border-radius: inherit;
  transition: opacity 0.375s cubic-bezier(0.33, 0, 0.2, 1);
}

.e-search-submit .material-icons {
  font-size: 1.5em;
  vertical-align: -26%;
}

.e-search-input-group.is-empty .e-search-submit {
  opacity: 0.65;
  pointer-events: none;
}

.e-search-result-container {
  position: relative;
  z-index: 1;
  display: none;
}

.e-search-result-container.is-active {
  display: block;
}

.e-search-result-pages.is-empty,
.e-search-result-products.is-empty {
  display: none;
}

.e-search-result-pages {
  line-height: 1.3125;
}

.e-search-loading-overlay {
  min-height: 50vh;
  background: rgba(255, 255, 255, 0.85);
  transition: opacity 0.1875s cubic-bezier(0.33, 0, 0.2, 1);
}

.e-search-loading-overlay .e-loading-spinner {
  top: 25vh;
}

.e-news.has-filter .e-news-item {
  opacity: 1;
  transform: none;
  transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 0.1875s cubic-bezier(0.33, 0, 0.2, 1);
  will-change: transform, opacity;
}

.e-news.is-filtering .e-news-item {
  opacity: 0;
  transform: translateY(50%) scale(0.375 0.375) rotate(0deg);
  transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 0.1875s cubic-bezier(0.33, 0, 0.2, 1);
}

.e-news-filter .nav-link {
  padding-right: 2em;
  padding-left: 2em;
}

.e-news-filter .nav-link:not([data-filter="*"]) {
  padding-right: 1em;
}

.e-news-filter .nav-link .material-icons {
  width: 1em;
  transform: rotate(45deg);
  opacity: 0;
}

.e-news-filter .nav-link.active .material-icons {
  opacity: 1;
}

@media (min-width: 768px) {
  .e-dealersearch-sidebar {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.e-dealersearch-header {
  flex: 0 1 auto;
}

.e-dealersearch-main {
  flex: 1 1 auto;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

@media (max-width: 767px) {
  .e-dealersearch-main {
    max-height: 200vh;
  }
}

.e-dealersearch-main::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

.e-dealersearch-main::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

.e-dealersearch-main::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
}

.e-dealersearch-input-placeholder {
  padding: 0.375em 0.75em;
  border: 1px solid transparent;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  opacity: 1;
  transition: opacity 0.1875s cubic-bezier(0.33, 0, 0.2, 1);
}

.e-dealersearch-input-placeholder.is-hidden {
  opacity: 0;
}

.e-dealersearch-suggestions {
  min-width: 100%;
}

.e-dealersearch-suggestions .dropdown-item {
  cursor: pointer;
}

.e-dealersearch-suggestions-item-geolocation .material-icons {
  color: #2d9ee0;
}

.e-dealersearch-suggestions-item-geolocation.active .material-icons {
  color: inherit;
}

.e-dealersearch-list {
  opacity: 0;
  transition: opacity 0.375s cubic-bezier(0.33, 0, 0.2, 1);
}

.e-dealersearch-list:not(.is-empty) {
  opacity: 1;
  background: #fff;
}

.e-dealersearch-list-item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.e-dealersearch-list-item-address {
  cursor: pointer;
}

@media (max-width: 767px) {
  .e-dealersearch-googlemap-container {
    height: 0;
    overflow: hidden;
  }
  .e-dealersearch-googlemap-container.is-active {
    height: auto;
  }
}

.e-dealersearch-googlemap {
  height: 90vh;
}

.e-dealersearch-googlemap-infowindow {
  color: rgba(0, 0, 0, 0.65);
}

.e-dealersearch-googlemap-infowindow h1,
.e-dealersearch-googlemap-infowindow .h1,
.e-dealersearch-googlemap-infowindow h2,
.e-dealersearch-googlemap-infowindow .h2,
.e-dealersearch-googlemap-infowindow h3,
.e-dealersearch-googlemap-infowindow .h3,
.e-dealersearch-googlemap-infowindow h4,
.e-dealersearch-googlemap-infowindow .h4 {
  color: rgba(0, 0, 0, 0.85);
}

.e-dealersearch-googlemap-infowindow p a:not(.btn),
.e-dealersearch-googlemap-infowindow ul a:not(.btn) {
  color: #2d9ee0;
}

.e-reveal-conceal {
  opacity: 0;
  transform: translateY(10vh);
  will-change: transform, opacity;
}

.e-reveal-fade {
  opacity: 1;
  transform: none;
  transition: opacity 0.75s cubic-bezier(0.33, 0, 0.2, 1);
}

.e-reveal-slide {
  opacity: 1;
  transform: none;
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 0.375s cubic-bezier(0.33, 0, 0.2, 1);
}

.reset-transform-containing-block {
  opacity: 1;
  transform: none;
  transition: none;
  will-change: initial;
}

.e-back-to-top {
  position: fixed;
  bottom: 1.5em;
  right: 1.5em;
  z-index: 1029;
  padding: 1em;
  border-radius: 0.25em;
  color: rgba(0, 0, 0, 0.85);
  background: rgba(255, 255, 255, 0.35);
  user-select: none;
  transform: translateY(200%);
  transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  will-change: transform;
}

.modal-open .e-back-to-top {
  display: none;
}

.e-back-to-top:hover, .e-back-to-top:focus, .e-back-to-top:active {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(255, 255, 255, 0.85);
}

.e-back-to-top.is-visible {
  transform: translateY(0);
}

.e-cookie {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1031;
  font-size: 0.875em;
  background: rgba(0, 0, 0, 0.85);
  color: #fff;
}

.e-cookie a {
  color: inherit;
  text-decoration: underline;
}

.e-cookie.is-accepted {
  display: none;
}

.e-cookie-accept-btn, .e-cookie-accept-btn:hover {
  color: currentColor;
  background-image: none;
  background-color: transparent;
  border-color: currentColor;
}

.p-img-container-parallax {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.p-img-container-parallax .p-img-parallax {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  opacity: 0;
  display: block;
}

@media (max-width: 575px) {
  .p-img-container-parallax .p-img-parallax {
    max-width: none;
    width: 200%;
    left: -50%;
  }
}

.p-img-container-parallax .p-img-parallax.is-loaded {
  transition: opacity 0.75s cubic-bezier(0.33, 0, 0.2, 1);
  opacity: 1;
  will-change: transform;
}

.p-txt-animate h1,
.p-txt-animate h2,
.p-txt-animate h3,
.p-txt-animate h4,
.p-txt-animate ul,
.p-txt-animate p {
  opacity: 0;
  transform: translateY(1.5em);
}

.p-txt-animate.is-visible h1,
.p-txt-animate.is-visible h2,
.p-txt-animate.is-visible h3,
.p-txt-animate.is-visible h4,
.p-txt-animate.is-visible ul,
.p-txt-animate.is-visible p {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.75s cubic-bezier(0.33, 0, 0.2, 1), transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.p-txt-animate.is-visible h1:nth-child(1),
.p-txt-animate.is-visible h2:nth-child(1),
.p-txt-animate.is-visible h3:nth-child(1),
.p-txt-animate.is-visible h4:nth-child(1),
.p-txt-animate.is-visible ul:nth-child(1),
.p-txt-animate.is-visible p:nth-child(1) {
  transition-delay: 0s;
}

.p-txt-animate.is-visible h1:nth-child(2),
.p-txt-animate.is-visible h2:nth-child(2),
.p-txt-animate.is-visible h3:nth-child(2),
.p-txt-animate.is-visible h4:nth-child(2),
.p-txt-animate.is-visible ul:nth-child(2),
.p-txt-animate.is-visible p:nth-child(2) {
  transition-delay: 0.5s;
}

.p-txt-animate.is-visible h1:nth-child(3),
.p-txt-animate.is-visible h2:nth-child(3),
.p-txt-animate.is-visible h3:nth-child(3),
.p-txt-animate.is-visible h4:nth-child(3),
.p-txt-animate.is-visible ul:nth-child(3),
.p-txt-animate.is-visible p:nth-child(3) {
  transition-delay: 1s;
}

.p-txt-animate.is-visible h1:nth-child(4),
.p-txt-animate.is-visible h2:nth-child(4),
.p-txt-animate.is-visible h3:nth-child(4),
.p-txt-animate.is-visible h4:nth-child(4),
.p-txt-animate.is-visible ul:nth-child(4),
.p-txt-animate.is-visible p:nth-child(4) {
  transition-delay: 1.5s;
}

.p-txt-animate.is-visible h1:nth-child(5),
.p-txt-animate.is-visible h2:nth-child(5),
.p-txt-animate.is-visible h3:nth-child(5),
.p-txt-animate.is-visible h4:nth-child(5),
.p-txt-animate.is-visible ul:nth-child(5),
.p-txt-animate.is-visible p:nth-child(5) {
  transition-delay: 2s;
}

.e-animate-number {
  display: inline-block;
}

@media (max-width: 991px) {
  .e-header-hamburger .e-nav-item-cart .e-nav-pagename.is-hidden + .badge {
    transform: translate(-20%, -110%);
  }
  .e-header-hamburger .e-nav-item-cart .badge {
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translate(-55%, -95%);
  }
  .e-nav .e-nav-item-cart .nav-link {
    display: flex;
    align-items: center;
  }
  .e-nav .e-nav-item-cart .badge {
    margin-left: 0.75em;
  }
}

@media (min-width: 992px) {
  .e-nav-item-cart .e-nav-pagename.is-hidden + .badge {
    transform: translate(-20%, -110%);
  }
  .e-nav-item-cart .badge {
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translate(-55%, -95%);
  }
}

.e-cart {
  position: relative;
}

.e-cart .e-loading-overlay {
  position: fixed;
  max-height: 100vh;
}

.e-orderline + .e-orderline {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.e-orderline .form-control {
  max-width: 5em;
}

@media (min-width: 768px) {
  .e-cart-summery {
    position: sticky;
    top: 1.5em;
  }
}

.e-cart-confirm {
  position: fixed;
  top: -1px;
  right: 1.5em;
  width: 41.66667%;
  z-index: 1029;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  transition: opacity 0.375s cubic-bezier(0.33, 0, 0.2, 1), top 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.e-cart-confirm.is-hidden {
  top: -100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.375s cubic-bezier(0.33, 0, 0.2, 1), top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@media (min-width: 992px) {
  .e-cart-confirm-footer .btn {
    flex-grow: 1;
  }
}

.e-checkout {
  min-height: 100vh;
}

.e-checkout .e-checkout-step-loading-overlay {
  background: rgba(255, 255, 255, 0.85);
}

.e-checkout-step + .e-checkout-step {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.e-checkout-step .card-header .badge {
  min-width: 2em;
}

.e-checkout-step.e-checkout-step-active .card-header,
.e-checkout-step.e-checkout-step-active .card-body {
  background: #ebfbff;
}

.e-checkout-step:not(.e-checkout-step-completed):not(.e-checkout-step-active) .card-header {
  opacity: 0.325;
}

.e-checkout-step:not(.e-checkout-step-completed):not(.e-checkout-step-active) .card-header .badge {
  background: #757575;
}

.e-checkout-step-summary {
  transition: max-height 0.5s cubic-bezier(0.58, 0, 0.29, 0.845);
  overflow: hidden;
  max-height: 0;
}

.e-checkout-step-completed .e-checkout-step-summary {
  max-height: 100vh;
}

.e-checkout-step-summary dl {
  opacity: 0.325;
}

.e-checkout-page-approve .e-checkout-step-summary dl {
  opacity: 1;
  color: rgba(0, 0, 0, 0.85);
}

.e-checkout-form .form-check-label {
  margin-bottom: 0.1875em;
}

.e-checkout-form .e-checkout-list-group-item-icon {
  height: 1.875em;
  width: auto;
}

.e-checkout-form .list-group .list-group-item {
  cursor: pointer;
}

.e-checkout-form .list-group .list-group-item .form-control-label {
  cursor: pointer;
}

.e-checkout-toggle-target-hidden {
  display: none;
}

.e-checkout-summery-icon img {
  height: 1.875em;
  width: auto;
}

@media (max-width: 991px) {
  .e-checkout {
    min-height: 100vh;
  }
  .e-checkout .collapsing {
    transition: none;
  }
  .e-checkout-step-active {
    display: block;
  }
}

.e-products .e-products-item {
  position: relative;
  padding: 2.25em 0;
  cursor: pointer;
}

@media (min-width: 1200px) {
  .e-products .e-products-item:hover {
    border: 0;
  }
  .e-products .e-products-item::before {
    content: "";
    display: none;
    position: absolute;
    top: -0.75em;
    bottom: -0.75em;
    left: -0.75em;
    right: -0.75em;
    z-index: 1;
    background-color: #fff;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  }
  .e-products .e-products-item:hover {
    z-index: 2;
  }
  .e-products .e-products-item:hover::before {
    display: block;
  }
  .e-products .e-products-item:hover .is-hidden-on-hover {
    display: none;
  }
  .e-products .e-products-item:hover .is-visible-on-hover {
    display: block;
  }
}

.e-products .e-products-item .is-hidden-on-hover {
  display: block;
}

.e-products .e-products-item .is-visible-on-hover {
  display: none;
}

.e-products .e-products-item-container {
  position: relative;
  z-index: 2;
}

.e-products .e-products-item-image-container {
  position: relative;
  margin-bottom: 1.5em;
  padding-top: 75%;
}

.e-products .e-products-item-image-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.e-products .e-products-item-image {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.e-products .e-products-item-text-container a {
  color: inherit;
  text-decoration: none;
}

.e-products .e-products-item-name {
  color: rgba(0, 0, 0, 0.85);
}

.e-productslist-header {
  padding-bottom: 0.5em;
}

.e-products-loading-overlay {
  background: rgba(255, 255, 255, 0.95);
  z-index: 1028;
}

.e-products-loading-overlay .e-loading-spinner {
  top: 20vh;
}

.e-productlist-product-count:empty {
  display: none;
}

.e-productlist-product-count::before {
  content: '(';
}

.e-productlist-product-count::after {
  content: ')';
}

.e-productlist-filters-range-group .e-productlist-filters-clear-range {
  color: #f44336;
}

.e-productlist-filters-range-group.is-empty .e-productlist-filters-apply-range {
  opacity: 0.65;
}

.e-productlist-filters-range-group.is-empty .e-productlist-filters-clear-range {
  pointer-events: none;
  opacity: 0;
}

.e-productlist-filters-group:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.e-productlist-filters-group-header {
  color: inherit;
  display: flex;
  align-items: center;
}

.e-productlist-filters-group-header .e-productlist-filters-group-collapse-toggle {
  cursor: pointer;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.e-productlist-filters-group-header .e-productlist-filters-group-collapse-toggle, .e-productlist-filters-group-header .e-productlist-filters-group-collapse-toggle:hover {
  text-decoration: none;
}

.e-productlist-filters-group-header .e-productlist-filters-group-collapse-toggle .material-icons {
  transform: rotate(0deg) scale(1.5);
  transition: transform 0.5s cubic-bezier(0.58, 0, 0.29, 0.845);
}

.e-productlist-filters-group-header .e-productlist-filters-group-collapse-toggle.collapsed .material-icons {
  transform: rotate(-90deg) scale(1.5);
}

.e-productlist-filters-group-header .e-productlist-filters-group-name {
  flex-grow: 1;
  padding: 1.125em 0 1.125em 0.375em;
}

.e-productlist-filters-option-selected .material-icons {
  transform: rotate(45deg);
}

.e-productlist-filters-group-options {
  padding-left: 0.25em;
}

.e-productlist-filters-group-options .e-nav-local {
  position: relative;
}

.e-productlist-filters-group-options .e-nav-local .e-nav-local-container {
  font-size: 1em;
}

.e-productlist-filters-group-options .e-nav-local .e-nav-local-container .nav > .nav-item {
  border: 0;
}

.e-productlist-filters-group-options .e-nav-local .e-nav-local-container .nav > .nav-item > .nav-link {
  color: #2d9ee0;
  font-weight: inherit;
  padding: 0.5625em 0;
}

.e-productlist-filters-options-collapse-peek {
  overflow: hidden;
  position: relative;
  max-height: 100vh;
  padding-bottom: 2.25em;
  transition: all 0.5s cubic-bezier(0.58, 0, 0.29, 0.845);
}

.e-productlist-filters-options-collapse-peek .e-productlist-filters-options-collapse-peek-toggle {
  text-decoration: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  transition: all 0.5s cubic-bezier(0.58, 0, 0.29, 0.845);
}

.e-productlist-filters-options-collapse-peek .e-productlist-filters-options-collapse-peek-toggle::after {
  content: attr(data-open-text);
  padding-left: 0.375em;
}

.e-productlist-filters-options-collapse-peek .e-productlist-filters-options-collapse-peek-toggle .material-icons {
  transform: rotate(180deg) scale(1.5);
  transition: transform 0.5s cubic-bezier(0.58, 0, 0.29, 0.845);
}

.e-productlist-filters-options-collapse-peek.is-peeking {
  max-height: 33vh;
}

.e-productlist-filters-options-collapse-peek.is-peeking .e-productlist-filters-options-collapse-peek-toggle::before {
  content: "";
  height: 3em;
  position: absolute;
  top: -3em;
  left: 0;
  right: 0;
  background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0.01) 95%);
}

.e-productlist-filters-options-collapse-peek.is-peeking .e-productlist-filters-options-collapse-peek-toggle::after {
  content: attr(data-closed-text);
  padding-left: 0.375em;
}

.e-productlist-filters-options-collapse-peek.is-peeking .e-productlist-filters-options-collapse-peek-toggle .material-icons {
  transform: rotate(0) scale(1.5);
}

.e-productlist-filters-option.is-disabled label {
  cursor: not-allowed;
}

.e-productlist-filters-modal .modal-body .e-productlist-filters-group-summery {
  order: 1;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 0;
}

.e-productlist-filters-modal .modal-footer {
  position: sticky;
  bottom: 0;
}

.e-product .e-product-container {
  font-size: 0.75em;
  position: relative;
  z-index: 2;
  padding: 1.5em;
}

.e-product .e-product-price-before {
  text-decoration: line-through;
}

.e-product .e-product-image-container {
  margin-bottom: 1.5em;
}

.e-product .e-product-image-container .carousel {
  height: auto;
}

.e-product .e-product-image-container .e-product-image-wrapper {
  position: relative;
  padding-top: 80%;
}

.e-product .e-product-image-container .carousel-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.e-product .e-product-image-container .carousel-item .e-product-image {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: zoom-in;
}

.e-product .e-product-image-container .carousel-indicators {
  font-size: 5em;
  position: static;
  margin: 0.1875em -0.09375em;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: center;
}

.e-product .e-product-image-container .carousel-indicators li {
  flex: 0 0 auto;
  position: relative;
  margin: 0 0.09375em 0.1875em 0.09375em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 1px;
  background-color: transparent;
}

.e-product .e-product-image-container .carousel-indicators li.active {
  border-color: #1F63B5;
}

.e-product .e-product-image-container .carousel-indicators li span {
  position: absolute;
  top: 0.0625em;
  right: 0.0625em;
  bottom: 0.0625em;
  left: 0.0625em;
  background-color: transparent;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.e-product .e-product-image-modal.modal-fullscreen {
  overflow-y: hidden;
}

.e-product .e-product-image-modal.modal-fullscreen .modal-content {
  min-height: 0;
}

.e-product .e-product-image-modal .modal-dialog,
.e-product .e-product-image-modal .modal-content,
.e-product .e-product-image-modal .modal-body,
.e-product .e-product-image-modal .carousel {
  height: 100%;
}

.e-product .e-product-image-modal .modal-body {
  padding: 0;
}

.e-product .e-product-image-modal .carousel-item {
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.e-product .e-product-image-modal .carousel-control.left {
  left: 0.5em;
}

.e-product .e-product-image-modal .carousel-control.right {
  right: 0.5em;
}

.e-product .e-product-image-modal .carousel-indicators li {
  background-color: rgba(0, 0, 0, 0.65);
  opacity: 0.65;
}

@media (min-width: 768px) {
  .e-product .e-product-image-modal .carousel-indicators li {
    background: #fff;
    opacity: 1;
    font-size: 3em;
    margin: 0.1875em;
    border-radius: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.e-product .e-product-image-modal .carousel-indicators li.active {
  border-color: #1F63B5;
  background: #fff;
  opacity: 1;
}

.e-product .e-product-image-modal .carousel-indicators li span {
  display: none;
  position: absolute;
  top: 0.0625em;
  right: 0.0625em;
  bottom: 0.0625em;
  left: 0.0625em;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .e-product .e-product-image-modal .carousel-indicators li span {
    display: block;
  }
}

.e-product .e-product-section {
  padding: 1.5em 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.e-product .e-product-section-header {
  padding-bottom: 1.5em;
}

.e-product .e-product-section-header[data-toggle="collapse"] {
  cursor: pointer;
}

.e-cc-list-collapse-toggle-icon {
  transform: rotate(0deg) scale(1.5);
  transition: transform 0.5s cubic-bezier(0.58, 0, 0.29, 0.845);
}

.collapsed .e-cc-list-collapse-toggle-icon {
  transform: rotate(-90deg) scale(1.5);
}

.e-cc-list-delete-icon {
  transform: rotate(45deg);
}

.btn-primary {
  background: #1F63B5;
  color: #fff;
  border: 0;
  text-decoration: none !important;
  font-weight: 400;
}

.btn-secondary {
  background: #E24747;
  border: none;
  color: #fff;
  font-weight: 400;
  text-decoration: none !important;
}

.e-nav-container.navbar {
  flex: unset;
  flex-flow: row wrap;
  display: flex;
}

.e-nav-container .e-header-section {
  order: 2;
  display: block;
  width: 100%;
}

@media (max-width: 991px) {
  .e-nav-container .e-header-section {
    order: inherit;
  }
}

.e-nav-container .bg-tertiary {
  order: 1;
  display: block;
  width: 100%;
}

@media (min-width: 992px) {
  .e-logo .e-logo-link {
    height: 4.4em;
    margin: 0 0 1.5em 0;
    padding-bottom: 0.2em;
  }
}

.e-nav-container .bg-tertiary {
  background: #fff !important;
}

@media (min-width: 992px) {
  .e-nav-global > .nav > .nav-item > .nav-link {
    padding: 2.7em 1em 2em 1em;
  }
  .e-nav-utilities-primary > .nav > .nav-item > .nav-link {
    padding: 1em 1em 0 1em;
  }
}

/*
@media (min-width: 1300px) {
.e-header-section {padding: 0 11vw;}
}
*/
@media (min-width: 1200px) {
  .headerBanner .p-txt-wrapper > div {
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    max-width: 35%;
    margin-left: 8.66667%;
  }
}

.headerBanner .p-txt-wrapper p {
  color: #fff;
  margin-bottom: 0;
  line-height: 1.5em;
}

.headerBanner .p-txt-wrapper h4 {
  color: #fff;
  font-size: 1.1em;
  padding-bottom: 0.5em;
}

.headerBanner .p-txt-wrapper h1 {
  color: #fff;
  margin-top: 0;
  font-weight: 900;
  font-size: 2.5em;
}

.headerBanner .p-txt-wrapper .btn {
  margin-top: 2em;
  font-size: 0.9em;
}

@media (min-width: 1200px) {
  .ProductCatalogue .p-txt-wrapper > div {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    padding-right: 50%;
  }
}

.ProductCatalogue .p-txt-wrapper h4 {
  color: #333333;
}

.ProductCatalogue .p-txt-wrapper h2 {
  color: #333333;
}

.ProductCatalogue .p-txt-wrapper p {
  color: #333333;
}

.p-section-small, .p-banner-small, .p-carousel-small .p-carousel-item, .p-banner-small, .p-carousel-small .p-carousel-item {
  height: auto;
  max-height: initial;
}

@media (min-width: 1200px) {
  .p-section-small, .p-banner-small, .p-carousel-small .p-carousel-item, .p-banner-small, .p-carousel-small .p-carousel-item {
    min-height: 55vh;
  }
}

.p-banner-txt-center-middle::before {
  background: linear-gradient(to bottom, transparent 0%, transparent 1%, rgba(0, 0, 0, 0.64) 99%, rgba(0, 0, 0, 0.65) 100%);
}

.p-banner h2 {
  font-size: 2em;
}

.p-txt-container ul {
  padding-left: 0;
}

.p-txt-container ul li {
  margin: 0.5em 0em;
  padding-left: 1.4em;
  list-style: none;
}

.p-txt-container ul li:before {
  font-family: 'Material Icons';
  content: "done";
  float: left;
  width: 1.4em;
  color: #E24747;
  margin-left: -1.4em;
}

/*.p-section-columns {
    .formtable {
        tr {
            display: block;
        }

        td {
            display: block;
        }

        .labelCell {
            font-size: 1em;
            color: theme-color("tertiary");
            padding-bottom: 0px;
            margin: 0;

            label {
                margin: 0;
            }
        }
    }
}

.formtable input[type=text], .formtable input[type=password], .formtable textarea {
    min-width: 250px;
    border-radius: 0.3em;
    box-shadow: none;
    border: 0.1em solid #C9D1CE;
    padding: 0.5em;
    width: 100%;
}

.formtable input[type="submit"] {
    border-radius: 10em;
    background: black;
    color: #fff;
    padding: 1em 2.2em;
    font-size: 0.9em;
    border: none;
}
*/
.p-txt-container a {
  text-decoration: underline;
}

.p-txt-container h4 {
  margin-bottom: 0.4em;
}

@media (max-width: 991px) {
  .e-footer-copyright {
    display: none;
  }
}

/*
.p-img-container.p-banner-img-center-middle::after {
    content: "keyboard_arrow_down";
    color: white;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    font-family: "Material Icons";
    text-rendering: optimizeLegibility;
    font-feature-settings: "liga";
    font-style: normal;
    text-transform: none;
    line-height: 1;
    font-size: 4em;
    width: 2em;
    margin-left: -1em;
    height: 1em;
    opacity: 1;
    display: inline-block;
    overflow: overlay;
    -webkit-font-smoothing: antialiased;
}
*/
a {
  color: #1F63B5;
}

a:hover {
  color: #333333;
}

.strong {
  color: #000;
}

.footerInformationaddress {
  max-width: 100%;
  flex: 0 0 100%;
}

.footerInformationaddress h3 {
  font-weight: 500;
  padding-bottom: 0px;
  margin: 0;
  font-size: 0.875em;
}

.e-footer-content {
  padding: 1em;
}

.e-footer-copyright {
  background: #2d2d2d;
}

.e-content-main {
  padding-top: 0;
  padding-bottom: 0;
}

.p-txt-container .lead {
  font-size: 1.3em;
}

.breadcrumb-back-link {
  display: none;
}

.breadcrumb-back-link + .breadcrumb-prefix-text::before {
  display: none;
}

.e-breadcrumb {
  border-top: 1px solid #ccc;
}

.e-breadcrumb .breadcrumb {
  font-size: 0.9em;
}

.e-breadcrumb .breadcrumb li:nth-child(2)::before {
  display: none;
}

.p-icontext .p-txt-container.col-12 p {
  margin-bottom: 0.5em;
}
