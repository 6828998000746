.p-txt-container {
    ul {
        padding-left: 0;

        li {
            margin: 0.5em 0em;
            padding-left: 1.4em;
            list-style: none;

            &:before {
                font-family: 'Material Icons';
                content: "done";
                float: left;
                width: 1.4em;
                color: #E24747;
                margin-left: -1.4em;
            }
        }
    }
}